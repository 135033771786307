/*
	~ Application Notification State Reducer & Actions
*/

import { iNotificationState } from '@domain/Interfaces/AppState/notification/store';

import {
	iNotificationCenterAction,
	NotificationCenterActionTypes,
	
} from '../../Actions/NotificationCenter/types';


const initialState: iNotificationState = {
	messages: [],
};


/* STATE REDUCER */

export default function reducer(state: iNotificationState = initialState, action: iNotificationCenterAction) {

	switch(action.type) {
		case NotificationCenterActionTypes.ADD_MESSAGE_TO_NOTIFICATION_CENTER:
			return {
				...state,
				messages: [
					...state.messages,
					{
						message: action.message,
						messageType: action.messageType,
					}
				],
			};

		case NotificationCenterActionTypes.REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER:
			const messages = [ ...state.messages ];
			messages.shift();

			return {
				...state,
				messages: [ ...messages ],
			};

		default: 
			return state;
	}

}

