/*
	~ App Reducer Actions
*/

import { iAppAction, AppActionTypes } from './types';


/* ACTION CREATORS */

export function rehydratingStores(): iAppAction {
	return {
		type: AppActionTypes.REHYDRATING_STORES 
	};
}

export function storesHydrated(): iAppAction {
	return {
		type: AppActionTypes.STORES_FULLY_HYDRATED 
	};
}

export function initializingApplication(): iAppAction {
	return {
		type: AppActionTypes.INITIALIZING 
	};
}

export function applicationNowInitialized(): iAppAction {
	return {
		type: AppActionTypes.INITIALIZED 
	};
}