/*
	~ Notification Center Component
*/

import classnames from 'classnames';
import NotificationIcon from '@atoms/_notificationIcon';

import Styles, { stylesType } from './styles';

import { Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { viewRecentNotification } from '@ducts/Controllers/notifications';
import { getNotificationsDuct } from '@ducts/DuctSelectors/notification';

import {
	mapMessageTypes,
	iNotificationDuct,
	ViewNotificationTypes,

} from '@interfaces/notifications';

import {
	useState,
	useEffect,

} from 'react';


interface iNotificationCenterView {}

/**
 * 
 * @param props 
 * @returns 
 */
const NotificationCenterView = (props: iNotificationCenterView) => {

	const [isOpened, setIsOpened] = useState(false);
	const classes = withStyles.getClasses<stylesType>(props);

	const storeMessages: iNotificationDuct[] = useSelector(getNotificationsDuct);
	let message = '';
	let messageType = ViewNotificationTypes.error;
	
	if (storeMessages.length > 0) {
		message = storeMessages[0]?.message;
		messageType = mapMessageTypes(
			storeMessages[0]?.messageType as string as keyof typeof ViewNotificationTypes
		);
	}

	useEffect(() => {
		if (!isOpened && storeMessages.length > 0) {
			setIsOpened(true);
		}

	}, [storeMessages]);

	const handleNotificationCenterOnClose = () => {
		setIsOpened(false);
		viewRecentNotification();
	};

	

	return(
		<Snackbar
			key={message}
			open={isOpened}
			autoHideDuration={3000}
			onClose={handleNotificationCenterOnClose}
			anchorOrigin={{
				vertical: 'bottom', 
				horizontal: 'left',
			}}
			ContentProps={{
				classes: {
					message: classes.boxShape,
					root: classnames({
						[classes.error]: messageType === ViewNotificationTypes.error,
						[classes.info]: messageType === ViewNotificationTypes.success,
					}),
				}
			}}
			message={
				<>
					<NotificationIcon type={messageType} />

					<span id={'message-id'}>
						{ message }
					</span>
				</>
			} />
	)
};


export default withStyles(NotificationCenterView, Styles);

