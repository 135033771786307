/**
 * 
 *  ~ Protected Route Component
 * 
 */

import ClientPortalLayout from '@atoms/_clientPortalLayout';

import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUser } from '@ducts/Providers/ApplicationState/StateSelectors/user';


/**
 * This route protects any route by wrapping it and giving it access to the 
 * store to check user authentication state
 * @returns 
 */
const ProtectedRoute = () => {
	const { isAuthenticated } = useSelector(selectUser);

	if (isAuthenticated) {
		return (
			<ClientPortalLayout>
				<Outlet />
			</ClientPortalLayout>
		);

	} else {
		return (
			<Navigate to={Paths.login} />
		);
	}
};


export default ProtectedRoute;