/**
 * 
 *  ~ School Modelling
 * 
*/


import moment from 'moment';

import School, { iSchool, EmptyGeoLocation } from '@data/Entities/Schools/school';

import { SchoolType } from '@data/Shared/Enums/schools/school';
import { iSchoolStoreCollectionDTO, iSchoolStoreDTO } from '@domain/Interfaces/AppState/school/dto';

import {
	iModelling,
	iCollectionModelling,
	iCollectionStructuring,
} from '@domain/Interfaces/modelling';

import {
	iRemoteSchoolDTO,
	iRemoteSchoolsDTO,
	iRemoteSchoolDTOCollection,
} from '@domain/Interfaces/remoteDTOs/school';


/**
 * Model Schools from DTOs to Models and back
 */
export const SchoolStoreModelling: iModelling<iSchoolStoreDTO, iSchool> = {
	toDTO: (school: iSchool) => {
		const { id } = school;

		return {
			uuid: id,
			slc: school.slc,
			fax: school.fax,
			type: school.type,
			name: school.name,
			city: school.city,
			email: school.email,
			phone: school.phone,
			grades: school.grades,
			website: school.website,
			rceName: school.rceName,
			principal: school.principal,
			postalCode: school.postalCode,
			rceCsapCode: school.rceCsapCode,
			geoLocation: school.geoLocation,
			schoolFamily: school.schoolFamily,
			civicAddress: school.civicAddress,
			feederSchools: school.feederSchools,
			vicePrincipal: school.vicePrincipal,
			created: school.created.toISOString(),
			updated: school.updated.toISOString(),
			principalEmail: school.principalEmail,
			mailingAddress: school.mailingAddress,
			vicePrincipalEmail: school.vicePrincipalEmail,
		};
	},
	toModel: (schoolDTO: iSchoolStoreDTO) => {
		const {
			uuid,
			type,
			name,
			updated,
			created,
			geoLocation,
			...remainingSchoolFields

		} = schoolDTO;

		const updatedTimeObject = moment(updated);
		const createdTimeObject = moment(created);

		return new School({
			...remainingSchoolFields,
			id: uuid,
			name: name || '',
			type: type as SchoolType,
			updated: updatedTimeObject,
			created: createdTimeObject,
			geoLocation: geoLocation ? geoLocation : {
				...EmptyGeoLocation 
			},
		});
	},
};


/**
 * Model Schools from DTO Arrays to Model Collections and back
 */
export const SchoolStoreCollectionModelling: iCollectionModelling<iSchoolStoreDTO, iSchool> = {
	toCollection: (schools: iSchool[]): iSchoolStoreCollectionDTO => {
		const collection: iSchoolStoreCollectionDTO = {
			data: {},
			order: [],
		};
		
		for(const school of schools) {
			collection.order.push(school.id);
			collection.data[school.id] = SchoolStoreModelling.toDTO(school);
		}

		return collection;
	},
	fromCollection: (schoolDTOCollection: iSchoolStoreCollectionDTO): iSchool[] => {
		const schools: iSchool[] = [];
		
		const {
			data,
			order,
		} = schoolDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools.push(
					SchoolStoreModelling.toModel(
						data[uuid]
					)
				)
			}
		}

		return schools;
	},
};


/**
 * Model Schools from DTOs to Models and back
 */
export const RemoteSchoolModelling: iModelling<iRemoteSchoolDTO, iSchool> = {
	toDTO: (school: iSchool) => {
		const { id } = school;

		return {
			uuid: id,
			slc: school.slc,
			fax: school.fax,
			type: school.type,
			name: school.name,
			city: school.city,
			email: school.email,
			phone: school.phone,
			grades: school.grades,
			website: school.website,
			rceName: school.rceName,
			principal: school.principal,
			postalCode: school.postalCode,
			rceCsapCode: school.rceCsapCode,
			geoLocation: school.geoLocation,
			schoolFamily: school.schoolFamily,
			civicAddress: school.civicAddress,
			feederSchools: school.feederSchools,
			vicePrincipal: school.vicePrincipal,
			created: school.created.toISOString(),
			updated: school.updated.toISOString(),
			principalEmail: school.principalEmail,
			mailingAddress: school.mailingAddress,
			vicePrincipalEmail: school.vicePrincipalEmail,
		};
	},
	toModel: (schoolDTO: iRemoteSchoolDTO) => {
		const {
			uuid,
			type,
			name, 
			updated,
			created,
			geoLocation,
			...remainingSchoolFields

		} = schoolDTO;

		const updatedTimeObject = moment(updated);
		const createdTimeObject = moment(created);

		return new School({
			...remainingSchoolFields,
			id: uuid,
			name: name || '',
			type: type as SchoolType,
			updated: updatedTimeObject,
			created: createdTimeObject,
			geoLocation: geoLocation ? geoLocation : {
				...EmptyGeoLocation
			},
		});
	},
};


/**
 * Model Schools from DTO Arrays to Model Collections and back
 */
export const RemoteSchoolCollectionModelling: iCollectionModelling<iRemoteSchoolDTO, iSchool> = {
	toCollection: (schools: iSchool[]): iRemoteSchoolDTOCollection => {
		const collection: iRemoteSchoolDTOCollection = {
			data: {},
			order: [],
		};
		
		for(const school of schools) {
			collection.order.push(school.id);
			collection.data[school.id] = SchoolStoreModelling.toDTO(school);
		}

		return collection;
	},
	fromCollection: (schoolDTOCollection: iRemoteSchoolDTOCollection): iSchool[] => {
		const schools: iSchool[] = [];
		
		const {
			data,
			order,
		} = schoolDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools.push(
					SchoolStoreModelling.toModel(
						data[uuid]
					)
				)
			}
		}

		return schools;
	},
};


/**
 * Model Schools from an object of DTOs sorted by UUID to a Collection structure and back
 */
export const RemoteSchoolCollectionStructure: iCollectionStructuring<iRemoteSchoolDTO> = {
	toCollection: (schools: iRemoteSchoolsDTO): iRemoteSchoolDTOCollection => {
		const collection: iRemoteSchoolDTOCollection = {
			data: {},
			order: [],
		};
		
		for(const [uuid, school] of Object.entries(schools)) {
			collection.order.push(uuid);
			collection.data[uuid] = {
				...school,
				uuid,
			};
		}

		return collection;
	},
	fromCollection: (schoolDTOCollection: iRemoteSchoolDTOCollection): iRemoteSchoolsDTO => {
		const schools: iRemoteSchoolsDTO = {};
		
		const {
			data,
			order,
		} = schoolDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools[uuid] = {
					...data[uuid],
				};
			}
		}

		return schools;
	},
};