/*
	~ Page Not Found View
*/

// import { withStyles } from 'tss-react/mui';
// import { ROUTES, getPathByRoute } from 'settings/routes';

// import Styles from './styles';


const PageNotFoundView = () => {
	return (
		<div>
			<h1>Page Not Found!</h1>
		</div>
	);
};

export default PageNotFoundView;