/**
 * 
 *  ~ Collection Types
 * 
 */


export enum CollectionNames {
    pipeline = 'pipeline',
    schools = 'schools',
    events = 'events',
    users = 'users',
}


export const CollectionKeys = {
	[CollectionNames.users]: 'users',
	[CollectionNames.events]: 'events',
	[CollectionNames.schools]: 'schools',
	[CollectionNames.pipeline]: 'pipeline',
};