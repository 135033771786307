/**
 * 
 *  ~ School Action Types
 * 
 */

// import { iSchoolEventDTO } from '../../DTOs/schoolEvent';
// import { iCollectionModel } from '../../Interfaces/modelling';

// import {
// 	iSchoolEventAction,
// 	iUpateSchoolEventAction,
// 	iUpateAllSchoolEventsAction,
// 	iIsFetchingSchoolEventsAction,

// } from '../../State/SchoolEvents/types';


// /**
//  * School Event(s) State Actions Interface
//  */
// export interface iSchoolEventActionCreators {
//     resetAll(): iSchoolEventAction,
//     updateSchoolEvent(userDTO: iSchoolEventDTO): iUpateSchoolEventAction,
//     isFetchingSchoolEvents(isFetching?: boolean): iIsFetchingSchoolEventsAction,
//     updateAllSchoolEvents(schoolEventsDTOCollection: iCollectionModel<iSchoolEventDTO>): iUpateAllSchoolEventsAction,
// }



// !!!!!!!!!!!!!!!!!! READY FOR DELETION !!!!!!!!!!!!!!!!!!!!!!!!!!!!


/**
 * 
 *  ~ School Events Action / Action Types
 * 
 */

import { Action } from 'redux';
import { iCollectionModel } from '@domain/Interfaces/modelling';
import { iSchoolEventStoreDTO } from '@domain/Interfaces/AppState/schoolEvent/dto';


export enum SchoolEventActionTypes {
    RESET_ALL = 'RESET_ALL_SCHOOL_EVENTS_ACTION',
    UPDATE_SCHOOL_EVENT = 'UPDATE_SCHOOL_EVENT_ACTION',
    FETCHING_SCHOOL_EVENTS = 'FETCHING_SCHOOL_EVENTS_ACTION',
    UPDATE_ALL_SCHOOL_EVENTS = 'UPDATE_ALL_SCHOOL_EVENTS_ACTION',
}

export interface iIsFetchingSchoolEventsAction extends Action<SchoolEventActionTypes> {
    isFetching: boolean,
}

// Generic
export interface iSchoolEventAction extends Action<SchoolEventActionTypes> {}

// Specific
export interface iUpateSchoolEventAction extends Action<SchoolEventActionTypes> {
    event: iSchoolEventStoreDTO,
}

export interface iUpateAllSchoolEventsAction extends Action<SchoolEventActionTypes> {
    events: iCollectionModel<iSchoolEventStoreDTO>,
}

