/*
	~ User Reducer Actions
*/

import { iUserStoreDTO } from '@domain/Interfaces/AppState/user/dto';

import {
	iUserAction,
	UserActionTypes,
	iUpateUserAction,
	isFetchingUserAction,
	iAuthenticateUserAction,

} from './types';


/* ACTION CREATORS */

export function isFetchingUser(isFetching = true): isFetchingUserAction {
	return {
		isFetching,
		type: UserActionTypes.FETCHING_AUTHENTICATION,
	};
}

export function updateUserAuthenticationState(isAuthenticated: boolean): iAuthenticateUserAction {
	return {
		isAuthenticated,
		type: UserActionTypes.UPDATE_USER_AUTHENTICATION,
	};
}

export function updateAuthentication(userDTO: Partial<iUserStoreDTO>): iUpateUserAction {
	return {
		user: {
			...userDTO,
		},
		type: UserActionTypes.UPDATE_USER,
	};
}

export function resetAll(): iUserAction {
	return {
		type: UserActionTypes.RESET_ALL 
	};
}

export function resetFeatureAccess(): iUserAction {
	return {
		type: UserActionTypes.RESET_FEATURES
	};
}