/*
	~ Global style helper functions
*/


/**
 * Takes and angle and the colours needed for gradient and produces the 
 * CSS styles for it
 * 
 * @param angle 
 * @param colorsArray 
 * @returns 
 */
export function getLinearGradient(angle: string, colorsArray: string[] = []) {
	let background = null;

	if(Array.isArray(colorsArray) && colorsArray.length > 0) {
		background = `linear-gradient(${angle}, ${colorsArray.join(', ')})`
	}

	return background;
}


export interface iRGBObject {
	r: number,
	g: number,
	b: number,
}

export function getRGBAColor(color: string, percentage: number) {
	const rgbObj = hexToRgb(color);

	if (rgbObj) {
		return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${percentage})`;
	}
}


export function hexToRgb(hex: string): iRGBObject | null {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => { return r + r + g + g + b + b; });
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}