/**
 * 
 * 	~ All Schools Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { DRAWER_WIDTH } from '@atoms/_searchFilterDrawer';


const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		overflowX: 'hidden',
		flexDirection: 'column',
	} as CSSObject,
	content: {
		width: '100%',
		minHeight: '85vh',
		position: 'relative',
        
	} as CSSObject,
	schoolsContainer: {
		width: '100%',
		transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

	} as CSSObject,
	schoolsContainerCompact: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,

	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;