/**
 * 	
 * 	~ Shared User Access Control Enums
 * 
 */

export enum FeatureAccessLevels {
	RESTRICTED,
	MINIMAL,
	EXTRA,
	UNRESTRICTED,
	ADMIN
}