/**
 *
 *  ~ Registration Errors
 *
 */

import ErrorBase from './base';
import ModelError from './model';

import { MODEL_ERRORS } from '@data/Shared/Enums/errors/model';
import { REGISTRATION_ERRORS } from '@data/Shared/Enums/errors/registration';


export const MESSAGE_MAX_CHARS = 250;


/**
 * Login Error Interface
 */
export interface IRegistrationError extends ErrorBase {
    type: REGISTRATION_ERRORS,
}

/**
 * Login Error Model
 */
export default class RegistrationError implements IRegistrationError {
	_message: string;
	_type: REGISTRATION_ERRORS;

	constructor({
		type,
		message,
	}: {
        message: string,
        type: REGISTRATION_ERRORS,
    }) {
		this._message = message;
		this._type = type;
	}


	// Message
	get message(): string {
		return this._message;
	}

	set message(newMessage: string) {
		if (newMessage.length <= MESSAGE_MAX_CHARS) {
			this._message = newMessage;

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.messageMaxLength,
				message: `Message can only be ${MESSAGE_MAX_CHARS} chars or less`,
			});
		}

	}

	// Type
	get type(): REGISTRATION_ERRORS {
		return this._type;
	}

	set type(newType: REGISTRATION_ERRORS) {
		this._type = newType;
	}
}
