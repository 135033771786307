/**
 *
 * ~ Web-related Extension Functions
 *
 */

import ModelError from '@data/Entities/errors/model';

import { isValid } from '@data/Shared/Validation/strings';
import { MODEL_ERRORS } from '@data/Shared/Enums/errors/model';
import { validUrl, validEmailRegex } from '@data/Shared/Validation/regex';


/**
 * Guards Any Class Accessor From Invalid Email Storage
 * @param target
 * @param propertyKey
 * @param descriptor
 */
export const guardEmailFromInvalidFormat = (
	classProp = '_email'

) => (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	descriptor.get = function() {
		return this[classProp as keyof PropertyDescriptor];
	};

	descriptor.set = function(newEmail: string) {
		if (isValid(newEmail, validEmailRegex)) {
			this[classProp as keyof PropertyDescriptor] = newEmail;

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.invalidEmail,
				message: `Email was not formatted correctly for ${propertyKey} property`,
			});
		}
	};
};


/**
 * Guards any class accessor from invalid urls
 * @param classProp 
 * @returns 
 */
export const guardURLFromInvalidFormat = (
	classProp = '_url'

) => (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	descriptor.get = function() {
		return this[classProp as keyof PropertyDescriptor];
	};

	descriptor.set = function(newURL: string) {
		if (isValid(newURL, validUrl)) {
			this[classProp as keyof PropertyDescriptor] = newURL;

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.invalidUrl,
				message: `URL was not formatted correctly for ${propertyKey} property`,
			});
		}
	};
};
