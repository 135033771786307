/**
 * 
 * 	
 * 
 */

import moment from 'moment';

import { filter } from './search';
import { iSchoolDuct } from '@ducts/Interfaces/school';

import {
	iSchoolUI,
	SchoolType,
	SchoolFilter,
	SchoolInformationFilters,

} from '@interfaces/schools';


/**
 * Turns State Ducts into School UI models
 * @param schools 
 * @returns 
 */
export function transformStateSchoolsToSchoolUIs(
	schools: iSchoolDuct[],
): iSchoolUI[] {
	const timelineEvents: iSchoolUI[] = [];

	if (schools?.length > 0) {
		for (const school of schools) {
			const {
				name,
				created,
				updated,
				...remainingSchoolFields

			} = school;

			timelineEvents.push({
				...remainingSchoolFields,
				name: name || '',
				created: moment(created),
				updated: moment(updated),
			});
		}
	}

	return timelineEvents;
}

export function filterSchools(schools: iSchoolDuct[], filters: SchoolFilter[]): iSchoolDuct[] {
	let filteredSchools: iSchoolDuct[] = [];

	if (schools.length > 0 && filters.length > 0) {
		filteredSchools = filter(schools, filters, checkSchoolDataBasedOnFilter);
	}

	return filteredSchools;
}

export function checkSchoolDataBasedOnFilter(
	school: iSchoolDuct, 
	schoolFilter: SchoolFilter
): iSchoolDuct | null {
	let foundSchool: iSchoolDuct | null = null;

	switch (schoolFilter.filter) {
		// School-based Filters
		case SchoolInformationFilters.byCity:
			if (school.city === schoolFilter.keyword) {
				foundSchool = school;
			}
			break;

		case SchoolType.elementary:
			if (school.type === SchoolType.elementary) {
				foundSchool = school;
			}	
			break;
			
		case SchoolType.highschool:
			if (school.type === SchoolType.highschool) {
				foundSchool = school;
			}	
			break;
			
		case SchoolType.juniorHigh:
			if (school.type === SchoolType.juniorHigh) {
				foundSchool = school;
			}	
			break;
			
		case SchoolType.prekAndKindergarten:
			if (school.type === SchoolType.prekAndKindergarten) {
				foundSchool = school;
			}	
			break;

		default:
			break;
	}

	return foundSchool;
}