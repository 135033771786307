/**
 * 
 *  ~ User Validation Functions
 * 
 */

import RegistrationError from '@data/Entities/errors/registration';

import { iUserDuct } from '@ducts/Interfaces/user';
import { REGISTRATION_ERRORS, REGISTRATION_MESSAGES } from '@data/Shared/Enums/errors/registration';


export function userDTOHasRequiredFields(userDuct: iUserDuct) {
	let hasRequiredFields = true;

	if (userDuct) {
		const {
			email, password 
		} = userDuct;
		let foundError = false
		let message = 'Could not create user due to an unknown error';
		let type: REGISTRATION_ERRORS = REGISTRATION_ERRORS.couldNotCreateUser;

		if (!email) {
			foundError = true;
			type = REGISTRATION_ERRORS.emailIsRequired;
			message = REGISTRATION_MESSAGES[REGISTRATION_ERRORS.emailIsRequired];

		}
		if (!password) {
			foundError = true;
			type = REGISTRATION_ERRORS.passwordIsRequired;
			message = REGISTRATION_MESSAGES[REGISTRATION_ERRORS.passwordIsRequired];
		}

		if (foundError && type) {
			hasRequiredFields = false;
			throw new RegistrationError({
				type, message 
			});
		}
	}

	return hasRequiredFields;
}