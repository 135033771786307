/**
 * 
 *  ~ Filled Title Component
 * 
 */

import { CSSObject } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { hasChildren } from '@interfaces/React';

const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		paddingTop: 35,
		paddingBottom: 35,

		background: theme.palette.primary.dark,
		
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderColor: theme.palette.action.disabled,
	} as CSSObject,
	title: {
		letterSpacing: 10,
		fontWeight: 'bold',
		textTransform: 'uppercase',
		margin: '0px 35px 0px 35px',
		color: theme.palette.primary.contrastText,
	} as CSSObject,
});

type stylesType = ReturnType<typeof Styles>;


interface iFilledTitleProps extends getClassesType<stylesType>, hasChildren {}


const FilledTitle = (props: iFilledTitleProps) => {
	const { children } = props;
	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<div className={classes.container}>
			<h2 className={classes.title}>
				{ children }
			</h2>
		</div>
	);
};


export default withStyles(FilledTitle, Styles);