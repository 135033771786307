/*
	~ Lure Box Component
*/

import includeClasses from 'classnames';

import { Card } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { hasChildren } from '@interfaces/React';
import { getClassesType } from '@interfaces/tssReact';

import {
	useState,
	ReactElement,

} from 'react';


const WIDTH = 200;
const HEIGHT = 275;
const SLIDE_IN_HEIGHT = HEIGHT / 5;

const Styles = (theme: Theme) => ({
	container: {
		padding: 25,
		width: WIDTH,
		height: HEIGHT,
		display: 'flex',
		cursor: 'pointer',
		overflow: 'hidden',
		textAlign: 'center',
		alignItems: 'center',
		position: 'relative',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.contrastText,
		borderBottom: `${theme.palette.primary.main} solid 10px`,
	} as CSSObject,
	active: {} as CSSObject,
	slideInContainer: {
		left: 0,
		width: '100%',
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		height: SLIDE_IN_HEIGHT,
		justifyContent: 'center',
		transition: '80ms ease-in',
		bottom: -((SLIDE_IN_HEIGHT) + 5),
		backgroundColor: theme.palette.primary.main,
	} as CSSObject,
	slideIn: {
		bottom: 0,
	} as CSSObject,
	slideInText: {
		fontSize: 14,
		fontWeight: 100,
		textAlign: 'center',
		textTransform: 'uppercase',
		color: theme.palette.primary.contrastText,
	} as CSSObject,
	overlay: {
		opacity: 1,
		width: '100%',
		height: '100%',
		top: 0, left: 0,
		position: 'absolute',
		transition: '100ms linear',
		backgroundColor: 'rgba(55, 55, 55, 0.1)',
	} as CSSObject,
	overlayHidden: {
		opacity: 0,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iLureBox extends getClassesType<stylesType>, hasChildren {
	onClick?: () => void,
	slideInText?: string,
	slideInIcon?: ReactElement | ReactElement[],
}


const LureBox = (props: iLureBox) => {
	const {
		children, 
		slideInText,
		slideInIcon,
		onClick = () => {/** */},
 
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);
	const [isHovering, setIsHovering] = useState(false);

	const handleOnMouseOver = () => {
		if (!isHovering) {
			setIsHovering(true);
		}
	};
	
	const handleOnMouseLeave = () => {
		if (isHovering) {
			setIsHovering(false);
		}
	};

	return (
		<Card 
			elevation={4}
			onClick={onClick}
			onMouseOver={handleOnMouseOver}
			onMouseLeave={handleOnMouseLeave}
			className={
				includeClasses({
					[classes.container]: true,
					[classes.active]: isHovering,
				})
			}>

			{ children }

			<div 
				className={
					includeClasses({
						[classes.overlay]: true,
						[classes.overlayHidden]: !isHovering,
					})
				} />

			<div 
				className={
					includeClasses({
						[classes.slideInContainer]: true,
						[classes.slideIn]: isHovering,
					})
				}>
				<h4 className={classes.slideInText}>
					{ slideInText }
				</h4>

				{ slideInIcon }
			</div>
		</Card>
	);
};


export default withStyles(LureBox, Styles);
