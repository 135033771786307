/**
 * 
 *  ~ Authentication Entity Data Domain
 * 
 */

import BaseEntity, { iBaseEntity } from '../BaseEntity';

import { guardEmailFromInvalidFormat } from '@data/Shared/Validation/model/web';


export interface iAuth extends Pick<iBaseEntity, 'id'> {
    email: string,
    password: string,
	isAuthenticated: boolean,
}


/**
 * Authentication Entity
 */
export default class Authentication extends BaseEntity implements iAuth {
	private _email = '';
	private _password = '';
	private _isAuthenticated = false;

	constructor({
		id,
		email,
		password,
		isAuthenticated,
	}: {
        id?: string,
		email?: string,
        password?: string,
        isAuthenticated?: boolean,
        
    }) {
		super(id || 'none');

		if (email) this.email = email;
		if (password) this.password = password;
		if (isAuthenticated) this.isAuthenticated = isAuthenticated;
	}

    @guardEmailFromInvalidFormat()
	set email(newEmail: string) {
		this._email = newEmail;
	}
    
    get email(): string {
    	return this._email;
    }

    get password(): string {
    	return this._password;
    }

    set password(newPassword: string) {
    	this._password = newPassword;
    }

    get isAuthenticated(): boolean {
    	return this._isAuthenticated;
    }

    set isAuthenticated(newIsAuthenticated: boolean) {
    	this._isAuthenticated = newIsAuthenticated;
    }
    
}