/**
 * 
 *  ~ Domain layer Authentication Error Handling Access
 * 
 */

import LoginError from '@data/Entities/errors/login';
import RegistrationError from '@data/Entities/errors/registration';

import { LOGIN_ERRORS, LOGIN_MESSAGES } from '@data/Shared/Enums/errors/login';
import { REGISTRATION_ERRORS, REGISTRATION_MESSAGES } from '@data/Shared/Enums/errors/registration';


/**
 * Triggers a System-level Login Error by giving a valid (and known by 
 * the provider beforehand) key for a login error
 * 
 * @param errorType 
 * @param message 
*/
export function triggerLoginError(errorType: LOGIN_ERRORS) {
	throw new LoginError({
		type: errorType,
		message: LOGIN_MESSAGES[errorType],
	});
}


/**
 * 
 * Triggers a System-level Registration Error by giving a valid (and known by 
 * the provider beforehand) key for a registration error
 * @param errorType 
 * @param message 
 */
export function triggerRegistrationError(errorType: REGISTRATION_ERRORS) {
	throw new RegistrationError({
		type: errorType,
		message: REGISTRATION_MESSAGES[errorType],
	});
}