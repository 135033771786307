/*
    ~ [CLIENT POTAL] School Timeline View
*/

import includeClasses from 'classnames';
import SchoolSelector from '@atoms/_schoolsSelector';
import BackToTopButton from '@atoms/_backToTopButton';
import SearchFilterDrawer from '@atoms/_searchFilterDrawer';
import SearchFilterAppBar from '@atoms/_searchFilterAppBar';
import PaginatedEventTimeline from '@cells/PaginatedEventTimeline';

import Styles, { stylesType } from './styles';

import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { iSchoolUI } from '@interfaces/schools';
import { getClassesType } from '@interfaces/tssReact';
import { useSearchFilters } from '@hooks/searchAndFilter';
import { SpecialSearchFilters } from '@interfaces/filters';
import { iSchoolEventDuct } from '@ducts/Interfaces/schoolEvents';
import { selectAllSchoolEventsDuct } from '@ducts/DuctSelectors/schoolEvent';
import { TimelineFilterLabels, getFilterOptions } from '@settings/timelineFilterOptions';
import { checkEventDataBasedOnFilter, transformStateEventsToSchoolEventTimeline } from '@modelling/schoolEvent';

import {
	useNavigate, 
	useSearchParams,

} from 'react-router-dom';

import {
	useState,
	useEffect,

} from 'react';

import {
	TimelineFilter, 
	TimelineFilters,
	AllTimelineFilters,
	TimelineFilterOption,

} from '@interfaces/schoolEvent';
import { Typography } from '@mui/material';


const DRAWER_DEFAULT_OPEN = true;

interface iSchoolTimelineView extends getClassesType<stylesType> {}


const CPTimelineView = (props: iSchoolTimelineView) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const filtrationOptions = getFilterOptions();
	const classes = withStyles.getClasses<stylesType>(props);

	const stateEvents: iSchoolEventDuct[] = useSelector(selectAllSchoolEventsDuct);
	
	const [selectedSchools, setSelectedSchools] = useState([] as iSchoolUI[]);
	const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState(DRAWER_DEFAULT_OPEN);

	const {
		filters,
		addFilter,
		removeFilter,
		items: timelineEventDucts,

	} = useSearchFilters({
		items: stateEvents,
		onRemove: (timelineFilter: TimelineFilter) => {
			if (timelineFilter.filter === TimelineFilters.bySchool) {
				setSelectedSchools(
					Array.from(selectedSchools).filter(school => school.uuid !== timelineFilter.keyword)
				);
			}
		},
		onFilter: checkEventDataBasedOnFilter,
		onSort: (eventA: iSchoolEventDuct, eventB: iSchoolEventDuct) => {
			if (eventA.datePublicized && eventB.datePublicized) {
				if (eventA.datePublicized?.isBefore(eventB?.datePublicized)) {
					return 1;
				
				} else {
					return -1;
				}

			} else {
				return 0;
			}
		}
	});

	const schoolTimelineEvents = transformStateEventsToSchoolEventTimeline(timelineEventDucts);

	useEffect(() => {
		const timeBasedFilterParam = searchParams.get(TimelineFilters.byTime);
		
		if (timeBasedFilterParam) {
			addFilter([{
				filter: timeBasedFilterParam as TimelineFilters,
				label: TimelineFilterLabels[timeBasedFilterParam],
			}]);
		}
	}, []);

	const handleListItemOnClick = (filter: string) => {
		// Special "All" filter and functionality
		if (filter === SpecialSearchFilters.all) {
			removeFilter({
				label: '',
				filter: SpecialSearchFilters.all,
			});

			setSelectedSchools([]);
		}
	};

	const handleSubListItemOnClick = (timelineFilter: TimelineFilter) => {
		addFilter([timelineFilter]);
	};
	
	const handleFilterOnDelete = (timelineFilter: TimelineFilter) => {
		removeFilter(timelineFilter);

		if (timelineFilter.filter === SpecialSearchFilters.all) {
			setSelectedSchools([]);
		}
	};

	return (
		<div className={classes.container}>
			<SearchFilterAppBar<AllTimelineFilters>
				filters={filters}
				onFilterDelete={handleFilterOnDelete}
				onDrawerButtonClick={() => setFilterDrawerIsOpen(true)} />

			<div className={classes.content}>
				<div 
					className={
						includeClasses({
							[classes.eventContainer]: true,
							[classes.eventContainerCompact]: filterDrawerIsOpen,
						})}>
				    
					{
						(schoolTimelineEvents.length <= 0 && filters.length > 0) ? (
							<Typography 
								variant={'body2'} 
								sx={{
									mt: 10,
									textAlign: 'center',
								}}>
								<strong>No events to show for this combination of filters.</strong><br />
								<em>Please remove some / all.</em>
							</Typography>
						) : 
							<PaginatedEventTimeline events={schoolTimelineEvents} />
					}
				</div>

				<SearchFilterDrawer<AllTimelineFilters>
					isOpen={filterDrawerIsOpen}
					filtrationOptions={filtrationOptions}
					onListItemClick={handleListItemOnClick}
					onSubListItemClick={handleSubListItemOnClick}
					onDrawerClose={() => {setFilterDrawerIsOpen(false)}}
					renderSubListItem={(timelineFilter: TimelineFilterOption) => {
						if (timelineFilter.filter === TimelineFilters.bySchool) {
							return (
								<SchoolSelector
									multiple={true}
									id={'timeline-school-selector'}
									value={selectedSchools?.map(school => school.uuid) || []}
									onChange={(schools: iSchoolUI | iSchoolUI[]) => {
										if (Array.isArray(schools)) {
											const newSchools = Array.from(selectedSchools);

											for (const school of schools) {
												if (!newSchools.some(currSchool => currSchool.uuid === school.uuid)) {
													newSchools.push(school)
												}
											}

											setSelectedSchools(newSchools);
						
											addFilter(
												schools.map(s => ({
													label: s.name,
													keyword: s.uuid,
													aggregate: true,
													filter: TimelineFilters.bySchool,
												}))
											);
										}
									}}
								/>
							);
						
						} else {
							return null;
						}
					}}
					 />
			</div>

			<BackToTopButton tipLabel={'Back to Now!'} />
		</div>
	);
};


export default withStyles(CPTimelineView, Styles);