/*
	~ Privacy Policy Component
*/

// import { useState } from 'react';
import Navbar from '@molecules/Navbar';

import Styles, { stylesType } from './styles';

import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
// import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { mainNavMenuItems } from '@settings/mainNavOptions';


interface iPrivacyPolicyProps extends getClassesType<stylesType> {}

const PrivacyPolicy = (props: iPrivacyPolicyProps) => {
	// const navigate = useNavigate();
	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<div className={classes.container}>
			<Navbar 
				menuItems={
					mainNavMenuItems({
						signupButtonCallback: () => {
							// navigate(Paths.register);
						}
					})
				} />

			<div className={classes.content}>
				<div className={classes.contentBox}>
					<Typography variant={'h2'} style={{
						marginBottom: 40 
					}}>
						SchoolReports.ca Privacy Policy
					</Typography>
					
					<Typography variant={'h3'}>
						Introduction
					</Typography>
					
					<Typography variant={'body1'}>
						Welcome to SchoolReports.ca, a website managed by Nautilus Cove Technologies, 
						based in Nova Scotia, Canada. This Privacy Policy outlines the types of 
						information we collect from public sources, how we use this information, and 
						the steps we take to protect your privacy.
					</Typography>

					<Typography variant={'h3'}>
						Information Collection
					</Typography>
					
					<Typography variant={'h4'}>
						Public Data Sources
					</Typography>
					<Typography variant={'body1'}>
						We collect data from publicly accessible sources, including:
					</Typography>

					<ul>
						<li>
							<strong>Social Media Platforms:</strong> Facebook, Instagram, Twitter, 
							and other similar platforms.
						</li>

						<li>
							<strong>Major News Sources:</strong> Canadian news organizations such 
							as The Canadian Broadcasting 
							Corporation (CBC) and Canadian Television (CTV).
						</li>
					</ul>

					<Typography variant={'h4'}>
						Nature of Data
					</Typography>

					<ul>
						<li>
							<strong>Public Data Only:</strong> We only collect data that is publicly 
							available. No private data is sought after or collected.
						</li>

						<li>
							<strong>Unaltered Data:</strong> The data collected is not altered in any 
							way by Nautilus Cove Technologies. It is presented on our website as it 
							is sourced from public platforms.
						</li>
					</ul>


					<Typography variant={'h3'}>
						Data Usage
					</Typography>

					<Typography variant={'body1'}>
						The information collected from public sources is used to:
					</Typography>

					<ul>
						<li>
							Provide relevant and up-to-date reports and content on SchoolReports.ca.
						</li>

						<li>
							Ensure the accuracy and integrity of the information presented on our website.
						</li>
						
						<li>
							Elevate "up to date" school-related events so that contextual users may be 
							able to find this information in a "streamlined", efficient and speedy way.
						</li>
					</ul>
					
					<Typography variant={'h3'}>
						Data Protection
					</Typography>
					
					<Typography variant={'body1'}>
						While we only collect publicly available data, we are committed to maintaining 
						the privacy and security of all information. We do not collect or store any private 
						data and we take appropriate measures to ensure the data we use is sourced responsibly.
					</Typography>


					<Typography variant={'h3'}>
						Third-Party Links
					</Typography>

					<Typography variant={'body1'}>
						Our website may contain links to third-party websites. This Privacy Policy does not apply 
						to those websites, and we encourage you to review the privacy policies of any third-party 
						sites you visit.
					</Typography>

					<Typography variant={'h3'}>
						Changes to this Privacy Policy
					</Typography>

					<Typography variant={'body1'}>
						We may update this Privacy Policy from time to time. Any changes will be posted on this 
						page, and we encourage you to review our Privacy Policy periodically.
					</Typography>

					<Typography variant={'h3'}>
						Contact Us
					</Typography>

					<Typography variant={'body1'}>
						If you have any questions about this Privacy Policy, please contact us at:
					</Typography>

					<Typography variant={'body1'}>
						<strong>Nautilus Cove Technologies</strong>
						<br />
						<Typography variant={'body1'}>Nova Scotia, Canada</Typography>
						<br />
						<strong>Email:</strong> <a href="mailto:info@nautiluscove.ca">info@nautiluscove.ca</a>
					</Typography>

					<Typography variant={'body1'}>
						<em>
							By using SchoolReports.ca, you agree to the terms outlined in this Privacy Policy.
							Thank you for visiting our website.
						</em>
					</Typography>
					
					<Typography variant={'body1'}>
						<em>
							This Privacy Policy is effective as of July 20th, 2024.
						</em>
					</Typography>
				</div>
			</div>
		</div>
	);
};


export default withStyles(PrivacyPolicy, Styles);