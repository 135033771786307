/**
 * 
 * 	~ Authentication Domain Modelling
 * 
 */

import Authentication, { iAuth } from '@data/Entities/User/authentication';

import { iModelling } from '@domain/Interfaces/modelling';
import { iAuthStoreDTO } from '@domain/Interfaces/AppState/user/dto';
import { iRemoteAuthenticationDTO } from '@domain/Interfaces/remoteDTOs/user';


/**
 * Model Authentication from DTOs to Models and back
 */
export const RemoteAuthModelling: iModelling<iRemoteAuthenticationDTO, iAuth> = {
	toDTO: (user: iAuth) => {
		const {
			email,
			password,
			isAuthenticated,

		} = user;

		return {
			email,
			password,
			isAuthenticated,
		};
	},
	toModel: (userDTO: iRemoteAuthenticationDTO) => {
		const {
			email,
			password,

		} = userDTO;

		return new Authentication({
			email,
			password,
		});
	},
};


/**
 * Model Authentication from DTOs to Models and back
 */
export const AuthStoreModelling: iModelling<iAuthStoreDTO, iAuth> = {
	toDTO: (auth: iAuth) => {
		const {
			email,
			password,
			isAuthenticated,

		} = auth;

		return {
			email,
			password,
			isAuthenticated,
		};
	},
	toModel: (authDTO: iAuthStoreDTO) => {
		const {
			email,
			password,
			isAuthenticated,

		} = authDTO;

		return new Authentication({
			email,
			password,
			isAuthenticated,
		});
	},
};