/**
 * 
 *  ~ State Selectors - School Event(s)
 * 
 */

import { RootStoreState } from '../State';
import { UUID } from '@ducts/Types/generic';
import { getSchoolsByUUIDs } from './school';
import { iSchoolStoreDTO } from '@domain/Interfaces/AppState/school/dto';
import { iSchoolEventState } from '@domain/Interfaces/AppState/schoolEvent/store';
import {
	iSchoolEventStoreCollectionDTO, iSchoolEventStoreCollectionRelationshipsDTO, iSchoolEventStoreDTO 
} from '@domain/Interfaces/AppState/schoolEvent/dto';


/**
 * Gets all school events in store and returns them
 * @param param0 
 * @returns 
 */
export const selectAllSchoolEvents = <RequestedEventModel>(
	modellingCallback: (eventsToModel: iSchoolEventState) => RequestedEventModel
) => ({ events }: RootStoreState): RequestedEventModel => modellingCallback(events);
	
	
/**
 * Gets all school events in store along with the schools they belong to (if any) and returns the entire selection
 * @param param0 
 * @returns 
 */
export const selectAllSchoolEventsWithRelatedSchools = <RequestedEventModel>(
	modellingCallback: (eventsToModel: iSchoolEventStoreCollectionRelationshipsDTO) => RequestedEventModel
) => ({
		events,
		schools, 
	}: RootStoreState): RequestedEventModel => {
		const schoolEvents: iSchoolEventStoreCollectionRelationshipsDTO = {
			data: {},
			order: [],
		};

		if (events.order?.length > 0) {
			for (const uuid of events.order) {
				const { schoolUuids } = events.data[uuid];
				const eventsSchools: iSchoolStoreDTO[] = [];

				if (schoolUuids && schoolUuids?.length > 0) {
					const foundSchoolState = getSchoolsByUUIDs(schoolUuids, schools);

					if (foundSchoolState.order?.length > 0) {
						for (const schoolUuid of foundSchoolState.order) {
							eventsSchools.push({
								...foundSchoolState.data[schoolUuid]
							});
						}
					}
				}
				
				schoolEvents.data[uuid] = {
					...events.data[uuid],
					schools: Array.from(eventsSchools),
				};
			}

			schoolEvents.order = events.order;
		}

		return modellingCallback(schoolEvents);
	}; 


/**
 * Gets all events that belong to the given School UUID
 * @param schoolUuid 
 * @param modellingCallback 
 * @returns 
 */
export const selectAllEventsByschool = <RequestedEventModel>(
	schoolUuid: UUID,
	modellingCallback: (eventsToModel: iSchoolEventStoreCollectionDTO) => RequestedEventModel
) => ({ events }: RootStoreState): RequestedEventModel => {
		const schoolEvents: iSchoolEventStoreCollectionDTO = {
			data: {},
			order: [],
		};

		if (events.order?.length > 0) {
			for (const uuid of events.order) {
				const { schoolUuids } = events.data[uuid];

				if (schoolUuids?.includes(schoolUuid)) {
					schoolEvents.order.push(uuid);

					schoolEvents.data[uuid] = {
						...events.data[uuid],
					};
				}
			}
		}

		return modellingCallback(schoolEvents);
	};