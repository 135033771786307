/*
	~ Stripe Pricing Table Component
*/

import 'https://js.stripe.com/v3/pricing-table.js';

// import { DetailedHTMLProps, useState } from 'react';
import { Theme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
// import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';



const PRICING_TABLE_ID = 'prctbl_1PmQdPRuKcWsmPE56FxeIAZI';
// eslint-disable-next-line
const PUBLISH_KEY = 'pk_test_51PBEuBRuKcWsmPE55eYhoQKVIPiaUttvxpJQzqDXyNM9nz9LkaoiZ0VxENIHLsQEorZ1VdRFbkRFuhJYwQr5GmTo00McdK6aoJ';


const Styles = (theme: Theme) => ({
	container: {} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

/* eslint-disable */
declare global {
	namespace JSX {
		interface IntrinsicElements {
			'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
		}
	}
}
/* eslint-enable */


interface iPricingTableProps extends getClassesType<stylesType> {}

const PricingTable = (props: iPricingTableProps) => {
	// const navigate = useNavigate();
	// const [, set] = useState(0);
	// const classes = withStyles.getClasses<stylesType>(props);

	return (
		<stripe-pricing-table publishable-key={PUBLISH_KEY} pricing-table-id={PRICING_TABLE_ID}  />
	);
};


export default withStyles(PricingTable, Styles);