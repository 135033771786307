/**
 * 
 * 	~ User Store Provider
 * 
 */


import { UserActions } from '@ducts/Providers/ApplicationState/Actions';
import { dispatchStoreSelector } from '../StateSelectors/createSelector';
import { iUserStoreProvider } from '@domain/Interfaces/AppState/user/store';
import { selectUser } from '@ducts/Providers/ApplicationState/StateSelectors/user';


export const UserStoreProvider: iUserStoreProvider = {
	actions: UserActions,
	selectors: {
		getUser: () =>
			dispatchStoreSelector(
				selectUser
			),
		getAuth: () =>
			dispatchStoreSelector(
				selectUser
			),
	},
};