/**
 * 
 * 	~ Privacy Policy Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {} as CSSObject,
	content: {
		display: 'flex',
		paddingTop: '10vh',
		alignItems: 'center',
		paddingBottom: '10vh',
		flexDirection: 'column',
	} as CSSObject,
	contentBox: {
		width: '65%',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;