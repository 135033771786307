/**
 * 
 * 	~ Selector Controllers - App
 * 
 */

import { iAppStatusDuct } from '@ducts/Interfaces/app';
import { applicationStatus } from '@ducts/Providers/ApplicationState/StateSelectors/app';


/**
 * Uses internal state selector to get application initialization state from store
 */
export const getApplicationStatus = applicationStatus((statusDTO: iAppStatusDuct) => statusDTO);