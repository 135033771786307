/**
 * 
 *  ~ MUI Library Types - Drawer
 * 
 */


/**
 * Drawer Anchor Prop =====
 */

export enum DrawerAnchors {
    top = 'top',
    right = 'right',
    left = 'left',
    bottom = 'bottom',
}

export type DrawerAnchorTypes = 
    DrawerAnchors.top | 
    DrawerAnchors.right | 
    DrawerAnchors.left |
    DrawerAnchors.bottom;

// ======================


/**
 * Drawer Variants =====
 */

export enum DrawerVariants {
    temporary = 'temporary',
    permanent = 'permanent',
    persistent = 'persistent',
}

export type DrawerVariantTypes = 
    DrawerVariants.temporary |
    DrawerVariants.permanent |
    DrawerVariants.persistent;

// ======================