
/**
 * 
 * Compact Timeline Component
 * 
 */

import moment from 'moment';
import ArrowButton from '@molecules/_arrowButton';
import EventTimeline from '@molecules/EventTimeline';

import Styles, { stylesType } from './styles';

import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getClassesType } from '@interfaces/tssReact';
import { iSchoolEventDuct } from '@ducts/Interfaces/schoolEvents';
import { getPipelineDuct } from '@ducts/DuctSelectors/pipeline';
import { iSchoolTimelineEvent, TimelineFilters } from '@interfaces/schoolEvent';
import { selectAllEventsByschoolUUIDDuct } from '@ducts/DuctSelectors/schoolEvent';
import { transformStateEventsToSchoolEventTimeline } from '@modelling/schoolEvent';


const PAGE_SIZE = 25;


interface iCompactEventTimelineProps extends getClassesType<stylesType> {
	schoolUUID: string,
}


const CompactEventTimeline = (props: iCompactEventTimelineProps) => {
	const { schoolUUID } = props;
	const navigate = useNavigate();
	const pipeline = useSelector(getPipelineDuct);

	const stateEvents: iSchoolEventDuct[] = useSelector(
		selectAllEventsByschoolUUIDDuct(schoolUUID)
	);

	const [loadedEvents, setLoadedEvents] = useState([] as iSchoolTimelineEvent[]);
	
	const theme = useTheme();
	const classes = withStyles.getClasses<stylesType>(props);
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	useEffect(() => {
		const newEvents = Array.from(stateEvents).slice(0, PAGE_SIZE);

		setLoadedEvents(
			transformStateEventsToSchoolEventTimeline(
				newEvents
			)
		);

	}, [JSON.stringify(stateEvents)]);

	const onFullTimelineClick = () => {
		navigate(`${Paths.schoolTimeline}?${TimelineFilters.bySchool}=${schoolUUID}`);
	};

	return (
		<>
			<div className={classes.timelinesTitlebar}>
				<h2 className={classes.timelineTitle}>
					School Timeline
				</h2>
			</div>

			<EventTimeline 
				events={loadedEvents}
				highlightOnHover={true}
				showEndOfTimeline={true}
				lastKnownRunTime={pipeline?.lastRun || moment()} />

			<div className={classes.footer}>
				<ArrowButton 
					label={
						isMobile ? 'Full Timeline' : 
							'Checkout Entire Timeline'
					} 
					onClick={onFullTimelineClick} />
			</div>
		</>
	);

};


export default withStyles(CompactEventTimeline, Styles);