/**
 * 
 *  ~ Notification Modelling
 * 
*/

import Notification, { iNotification } from '@data/Entities/Notifications/notification';

import { iModelling } from '@domain/Interfaces/modelling';
import { iNotificationDTO } from '@domain/Interfaces/remoteDTOs/notification';


/**
 * Model Notification from DTOs to Models and back
 */
export const NotificationModelling: iModelling<iNotificationDTO, iNotification> = {
	toDTO: (notification: iNotification) => {
		const {
			message,
			messageType,

		} = notification;

		return {
			message,
			messageType,
		};
	},
	toModel: (notificationDTO: iNotificationDTO) => {
		const {
			message,
			messageType,

		} = notificationDTO;

		return new Notification({
			message,
			messageType,
		});
	},
};