/**
 * 
 *  ~ App Controller Functions
 * 
 */

import iAuthInteractor from '@domain/Interfaces/Interactor/iAuthenticationInteractor';

import { createAuthInteractor } from '../Factories/auth';
import { createAppInteractor } from '@ducts/Factories/app';
import { iAppInteractor } from '@domain/Interfaces/Interactor/iApplicationInteractor';


/**
 * Initializes Entire Application
 */
export async function initialize() {
	try {
		const appInteractor: iAppInteractor = createAppInteractor();

		appInteractor.initialize(); // <-- INIT! GO!
        
	} catch (error) {
		const authInteractor: iAuthInteractor = createAuthInteractor();
		authInteractor.unauthenticateUser();
	}
}