/**
 * 
 * 	~ School Event Store Provider
 * 
 */


// import { dispatchStoreSelector } from '../StateSelectors/createSelector';
import { SchoolEventActions } from '@ducts/Providers/ApplicationState/Actions';
import { iSchoolEventStoreProvider } from '@domain/Interfaces/AppState/schoolEvent/store';


export const SchoolEventStoreProvider: iSchoolEventStoreProvider = {
	actions: SchoolEventActions,
	selectors: {
		test() {
			//
		},
		// getUser: () =>
		// 	dispatchStoreSelector(
		// 		getUser
		// 	),
	},
};