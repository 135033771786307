/**
 * 
 *  ~ Authentication Provider Service 
 *      Use Case Mixins
 * 
 */


import LoginError from '@data/Entities/errors/login';
import iAuthProvider from '../Interfaces/iAuthProvider';
import RegistrationError from '@data/Entities/errors/registration';

import { log } from '@logging/Console';
import { fireAuthentication } from '../../firebase';
import { handleError } from '@domain/Validation/errorHandling';
import { iRemoteUserDTO } from '@domain/Interfaces/remoteDTOs/user';
import { LOGIN_ERRORS, LOGIN_MESSAGES } from '@data/Shared/Enums/errors/login';
import { REGISTRATION_ERRORS, REGISTRATION_MESSAGES } from '@data/Shared/Enums/errors/registration';
import { normalizeUserResponse, normalizeUserCredentialResponse } from '../Services/Firebase/firebaseConversions';

import {
	AuthError, 
	UserCredential,
	User as FirebaseUser, 
} from 'firebase/auth';


type Constructor<T> = new (...args: any[]) => T;


/**
 * This interface satisfies a small portion of the Domain Layer's 
 * total required iRemoteAuthenticate Interface
 */
export interface iAuthenticationActions extends iAuthProvider {
    unauthenticateUser(): Promise<void>,
	sendPasswordResetEmail(email: string): Promise<void>,
    registerNewUser(email: string, password: string): Promise<iRemoteUserDTO>,
    authenticateUser(username: string, password: string): Promise<iRemoteUserDTO>,
    subscribeToAuthentication(callback: (userDTO: iRemoteUserDTO) => void): Promise<() => void>,
}

type updateCallbackType = (userDTO: iRemoteUserDTO) => void;


/**
 * 
 * @param base 
 * @returns 
 */
export function addUserAccountActions(base: Constructor<iAuthProvider>): 
    Constructor<iAuthenticationActions> & Constructor<iAuthProvider> {

	return class extends base implements iAuthenticationActions {
		/**
         * 
         * @param userDTO 
         * @returns 
         */
		async registerNewUser(email: string, password: string): Promise<iRemoteUserDTO> {
			try {
				if (email && password) {
					const response: UserCredential = await this.authProvider.createUserWithEmailAndPassword(
						fireAuthentication, 
						email, 
						password
					);

					if (response && response?.user?.uid) {
						const { email }: iRemoteUserDTO = normalizeUserCredentialResponse(response);

						return {
							email,
							id: response.user.uid,
							isAuthenticated: true,
						};
                        
					} else {
						throw new RegistrationError({
							type: REGISTRATION_ERRORS.couldNotCreateUser,
							message: REGISTRATION_MESSAGES[REGISTRATION_ERRORS.couldNotCreateUser],
						});
					}
				} else {
					log(
						'error', 
						'error in registering a new user with provider! User creds given: ', 
						`email - ${email}`, 
						`password - ${password}`
					);
					return {};
				}
    
			} catch (error) {
				log('error', 'error in registering a new user with provider!: ', error);
				
				handleError(
                    error as AuthError
				);

				return {};
			}
		}


		/**
         * 
         * @param username 
         * @param password 
         * @returns 
         */
		async authenticateUser(username: string, password: string): Promise<iRemoteUserDTO> {
			try {
				// Sign user in and normalize response to DTO to respond with
				const response: UserCredential = await this.authProvider.signInWithEmailAndPassword(
					fireAuthentication, 
					username, 
					password
				);

				if (response && response?.user?.uid) {
					const { email }: iRemoteUserDTO = normalizeUserCredentialResponse(response);

					return {
						email,
						id: response.user.uid,
						isAuthenticated: true,
					};
					
				} else {
					throw new LoginError({
						type: LOGIN_ERRORS.userNotFound,
						message: LOGIN_MESSAGES[LOGIN_ERRORS.userNotFound],
					});
				}

			} catch (error) {
				// Run this error through error handling to check for errors to throw
				handleError(
                    error as AuthError
				);

				return {};
			}
		}
        
        
		/**
         * 
         * @param username 
         * @param password 
         * @returns 
         */
		async unauthenticateUser(): Promise<void> {
			try {
				await this.authProvider.signOut(fireAuthentication);

			} catch (error) {
				log('error', `error in unauthenticating user: `, error);
			}
		}

		
		/**
		 * 
		 * @param email 
		 */
		async sendPasswordResetEmail(email: string): Promise<void> {
			try {
				await this.authProvider.sendPasswordResetEmail(fireAuthentication, email);
				
			} catch (error) {
				log('error', `error when sending password email to reset account: `, error);
			}
		}


		/**
         * 
         * @param callback 
         * @returns 
         */
		async subscribeToAuthentication(callback: (userDTO: iRemoteUserDTO) => void): Promise<() => void> {
			try {
				return this.authProvider.onAuthStateChanged(fireAuthentication, this.handleAuthStateChanged(callback));
                
			} catch (error) {
				log('error', `error in subscribing to authentication, error: `, error);
                
				throw new LoginError({
					type: LOGIN_ERRORS.userNotLoggedOut,
					message: LOGIN_MESSAGES[LOGIN_ERRORS.userNotLoggedOut],
				});
			}
		}


		/**
         * 
         * @param userUpdateCallback 
         * @returns null if empty otherwise returns userDTO
         */
		private handleAuthStateChanged = (userUpdateCallback: updateCallbackType) => 
			(user: FirebaseUser | null): void => {
				try {
					log('info', `in authentication provider ------ user state change received: `, user);
                
					if (user) {
						userUpdateCallback(
							normalizeUserResponse(user)
						);
					}
                
				} catch (error) {
					log('error', `error in handling auth, error: `, error);
				}
			};
		
	};

}