
/**
 * 
 * Demo Timeline Component
 * 
 */

import moment from 'moment';
import ArrowButton from '@molecules/_arrowButton';
import EventTimeline from '@molecules/EventTimeline';
import SchoolSelector from '@atoms/_schoolsSelector';

import Styles, { stylesType } from './styles';

import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { iSchoolUI } from '@interfaces/schools';
import { getClassesType } from '@interfaces/tssReact';
import { TimelineFilters } from '@interfaces/schoolEvent';
import { getPipelineDuct } from '@ducts/DuctSelectors/pipeline';
import { iSchoolEventDuct } from '@ducts/Interfaces/schoolEvents';
import { selectAllSchoolEventsDuct } from '@ducts/DuctSelectors/schoolEvent';
import { transformStateEventsToSchoolEventTimeline } from '@modelling/schoolEvent';


const PAGE_SIZE = 10;


interface iDemoEventTimelineProps extends getClassesType<stylesType> {}


const DemoEventTimeline = (props: iDemoEventTimelineProps) => {
	const navigate = useNavigate();
	const pipeline = useSelector(getPipelineDuct);

	const stateEvents: iSchoolEventDuct[] = useSelector(selectAllSchoolEventsDuct);
	const [loadedEvents, setLoadedEvents] = useState({} as iSchoolEventDuct[]);
	const [selectedSchool, setSelectedSchool] = useState(null as iSchoolUI | null);
	
	const theme = useTheme();
	const classes = withStyles.getClasses<stylesType>(props);
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	useEffect(() => {
		let newEventsList: iSchoolEventDuct[] = [];

		if (selectedSchool === null) {
			newEventsList = Array.from(stateEvents).slice(0, PAGE_SIZE);
			
		} else {
			newEventsList = Array.from(stateEvents)
				.filter(event => event.schoolUuids && event?.schoolUuids.includes(selectedSchool?.uuid))
				.slice(0, PAGE_SIZE);
		}

		setLoadedEvents(newEventsList);

	}, [stateEvents, selectedSchool]);

	const onFullTimelineClick = () => {
		if (selectedSchool === null) {
			navigate(Paths.schoolTimeline);
			
		} else if (selectedSchool !== null) {
			navigate(`${Paths.schoolTimeline}?${TimelineFilters.bySchool}=${selectedSchool.uuid}`);
		}
	};

	return (
		<>
			{
				isMobile && (
					<div className={classes.mobileSchoolSelector}>
						<SchoolSelector
							id={'timeline-school-selector'}
							value={selectedSchool?.uuid || ''}
							onChange={(school: iSchoolUI | iSchoolUI[]) => {
								if (typeof school === 'string') {
									setSelectedSchool(school);
								}
							}}
						/>
					</div>
				)
			}

			<div className={classes.timelinesTitlebar}>
				<Typography variant='h2' className={classes.timelineTitle}>
					{ selectedSchool?.name || 'Select a school to view the timeline' }
				</Typography>

				{
					!isMobile && (
						<div className={classes.schoolSelector}>
							<SchoolSelector
								value={selectedSchool?.uuid || ''}
								id={'timeline-school-selector'}
								onChange={(school: iSchoolUI | iSchoolUI[]) => {
									if (!Array.isArray(school)) {
										setSelectedSchool(school);
									}
								}}
							/>
						</div>
					)
				}
			</div>

			<EventTimeline 
				highlightOnHover={true}
				showEndOfTimeline={true}
				lastKnownRunTime={pipeline?.lastRun || moment()}
				events={
					transformStateEventsToSchoolEventTimeline(loadedEvents)
				} />

			<div className={classes.footer}>
				<ArrowButton 
					label={
						isMobile ? 'Full Timeline' : 
							'Checkout Entire Timeline'
					} 
					onClick={onFullTimelineClick} />
			</div>
		</>
	);

};


export default withStyles(DemoEventTimeline, Styles);