/**
 * 
 *  ~ Shared Base Entity
 * 
 */

import { UUID } from '@data/Shared/Types/genericModels';


export interface iBaseEntity {
    id: UUID;
    readonly _id: UUID;
}


export default class BaseEntity implements iBaseEntity {
	readonly _id: UUID = 'none';

	constructor(id: UUID) {
		this._id = id;
	}

	get id(): UUID {
		return this._id;
	}
}