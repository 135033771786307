/**
 * 
 * 	~ Notification State Adapters
 * 
 */

import { NotificationModelling } from '@domain/Modelling/notification';
import { iNotification } from '@data/Entities/Notifications/notification';
import { iNotificationStoreProvider } from '@domain/Interfaces/AppState/notification/store';


export interface iNotificationStoreAdapter {
	actions: {
		removeFirstMessageInStack(): void,
		showMessage(notification: iNotification): void,
	},
	selectors: {
		//
	},
}


export default class NotificationStoreAdapter implements iNotificationStoreAdapter {
	constructor(
		private _NotificationStore: iNotificationStoreProvider,
	) {}

	actions = {
		...this._NotificationStore.actions,
		showMessage: (notification: iNotification) => {
			this._NotificationStore.actions.showMessage(
				NotificationModelling.toDTO(notification)
			);
		},
	};


	selectors = {
		placeholderDeleteMe() {
			//
		},
	};
}