/*
	~ School Event Reducer Actions
*/

import { iSchoolEventStoreCollectionDTO, iSchoolEventStoreDTO } from '@domain/Interfaces/AppState/schoolEvent/dto';

import {
	SchoolEventActionTypes,
	iIsFetchingSchoolEventsAction,
	iSchoolEventAction,
	iUpateAllSchoolEventsAction,
	iUpateSchoolEventAction, 

} from './types';


/* ACTION CREATORS */

export function isFetchingSchoolEvents(isFetching = true): iIsFetchingSchoolEventsAction {
	return {
		isFetching,
		type: SchoolEventActionTypes.FETCHING_SCHOOL_EVENTS,
	};
}

export function updateSchoolEvent(schoolEventDTO: iSchoolEventStoreDTO): iUpateSchoolEventAction {
	return {
		event: schoolEventDTO,
		type: SchoolEventActionTypes.UPDATE_SCHOOL_EVENT,
	};
}

export function updateAllSchoolEvents(
	schoolEventsDTOCollection: iSchoolEventStoreCollectionDTO
): iUpateAllSchoolEventsAction {
	return {
		events: schoolEventsDTOCollection,
		type: SchoolEventActionTypes.UPDATE_ALL_SCHOOL_EVENTS,
	};
}

export function resetAll(): iSchoolEventAction {
	return {
		type: SchoolEventActionTypes.RESET_ALL 
	};
}