/**
 *
 *  ~ Notification Message Enums
 *
 */


/**
 * Possible Message Types For User Notifications
 */
export enum NotificationTypes {
	INFO = 'INFO_NOTIFICATION_TYPE',
	ERROR = 'ERROR_NOTIFICATION_TYPE',
	WARNING = 'WARNING_NOTIFICATION_TYPE',
	SUCCESS = 'SUCCESS_NOTIFICATION_TYPE',
}