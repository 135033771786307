/**
 *
 *  ~ Model-level Validation, Decorator Mixin - Date & Time
 *
 */

import ModelError from '@data/Entities/errors/model';

import { MODEL_ERRORS } from '@data/Shared/Enums/errors/model';
import { isDateStringValid } from '@data/Shared/Validation/dateTime';


/**
 * Guards Any Class Accessor From Date & Time Strings That Cannot
 * Be Used For Dates or Times
 * @returns Accessor decorator handler
 */
export const guardDateTimeFromPoorlyFormedStrings = () => 
	(target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

		descriptor.get = function() {
			return this[`_${propertyKey}` as keyof PropertyDescriptor];
		};

		descriptor.set = function(newDateString: string) {
			if (isDateStringValid(newDateString)) {
				this[`_${propertyKey}` as keyof PropertyDescriptor] = newDateString;

			} else {
				throw new ModelError({
					type: MODEL_ERRORS.poorlyFormedDateString,
					message: `
                    Date string was not formatted correctly for ${propertyKey} 
                    property to be considered a Date and/or Time string
                `,
				});
			}
		};
	};
