/**
 * 
 * 	~ School Adapter for Remote Store
 * 
 */

import { iSchool } from '@data/Entities/Schools/school';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { iRemoteSchoolsDTO } from '@domain/Interfaces/remoteDTOs/school';
import { RemoteSchoolCollectionModelling, RemoteSchoolCollectionStructure } from '@domain/Modelling/school';


export interface iSchoolRemoteStoreAdapter {
	subscribeTo(id: string, callback: (schools: iSchool[] | null) => object): Promise<() => void>
}


export default class SchoolRemoteStoreAdapter implements iSchoolRemoteStoreAdapter {

	constructor(
		private _RemoteStoreProvider: iRemoteStore,
	) {}
	
	async subscribeTo(
		id: string, 
		callback: (schools: iSchool[] | null) => object
	): Promise<() => void> {
		return this._RemoteStoreProvider.subscribeTo<iRemoteSchoolsDTO>(
			id, 
			(schoolsDTOResponse: iRemoteSchoolsDTO | null) => {
				if (schoolsDTOResponse) {
					return callback(
						RemoteSchoolCollectionModelling.fromCollection(
							RemoteSchoolCollectionStructure.toCollection(schoolsDTOResponse)
						)
					);
				
				} else {
					return {};
				}
			}
		);
	}

}