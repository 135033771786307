/**
 * 
 * 	~ Pipeline State Adapters
 * 
 */

import { iPipeline } from '@data/Entities/Piipeline/pipeline';
import { iPipelineStoreProvider } from '@domain/Interfaces/AppState/pipeline/store';
import { PipelineConfigurationModelling } from '@domain/Modelling/pipeline';


export interface iPipelineStoreAdapter {
	actions: {
		resetAll(): void,
		updatePipeline(PipelineDTO: iPipeline): void,
		isFetchingPipelines(isFetching?: boolean): void,
	},
	selectors: {
		//
	},
}


export default class PipelineStoreAdapter implements iPipelineStoreAdapter {
	constructor(
		private _PipelineStore: iPipelineStoreProvider,
	) {}

	actions = {
		...this._PipelineStore.actions,
		updatePipeline: (pipelineUpdate: iPipeline) => {
			this._PipelineStore.actions.updatePipeline(
				PipelineConfigurationModelling.toDTO(pipelineUpdate)
			)
		},
	};

	selectors = {
		placeholderDeleteMe() {
			//
		},
	};
}