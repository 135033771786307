/*
	~ User Menu Button Component
*/

import { CSSObject } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { LoginMenuOptionType, LoginMenuOptions } from '@interfaces/navbar';

import {
	Logout,
	Settings,
	PersonAdd,
	AccountCircle,

} from '@mui/icons-material';

import {
	useState,
	MouseEvent,
	MouseEventHandler,
	ReactNode,

} from 'react';

import {
	Avatar,
	Tooltip,
	IconButton,
	ButtonProps,
	AvatarTypeMap,
	Menu,
	MenuItem,
	Divider,
	ListItemIcon,

} from '@mui/material';


export const PROFILE_MENU_ICON_SQUARE = 60;


const Styles = (theme: Theme) => ({
	avatar: {
		color: 'inherit',
		backgroundColor: 'transparent',
	    width: PROFILE_MENU_ICON_SQUARE,
	    height: PROFILE_MENU_ICON_SQUARE,
	} as CSSObject,
	avatarIcon: {
		width: '70%',
		height: '70%',
		fontSize: '70%',
	} as CSSObject,
	menuButton: {
		padding: 2,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iUserMenuButtonProps extends getClassesType<stylesType> {
	label?: string,
	imageUrl?: string,
	avatarProps?: AvatarTypeMap,
	menuItems?: LoginMenuOptions[],
	buttonProps?: Partial<ButtonProps>,
	onClick?: MouseEventHandler<HTMLButtonElement>,
}

const UserSettingsMenuButton = (props: iUserMenuButtonProps) => {
	const {
		label,
		imageUrl,
		menuItems = [],
		buttonProps = {},
		avatarProps = {},
		onClick = () => {/** */},

	} = props;

	const [ isOpen, setIsOpen ] = useState(false);
	const classes = withStyles.getClasses<stylesType>(props);
	const [ menuAnchorEl, setMenuAnchorEl ] = useState<null | HTMLElement>(null);


	const handleMenuButtonOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
		setIsOpen(true);
		onClick(event);
	};

	const handleOptionOnClick = (callback: (() => void) | undefined) => () => {
		if (callback) {
			callback();
		}
	};
	
	const handleClose = () => {
		setMenuAnchorEl(null);
		setIsOpen(false);
	};

	const renderMenuItems = () => {
		const items: ReactNode[] = [];

		if (menuItems.length > 0) {
			for (const [menuIndex, menuItem] of menuItems.entries()) {
				const {
					type,
					label,
					options,
					callback,

				} = menuItem;

				switch (type) {
					case LoginMenuOptionType.divider:
						items.push( <Divider key={`menu_item_${menuIndex}`} /> );
						break;
						
					case LoginMenuOptionType.option:
						if (label) {
							items.push(
								<MenuItem 
									key={`menu_item_${menuIndex}`}
									onClick={handleOptionOnClick(callback)}>

									{ label }
								</MenuItem>
							);
						}
						break;
						
					case LoginMenuOptionType.iconOption:
						if (label && options && Object.hasOwn(options, 'icon')) {
							items.push(
								<MenuItem 
									key={`menu_item_${menuIndex}`}
									onClick={handleOptionOnClick(callback)}>

									<ListItemIcon>
										{ options.icon }
									</ListItemIcon>

									{ label }
								</MenuItem>
							);
						}
						break;
				
					default:
						break;
				}
			}

			return items;

		} else {
			return null;
		}
	};

	const renderMenu = () => {
		return (
			<Menu
				open={isOpen}
				id={'account-menu'}
				onClose={handleClose}
				onClick={handleClose}
				anchorEl={menuAnchorEl}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{
					horizontal: 'right', vertical: 'top' 
				}}
				anchorOrigin={{
					horizontal: 'right', vertical: 'bottom' 
				}}>

				{ renderMenuItems() }
			</Menu>
		);
	};

	const renderAvatar = () => {
		if(imageUrl) {
			return (
				<Avatar
					alt={'image'}
					src={imageUrl}
					variant={'circular'}
					classes={{
						root: classes.avatar 
					}}
					{ ...avatarProps } />
			);
	
		} else if(label) {
			return (
				<Avatar
					variant={'circular'}
					classes={{
						root: classes.avatar 
					}}
					{ ...avatarProps }>
	
					{ label }
				</Avatar>
	
			);
		
		} else {
			return (
				<Avatar
					variant={'circular'}
					classes={{
						root: classes.avatar 
					}}
					{ ...avatarProps }>
	
					<AccountCircle classes={{
						root: classes.avatarIcon 
					}} />
				</Avatar>
			);
		}
	}

	return (
		<>
			<Tooltip title='Account options'>
				<IconButton
					size={'medium'}
					aria-haspopup={'true'}
					onClick={handleMenuButtonOnClick}
					aria-expanded={isOpen ? 'true' : undefined}
					aria-controls={isOpen ? 'account-menu' : undefined}
					classes={{
						root: classes.menuButton
					}}
					{ ...buttonProps }>

					{ renderAvatar() }
				</IconButton>
			</Tooltip>

			{ renderMenu() }
		</>
	)
};


export default withStyles(UserSettingsMenuButton, Styles);