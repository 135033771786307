/**
 * 
 * 	~ Use Previous State Hook
 * 
*/

import { useEffect, useRef } from 'react';


function usePrevious<PreviousValue>(value: PreviousValue): PreviousValue {
	const ref = useRef<PreviousValue>();

	useEffect(() => {
		ref.current = value;

	},[value]);

	if (ref.current !== undefined) {
		return ref.current;
	
	} else {
		return {} as PreviousValue;
	}
}

export default usePrevious;