/*
	~ App View
*/


import includeClasses from 'classnames';
import NavigatorView from '@views/Navigator';
import usePrevious from '@hooks/previousState';
import Theme from '@atoms/_materialUIThemeProvider';
import NotificationCenter from '@cells/NotificationCenter';

import Styles, { styleType } from './styles';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';
import { initialize } from '@ducts/Controllers/app';
import { getClassesType } from '@interfaces/tssReact';
import { getApplicationStatus } from '@ducts/DuctSelectors/app';


interface iAppViewProps extends getClassesType<styleType> {}


/**
 * Application Base/Root Component
 * @param props 
 * @returns 
 */
const AppView = (props: iAppViewProps) => {
	let initializationSafetyCounter = 1;

	const {
		isHydrated,
		isInitialized,
		isInitializing,

	} = useSelector(getApplicationStatus);

	const classes = withStyles.getClasses<styleType>(props);
	const prevInitializedState = usePrevious(isInitialized);
	
	useEffect(() => {
		// Run initialization only after hydration of stores
		if (isHydrated && isInitialized === false && isInitializing === false && initializationSafetyCounter <= 1) {
			initializationSafetyCounter++;
			initialize();
		}

		// If application is no longer initialized, but it was previously... reset 
		if (prevInitializedState !== isInitialized && prevInitializedState === true && isInitialized === false) {
			initializationSafetyCounter = 1;
		}

	}, [isHydrated, isInitialized]);

	return (
		<Theme>
			<div className={
				includeClasses({
					[classes.container]: true,
					[classes.isLoading]: !isInitialized,
				})
			}>
				{ 
					isInitialized ? ( <NavigatorView /> ) : ( <CircularProgress size={100} /> ) 
				}
			</div>

			<NotificationCenter />
		</Theme>
	);
};

export default withStyles(AppView, Styles);