/**
 *
 *  ~ Registration Errors
 *
 */


export enum REGISTRATION_ERRORS {
    weakPassword = 'WEAK_PASSWORD',
    emailIsRequired = 'EMAIL_REQUIRED',
    invalidEmail = 'INVALID_EMAIL_FORMAT',
    passwordIsRequired = 'PASSWORD_REQUIRED',
    emailAlreadyInUse = 'EMAIL_ALREADY_IN_USE',
    invalidPassword = 'INVALID_PASSWORD_FORMAT',
    operationNotAllowed = 'OPERATION_NOT_ALLOWED',
    profileImageIsRequired = 'PROFILE_IMAGE_REQUIRED',
    couldNotCreateUser = 'COULD_NOT_REGISTER_A_NEW_USER',
}


export interface iRegistrationMessage {
    [errorType: string]: string,
}

export const REGISTRATION_MESSAGES: iRegistrationMessage = {
	[REGISTRATION_ERRORS.invalidPassword]: 'Wrong password format',
	[REGISTRATION_ERRORS.operationNotAllowed]: 'This operation is not allowed!',
	[REGISTRATION_ERRORS.emailIsRequired]: 'Email is required in order to register a new user',
	[REGISTRATION_ERRORS.weakPassword]: `This password is super weak... like... it's just awful`,
	[REGISTRATION_ERRORS.emailAlreadyInUse]: 'Cannot create new user, this email is already in use',
	[REGISTRATION_ERRORS.invalidEmail]: 'Can only accept valid email formats, e.g. example@test.com',
	[REGISTRATION_ERRORS.passwordIsRequired]: 'Password is required in order to register a new user',
	[REGISTRATION_ERRORS.profileImageIsRequired]: 'Profile Image is required in order to register a new user',
	[REGISTRATION_ERRORS.couldNotCreateUser]: 'Could not create a new user at this time, please try again later!',
};