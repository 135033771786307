/*
	~ Search Filter Drawer Menu Component
*/

import includeClasses from 'classnames';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { DrawerAnchors, DrawerVariants } from '@assets/styles/muiTypes/drawer';

import {
	SearchFilter,
	SearchFilterGranular,
	SpecialSearchFilters,

} from '@interfaces/filters';

import {
	ExpandLess,
	ExpandMore,
	ChevronRightSharp,

} from '@mui/icons-material';

import {
	useState,
	MouseEvent,
	ReactElement,
	useEffect,

} from 'react';

import {
	List,
	Drawer,
	Toolbar,
	Divider,
	Collapse,
	ListItem,
	IconButton,
	ListItemText,
	ListItemButton,

} from '@mui/material';


export const DRAWER_WIDTH = 350;

const Styles = (theme: Theme) => ({
	drawer: {
		width: DRAWER_WIDTH,
		position: 'absolute',
	} as CSSObject,
	staticDrawer: {
		position: 'fixed',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iSearchFilterDrawerProps<FilterTypes> extends getClassesType<stylesType> {
	isOpen?: boolean,
	renderListItem?: () => ReactElement,
	filtrationOptions: SearchFilterGranular<FilterTypes>[],
	onDrawerClose?: (event: MouseEvent<HTMLButtonElement>) => void,
	onSubListItemClick?: (filter: SearchFilter<FilterTypes>) => void,
	onListItemClick?: (filter: FilterTypes | SpecialSearchFilters) => void,
	renderSubListItem?: (parentFilter: SearchFilterGranular<FilterTypes>) => ReactElement | ReactElement[] | null,
}

const SearchFilterDrawer = <FilterTypes,>(props: iSearchFilterDrawerProps<FilterTypes>) => {
	const classes = withStyles.getClasses<stylesType>(props);
	const [ isFixed, setIsFixed ] = useState(false);
	const [isListItemOpen, setIsListItemOpen] = useState({} as { [id: string]: boolean });
	let lastKnownScrollPosition = 0;
	let isTicking = false;

	const {
		isOpen = false,
		filtrationOptions,
		renderListItem = null,
		renderSubListItem = null,
		onDrawerClose = () => { /** */ },
		onListItemClick = () => { /** */ },
		onSubListItemClick = () => { /** */ },
	} = props;


	useEffect(() => {
		window.addEventListener('scroll', handlePageOnScroll);

		return () => {
			window.removeEventListener('scroll', handlePageOnScroll);
		};

	}, []);

	const handleListItemOnClick = (filter: FilterTypes | SpecialSearchFilters, index: number) => () => {
		const isOpen = isSubMenuOpen(index);

		setIsListItemOpen({
			...isListItemOpen,
			[index]: !isOpen,
		})

		onListItemClick(filter);
	};

	const handleSubListItemOnClick = (filter: SearchFilter<FilterTypes>) => () => {
		onSubListItemClick(filter);
	};

	const isSubMenuOpen = (index: number) => {
		let isOpen = (Object.hasOwn(isListItemOpen, index) && isListItemOpen[index] === true);

		if (isOpen === undefined) {
			isOpen = false;
		}

		return isOpen;
	};

	const handlePageOnScroll = () => {
		lastKnownScrollPosition = window.scrollY;

		if (!isTicking) {
			window.requestAnimationFrame(() => {
				if (lastKnownScrollPosition >= window.outerHeight) {
					setIsFixed(true);
				} else {
					setIsFixed(false);
				}

				isTicking = false;
			});

			isTicking = true;
		}
	};
	
	const renderSubList = (isOpen: boolean, items: ReactElement[]) => {
		return (
			<Collapse
				in={isOpen}
				timeout="auto"
				unmountOnExit
				sx={{
					width: '100%'
				}}>
				<List component="ul" disablePadding>
					{ items }
				</List>
			</Collapse>
		);
	};

	const renderSubListItems = (
		parent: SearchFilterGranular<FilterTypes>, 
		subItems?: SearchFilter<FilterTypes>[], 
		isOpen = false
	) => {
		let items: ReactElement[] = [];

		if (subItems && subItems?.length > 0) {
			for (const [itemIndex, item] of subItems.entries()) {
				const { label } = item;

				items.push(
					<ListItem key={`sub-list-item-${itemIndex}`}>
						<ListItemButton
							onClick={handleSubListItemOnClick(item)}
							sx={{
								pl: 4,
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}>

							<ListItemText primary={label} />
						</ListItemButton>
					</ListItem>
				);
			}

		} else if (renderSubListItem !== null) {
			const newListItem = renderSubListItem(parent) as ReactElement[];

			if (newListItem) {
				items = newListItem;
			}
		}

		return renderSubList(isOpen, items);
	};
	
	const listItem = (label: string) => {
		if (renderListItem) {
			return renderListItem();
		} else {
			return (
				<ListItemText primary={label} />
			);
		}
	};

	const renderArrowIcon = (subItems: SearchFilter<FilterTypes>[], isOpen: boolean) => {
		if (subItems) {
			if (isOpen) {
				return (
					<ExpandMore />
				);

			} else {
				return (
					<ExpandLess />
				);
			}
		
		} else {
			return null;
		}
	};

	const renderListItems = (option: SearchFilterGranular<FilterTypes>, optionIndex: number) => {
		const {
			label, 
			filter,
			subItems,

		} = option;

		const isMenuOpen = isSubMenuOpen(optionIndex);

		return (
			<ListItem 
				key={`filter_option_${optionIndex}`} 
				sx={{
					flexDirection: 'column'
				}}>

				<ListItemButton
					onClick={handleListItemOnClick(filter, optionIndex)}
					sx={{
						width: '100%',
					}}>

					{ listItem(label) }
					{ subItems && renderArrowIcon(subItems, isMenuOpen) }
				</ListItemButton>

				{ renderSubListItems(option, subItems, isMenuOpen) }
			</ListItem>

		);
	};

	return (
		<Drawer
			open={isOpen}
			anchor={DrawerAnchors.right}
			variant={DrawerVariants.persistent}
			classes={{
				paper: includeClasses({
					[classes.drawer]: true,
					[classes.staticDrawer]: isFixed,
				}),
			}}>

			<Toolbar>
				<IconButton 
					onClick={(event: MouseEvent<HTMLButtonElement>) => {
						onDrawerClose(event)
					}}>

					<ChevronRightSharp />
				</IconButton>
			</Toolbar>

			<Divider />

			<List>
				{
					filtrationOptions.map(renderListItems)
				}
			</List>
		</Drawer>
	);
};


export default withStyles(SearchFilterDrawer, Styles);