/**
 * 
 * 	React / Redux Provider Bridge
 * 
 */

import { hasChildren } from '@interfaces/React';
import { store, ReactReduxProvider } from '../core';

/**
 * Wrap child components in Store giving access to application 
 * state in redux
 * 
 * @param props 
 * @returns 
 */
const ReduxStoreProvider = (props: hasChildren) => (
	<ReactReduxProvider store={store}>
		{ props.children }
	</ReactReduxProvider>
);

ReduxStoreProvider.defaultProps = {
	children: null 
};

export default ReduxStoreProvider;
//------

