/*
	~ Password Reset Styles
*/

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';



const Styles = (theme: Theme) => ({
	container: {
	    width: '100%',
		display: 'flex',
		minHeight: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: theme.palette.primary.contrastText,
	} as CSSObject,

	centeringContainer: {
		flex: 1,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,

	centralizedContent: {
		width: '35%',
		minWidth: 350,
		minHeight: 300,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,

	titleContainer: {
		width: '100%',
		paddingTop: 35,
		marginBottom: 25,
		paddingBottom: 35,

		background: theme.palette.primary.dark,
		
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderColor: 'lightgrey',
	} as CSSObject,
	titleText: {
		textTransform: 'uppercase',
		margin: '0px 35px 0px 35px',
		color: theme.palette.primary.contrastText,
	} as CSSObject,

	spinner: {
		color: theme.palette.primary.contrastText,
	} as CSSObject,
	
	passwordFormContainer: {
		width: '90%',
		paddingTop: 20,
		paddingBottom: 20,
	} as CSSObject,

	userInformationalText: {
		marginTop: 20,
		marginBottom: 40,
		fontWeight: 400,
	} as CSSObject,
	userWarningText: {
		color: theme.palette.error.main,
	} as CSSObject,

	passwordFormFooter: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export default Styles;