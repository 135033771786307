/**
 * 
 *  ~ Outgoing Accessible Actions for the Application to consume
 * 
 */

import * as appActions from './App';
import * as userActions from './User';
import * as schoolActions from './School';
import * as pipelineActions from './Pipeline';
import * as schoolEventActions from './SchoolEvent';
import * as notificationCenterActions from './NotificationCenter';

import ConfigureStore from '../configureStore';

import { bindActionCreators } from 'redux';

/**
 * 
 * 			THESE SHOULD ALL BE MOVED TO DUCT/PROVIDER LEVEL SO THEY OBEY THE CONTRACT OF WHAT THEY PLUG INTO
 * 
 */

/**
 * These interfaces come from the domain layer as a contract and are the domain's 
 * way of saying "if you want to be this application's source of app state 
 * management, then you need to give it these actions"
 */
import { iAppActions } from '@domain/Interfaces/AppState/app/store';
import { iUserActions } from '@domain/Interfaces/AppState/user/store';
import { iSchoolActions } from '@domain/Interfaces/AppState/school/store';
import { iPipelineActions } from '@domain/Interfaces/AppState/pipeline/store';
import { iSchoolEventActions } from '@domain/Interfaces/AppState/schoolEvent/store';
import { iNotificationActions } from '@domain/Interfaces/AppState/notification/store';
// -------------------------------------------------


const { store } = ConfigureStore;
const { dispatch } = store;


export const ApplicationActions: iAppActions = {
	...bindActionCreators<iAppActions, any>(appActions, dispatch) 
};

export const PipelineActions: iPipelineActions = {
	...bindActionCreators<iPipelineActions, any>(pipelineActions, dispatch) 
};

export const UserActions: iUserActions = {
	...bindActionCreators<iUserActions, any>(userActions, dispatch)
};

export const SchoolActions: iSchoolActions = {
	...bindActionCreators<iSchoolActions, any>(schoolActions, dispatch)
};

export const SchoolEventActions: iSchoolEventActions = {
	...bindActionCreators<iSchoolEventActions, any>(schoolEventActions, dispatch)
};

export const NotificationCenterActions: iNotificationActions = {
	...bindActionCreators<iNotificationActions, any>(notificationCenterActions, dispatch)
};