/**
 * 
 *  ~ User Use Case Functions
 * 
 */

import { log } from '@logging/Console';
import { iFeatureAccess } from '@data/Entities/User/feature';
import { iUserStoreAdapter } from '@ducts/Adapters/ApplicationState/user';
import { iUserRemoteStoreAdapter } from '@ducts/Adapters/RemoteStore/user';
import { iUserInteractor } from '@domain/Interfaces/Interactor/iUserInteractor';


export default class UserInteractor implements iUserInteractor {

	private userUpdatesListener: Promise<() => void> | null = null;

	constructor(
        private _RemoteStoreProvider: iUserRemoteStoreAdapter,
		private _UserStore: iUserStoreAdapter,
	) {}


	/**
     * Subscribed to remote store updates and passes a handler to deal with each 
     * update event
     */
	async listenForUserAccessUpdates(): Promise<void> {
    	try {
			const { isAuthenticated } = this._UserStore.selectors.getAuth();
			
			if (isAuthenticated) {
				const { id } = this._UserStore.selectors.getUser();

				this.userUpdatesListener = this._RemoteStoreProvider.subscribeTo(
					id,
					this.handleUserAccessUpdate
				);
			}

    	} catch (error) {
			this._UserStore.actions.isFetchingUser(false);
    		log('error', 'Something went wrong with user listener, error given: ', error);
    	}
	}


	/**
	 * Handles user access updates from remote store
	 * @param featureAccesses 
	 */
	private handleUserAccessUpdate = async (featureAccesses: iFeatureAccess[] | null): Promise<void> => {
		try {
			if (featureAccesses === null) {
				this._UserStore.actions.resetFeatureAccess();

			} else {
				this._UserStore.actions.isFetchingUser(true);

				this._UserStore.actions.updateUser({
					features: featureAccesses,
				});

				this._UserStore.actions.isFetchingUser(false);
			}
			
		} catch (error) {
			console.log(`error: ???? `, error);
		}
	};

}