/*
	STORE STRUCTURE
	===================
	
	├─ App
	├─ User
	├─ Events
	├─ Schools
	├─ Pipeline
	└─ NotificationCenter
*/


import app from './App';
import user from './User';
import schools from './School';
import pipeline from './Pipeline';
import schoolEvents from './SchoolEvents';
import notificationCenter from './NotificationCenter';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { userPersistConfig } from './localStorageKeys';
import { PersistPartial } from 'redux-persist/lib/persistReducer';

import { iAppState } from '@domain/Interfaces/AppState/app/store';
import { iUserState } from '@domain/Interfaces/AppState/user/store';
import { iSchoolState } from '@domain/Interfaces/AppState/school/store';
import { iPipelineState } from '@domain/Interfaces/AppState/pipeline/store';
import { iSchoolEventState } from '@domain/Interfaces/AppState/schoolEvent/store';
import { iNotificationState } from '@domain/Interfaces/AppState/notification/store';

/**
 * State Requirements for entire Store
 */
export type RootStoreState = {
	app: iAppState,
	schools: iSchoolState,
	pipeline: iPipelineState,
	events: iSchoolEventState,
	user: iUserState & PersistPartial,
	notificationCenter: iNotificationState,
};


export default combineReducers<RootStoreState>({
	app,
	schools,
	pipeline,
	notificationCenter,
	events: schoolEvents,
	user: persistReducer(userPersistConfig, user),
});