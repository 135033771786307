/**
 * 
 *  ~ User Application State Selectors
 * 
 */

import { store } from '../configureStore';
import { RootStoreState } from '../State';
import { iUserStoreDTO } from '@domain/Interfaces/AppState/user/dto';

const { getState } = store;


/**
 * Gets the current Authenticated User Id from 
 * central Application State store
 * @returns 
 */
export function getAuthenticatedUserId(): string | null {
	const { user } = getState();
	let userId = null;

	if (user?.id) {
		userId = user.id;
	}

	return userId;
	
}


/**
 * Get a User from Stored State
 * @param param0 
 * @returns 
 */
export const selectUser = ({ user }: RootStoreState): iUserStoreDTO => user;


/**
 * Get a User from Stored State and models response
 * 
 * @param modellingCallback - This callback is used on the user state model before it 
 * returns from the selector
 * @returns
 */
export const selectUserWithModelling = <RequestedUserModel>(
	modellingCallback: (user: iUserStoreDTO) => RequestedUserModel
) => ({ user }: RootStoreState): RequestedUserModel => modellingCallback(user);