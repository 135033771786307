/**
 * 
 *   ~ Selector Controllers - Notification Center
 * 
 */

import { iNotificationState } from '@domain/Interfaces/AppState/notification/store';
import { selectNotifications } from '@ducts/Providers/ApplicationState/StateSelectors/notification';

import {
	mapMessageTypes,
	iNotificationDuct,
	ViewNotificationTypes,

} from '@interfaces/notifications';


/**
 * 
 * @returns 
 */
export const getNotificationsDuct = selectNotifications(
	(notifications: iNotificationState): iNotificationDuct[] => {
		const notificationDucts: iNotificationDuct[] = [];

		if (notifications) {
			const { messages } = notifications;

			if (messages?.length > 0) {
				for (const messageObj of messages) {
					const {
						message, messageType 
					} = messageObj;

					notificationDucts.push({
						message,
						messageType: mapMessageTypes(messageType as string as keyof typeof ViewNotificationTypes),
					});
				}
			}
		}

		return notificationDucts;
	}
);