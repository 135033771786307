/**
 * 
 *  ~ Authentication Controller Functions
 *      Top-level Access to business logic
 * 
 */


import { log } from '@logging/Console';
import { iUserDuct } from '@ducts/Interfaces/user';
import { createAuthInteractor } from '@ducts/Factories/auth';
import { userDTOHasRequiredFields } from '@domain/Validation/user';
import { RemoteAuthModelling } from '@domain/Modelling/authentication';


/**
 * Creates a new remote user by passing a User DTO Object
 * @param authService 
 * @param userDuct 
 */
export async function createUser(userDuct: iUserDuct): Promise<void> {
	try {
		if (userDuct) {
			// Validate
			userDTOHasRequiredFields(userDuct);
			// ----
			const authInteractor = createAuthInteractor();

			await authInteractor.createUser(
				RemoteAuthModelling.toModel({
					email: userDuct.email,
					password: userDuct.password,
				})
			);
        
		} else {
			// @todo: make this into a proper error Class
			throw new Error(
				`Missing either userDTO in createRemoteUser function. Given: ${userDuct}`
			);
		}

	} catch (error) {
		log('warning', 'error in controller!!: ', error);
	}
}


/**
 * 
 * @param email 
 * @param password 
 */
export async function login(email: string, password: string) {
	try {
		const authInteractor = createAuthInteractor();

		await authInteractor.authenticateUser(
			RemoteAuthModelling.toModel({
				email,
				password,
			}),
		);
        
	} catch (error) {
		log('warning', 'error in login controller function: ', error);
	}
}


/**
 * 
*/
export async function logout() {
	try {
		const authInteractor = createAuthInteractor();
		authInteractor.unauthenticateUser();
        
	} catch (error) {
		log('warning', 'could not logout! Error: ', error);
	}
}


/**
 * Reset a users password by email
 * @param email 
 */
export async function resetPassword(email: string) {
	try {
		const authInteractor = createAuthInteractor();
		authInteractor.resetPassword(email);
		
	} catch (error) {
		log('warning', 'could not logout! Error: ', error);
	}
}