/**
 *
 *  ~ Model-level Validation, Decorator Mixin - First and Last Name
 *
 */

import ModelError from '@data/Entities/errors/model';

import { MODEL_ERRORS } from '@data/Shared/Enums/errors/model';

/**
 * This should probably be moved to it's own file/space
 */
export const MAX_NAME_LENGTH = 250;

/**
 * Guards Any Class Accessor From A String That Is Longer Than 250 chars
 * @param target
 * @param propertyKey
 * @param descriptor
 */
export const guardStringFromMaxLength = (
	maxLength: number = MAX_NAME_LENGTH

) => (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	descriptor.get = function() {
		return this[`_${propertyKey}` as keyof PropertyDescriptor];
	};

	descriptor.set = function(newString: string) {
		if (newString.length <= maxLength) {
			this[`_${propertyKey}` as keyof PropertyDescriptor] = newString;

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.messageMaxLength,
				message: `${propertyKey}: Property can only be ${maxLength} chars or less`,
			});
		}
	};
};
