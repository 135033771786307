/**
 * 
 *  ~ State Selectors - Pipeline
 * 
 */

import { RootStoreState } from '../State';
import { iPipelineStoreDTO } from '@domain/Interfaces/AppState/pipeline/dto';


/**
 * Get state pipeline data
 * @param param0 
 * @returns 
 */
export const selectPipelineData = <RequestedPipelineModel>(
	modellingCallback: (pipeline: iPipelineStoreDTO) => RequestedPipelineModel
) => ({ pipeline }: RootStoreState): RequestedPipelineModel => modellingCallback({
		lastRun: pipeline.lastRun || undefined,
	});