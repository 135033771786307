/**
 * 
 *  ~ User Entity Data Domain
 * 
 */

import BaseEntity, { iBaseEntity } from '../BaseEntity';

import { iFeatureAccess } from './feature';
import { guardEmailFromInvalidFormat } from '@data/Shared/Validation/model/web';


export interface iUser extends Pick<iBaseEntity, 'id'> {
    email: string,
    isAuthenticated?: boolean,
	features: iFeatureAccess[],
}


/**
 * User Entity
 */
export default class User extends BaseEntity implements iUser {
	private _email = '';
	private _isAuthenticated = false;
	private _features: iFeatureAccess[] = [];

	constructor({
		id,
		email,
		features,
		isAuthenticated,
	}: {
        id: string,
        email?: string,
        password?: string,
		isFetching?: boolean,
		isAuthenticated?: boolean,
		features?: iFeatureAccess[],
        
    }) {
		super(id);

		if (email) this.email = email;
		if (features) this.features = Array.from(features);
		if (isAuthenticated) this.isAuthenticated = isAuthenticated;
	}

    @guardEmailFromInvalidFormat()
	set email(newEmail: string) {
		this._email = newEmail;
	}
    
    get email(): string {
    	return this._email;
    }

    get features(): iFeatureAccess[] {
    	return this._features;
    }

    set features(newFeatures: iFeatureAccess[]) {
    	this._features = newFeatures;
    }

    get isAuthenticated(): boolean {
    	return this._isAuthenticated;
    }

    set isAuthenticated(newIsAuthenticated: boolean) {
    	this._isAuthenticated = newIsAuthenticated;
    }

    
}