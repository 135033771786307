/*
	~ School City Selector Component
*/

import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { getClassesType } from '@interfaces/tssReact';
import { iSchoolDuct } from '@ducts/Interfaces/school';
import { transformStateSchoolsToSchoolUIs } from '@modelling/school';
import { getAllSchoolsDuct } from '@ducts/DuctSelectors/school';

import {
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormHelperText,
	SelectChangeEvent,
	SelectProps,

} from '@mui/material';


const Styles = (/* theme: Theme */) => ({
	menuItem: {} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iSchoolCitySelector extends Omit<
	SelectProps, 
	'classes' | 'className' | 'onChange'
>, getClassesType<stylesType> {
	helperText?: string,
	onChange: (city: string) => void,
}


const SchoolCitySelector = (props: iSchoolCitySelector) => {
	const classes = withStyles.getClasses<stylesType>(props);

	const {
		id,
		value,
		onChange,
		helperText,
		label = 'Select a City',
		// This is here for destructuring only
		// eslint-disable-next-line
		classes: noName,
		...otherProps

	} = props;
	
	const stateSchools: iSchoolDuct[] = useSelector(getAllSchoolsDuct);
	const schools = transformStateSchoolsToSchoolUIs(stateSchools);
	const cities = schools.map(school => school.city);

	const handleOnChange = (event: SelectChangeEvent<any>) => {
		const city = event?.target?.value;

		if (city) {
			onChange(city);
		}
	};

	const renderMenuItems = () => {
		const items: ReactElement[] = [];
		for (const [cityIndex, city] of cities.entries()) {
			items.push(
				<MenuItem 
					value={city}
					className={classes.menuItem}
					key={`${cityIndex}-city-menu-item`}>

					{ city }
				</MenuItem>
			);
		}

		return items;
	};

	return (
		<FormControl fullWidth>
			<InputLabel id={`${id}-label`}>
				{ label }
			</InputLabel>

			<Select
				{ ...otherProps }
				id={id}
				label={label}
				value={value}
				labelId={`${id}-label`}
				onChange={handleOnChange}
			>
				<MenuItem value={''}>
					<em>None</em>
				</MenuItem>

				{ renderMenuItems() }
			</Select>

			{
				helperText && (
					<FormHelperText>
						{ helperText }
					</FormHelperText>
				)
			}
		</FormControl>
	);
};


export default withStyles(SchoolCitySelector, Styles);
