/**
 * 
 * 	~ Single School Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {
		width: '100vw',
		display: 'flex',
		overflowX: 'hidden',
		flexDirection: 'column',
	} as CSSObject,
	contentBody: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,
	centered: {
		width: '85%',
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '10vh',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	} as CSSObject,
	schoolImage: {
		width: '100%',
		height: '100%',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
	} as CSSObject,
	titleContainer: {
		marginTop: 45,

	} as CSSObject,
	detail: {
		marginBottom: 10,
	} as CSSObject,
	title: {
		width: '100%',
		marginRight: 35,
		color: theme.palette.primary.dark,
	
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			textAlign: 'center',
		},

	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;