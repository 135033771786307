/**
 * 
 * 	~ User Adapters for Remote Store
 * 
 */

import { iFeatureAccess } from '@data/Entities/User/feature';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { ManyFeatureAccessModelling } from '@domain/Modelling/user';
import { iFeatureAccessResponseDTO } from '@domain/Interfaces/remoteDTOs/uac';


export interface iUserRemoteStoreAdapter {
	subscribeTo(id: string, callback: (model: iFeatureAccess[] | null) => object): Promise<() => void>
}


export default class UserRemoteStoreAdapter implements iUserRemoteStoreAdapter {

	constructor(
		private _RemoteStoreProvider: iRemoteStore,
	) {}
	
	async subscribeTo(id: string, callback: (model: iFeatureAccess[] | null) => object): Promise<() => void> {
		return this._RemoteStoreProvider.subscribeTo<iFeatureAccessResponseDTO>(
			id, 
			(featureAccessDTOResponse: iFeatureAccessResponseDTO | null) => {
				if (featureAccessDTOResponse) {
					const { featureAccessors } = featureAccessDTOResponse;
					
					return callback(
						ManyFeatureAccessModelling.toManyModels(featureAccessors)
					);
				
				} else {
					return {};
				}
			}
		);
	}

}