/*
	~ Navigator View
*/

// import { withStyles } from 'tss-react/mui';
// import { ROUTES, getPathByRoute } from 'settings/routes';

// import Styles from './styles';



import PageNotFoundView from '@views/PageNotFound';
import ProtectedRoute from '@atoms/_protectedRoute';
import AuthenticatedRoute from '@atoms/_authenticatedRoute';

import { ReactNode } from 'react';
import { AllRoutes, iRouteGroup } from '@settings/routes';

import {
	Route,
	Routes,
	BrowserRouter as Router,

} from 'react-router-dom';


const NavigatorView = () => {
	const renderRouteGroup = (group: iRouteGroup) => {
		const routesToRender: ReactNode[] = [];

		if (group) {
			Object.values(group).forEach((route, routeIndex) => {
				routesToRender.push(
					<Route 
						path={route.path}
						element={route.element} 
						key={`route_${routeIndex}`} />
				);
			});
		}

		return routesToRender;
	};

	return (
		<Router>
			<Routes>
				<Route 
					path={'*'} 
					element={<PageNotFoundView />} />

				<Route element={<AuthenticatedRoute />}>
					{ renderRouteGroup(AllRoutes?.insecure) }
				</Route>

				<Route element={<ProtectedRoute />}>
					{ renderRouteGroup(AllRoutes?.secure) }
				</Route>
			</Routes>
		</Router>
	);
};

export default NavigatorView;