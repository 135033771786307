/**
 * 
 * 	~ UI Filters
 * 
 */


export enum SpecialSearchFilters {
	all = '*',
}


/**
 * This Search Filter pattern is used by all menus
 */
export interface SearchFilter<PossibleFilters> {
	label: string,
	keyword?: string,
	aggregate?: boolean,
	filter: PossibleFilters,
}

/**
 * This extends the Search Filter pattern by giving it a set of child items that 
 * contextually relate to the parent search filter
 */
export interface SearchFilterGranular<PossibleFilters> extends SearchFilter<PossibleFilters> {
	subItems?: SearchFilter<PossibleFilters>[],
}