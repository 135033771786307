/**
 * 
 * 	~ Create a selector
 * 
 */


import { store } from '../configureStore';
import { RootStoreState } from '../State';

const { getState } = store;

export type iSelectorFunction<iRequestDTO, iResponseDTO> = (dto: iRequestDTO) => iResponseDTO;


/**
 * Takes a selector function and passes a fresh instance of the store to it
 * @param selectorFunction 
 * @returns 
 */
export const dispatchStoreSelector = <iDTO>(
	selectorFunction: (store: RootStoreState) => iDTO
) => selectorFunction(
		getState()
	);