/*
	~ School Reducer Actions
*/

import { iSchoolStoreCollectionDTO, iSchoolStoreDTO } from '@domain/Interfaces/AppState/school/dto';

import {
	iSchoolAction,
	SchoolActionTypes,
	iUpateSchoolAction,
	iIsFetchingSchoolAction,
	iUpateAllSchoolsAction,

} from './types';


/* ACTION CREATORS */

export function isFetchingSchools(isFetching = true): iIsFetchingSchoolAction {
	return {
		isFetching,
		type: SchoolActionTypes.FETCHING_SCHOOLS,
	};
}

export function updateSchool(schoolDTO: iSchoolStoreDTO): iUpateSchoolAction {
	return {
		school: schoolDTO,
		type: SchoolActionTypes.UPDATE_SCHOOL,
	};
}

export function updateAllSchools(schoolDTOCollection: iSchoolStoreCollectionDTO): iUpateAllSchoolsAction {
	return {
		schools: schoolDTOCollection,
		type: SchoolActionTypes.UPDATE_ALL_SCHOOLS,
	};
}

export function resetAll(): iSchoolAction {
	return {
		type: SchoolActionTypes.RESET_ALL 
	};
}