/*
	~ Back To Top Button Component
*/

import { useEffect, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { ArrowUpward } from '@mui/icons-material';
import { getClassesType } from '@interfaces/tssReact';
import { SlideAnimations } from '@assets/styles/muiTypes/animation';
import { ButtonColors, ButtonSizes } from '@assets/styles/muiTypes/button';

import {
	Fab,
	Slide,
	Tooltip,

} from '@mui/material';


const Styles = () => ({
	backToTopButton: {
		left: 25,
		bottom: 25,
		zIndex: 99999,
		position: 'fixed',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iBackToTopButtonProps extends getClassesType<stylesType> {
	tipLabel?: string,
}

const BackToTopButton = (props: iBackToTopButtonProps) => {
	const { tipLabel = 'Back to Top!', } = props;

	const classes = withStyles.getClasses<stylesType>(props);
	const [slideInBackToTopButton, setSlideInBackToTopButton] = useState(false);
	let lastKnownScrollPosition = 0;
	let isTicking = false;

	useEffect(() => {
		window.addEventListener('scroll', handlePageOnScroll);

		return () => {
			window.removeEventListener('scroll', handlePageOnScroll);
		};

	}, []);

	const handleBackToTopButtonOnClick = () => {
		if (lastKnownScrollPosition >= (window.innerHeight * 10)) {
			window.scrollTo({
				top: 0
			});

		} else {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	};

	const handlePageOnScroll = () => {
		lastKnownScrollPosition = window.scrollY;

		if (!isTicking) {
			window.requestAnimationFrame(() => {
				checkWindowForSlideInEffect();
				isTicking = false;
			});

			isTicking = true;
		}
	};

	const checkWindowForSlideInEffect = () => {
		if (lastKnownScrollPosition >= window.outerHeight) {
			setSlideInBackToTopButton(true);
			
		} else {
			setSlideInBackToTopButton(false);
		}
	};

	return (
		<Slide
			in={slideInBackToTopButton}
			direction={SlideAnimations.up}>
			
			<Tooltip title={tipLabel}>
				<Fab
					aria-label="back to top"
					size={ButtonSizes.medium}
					color={ButtonColors.primary}
					onClick={handleBackToTopButtonOnClick}
					classes={{
						root: classes.backToTopButton 
					}}>

					<ArrowUpward />
				</Fab>
			</Tooltip>
		</Slide>
	);
};


export default withStyles(BackToTopButton, Styles);