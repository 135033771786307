/**
 * 
 *  ~ User Access Control [Features] - Entity - Data Domain
 * 
 */

import BaseEntity, { iBaseEntity } from '../BaseEntity';

import { FeatureAccessId } from '@data/Shared/Types/uac';
import { FeatureAccessLevels } from '@data/Shared/Enums/user/uac';
import { guardStringFromMaxLength } from '@data/Shared/Validation/model/string';


export interface iFeatureAccess extends iBaseEntity {
	level: FeatureAccessLevels,
}


/**
 * Feature Access Entity
 */
export default class FeatureAccess extends BaseEntity implements iFeatureAccess {
	private _level: FeatureAccessLevels = FeatureAccessLevels.RESTRICTED;

	constructor({
		id,
		level,
	}: {
		id: FeatureAccessId,
        level?: FeatureAccessLevels,

    }) {
		super(id);

		if (level) this._level = level;
	}

	@guardStringFromMaxLength()
	set level(newLevel: FeatureAccessLevels) {
		this._level = newLevel;
	}

	get level(): FeatureAccessLevels {
    	return this._level;
	}

}