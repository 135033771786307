/**
 * 
 * 	~ Admin Side Menu Interfaces & Types
 * 
 */

import { ReactNode } from 'react';


export enum AdminSideMenuType {
	option,
	header,
	divider,
}

export enum SideMenuItemIds {
	dashboard,
	allSchools,
	crimeHeatMap,
	eventTimeline,
}

export interface iAdminSideMenuItem {
	type: AdminSideMenuType,
	
	url?: string,
	label?: string,
	icon?: ReactNode,
	id?: SideMenuItemIds,
}