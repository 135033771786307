/*
	~ Submit Button Component
*/

import { MouseEventHandler } from 'react';
import { CSSObject } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import { Theme } from '@mui/system/createTheme';
import { hasChildren } from '@interfaces/React';
import { getClassesType } from '@interfaces/tssReact';

import {
	Button,
	ButtonBaseProps,
	CircularProgress,

} from '@mui/material';

import {
	Buttons,
	ButtonSizes,
	ButtonColors,
	ButtonVariants,
	ButtonSizeTypes,
	ButtonColorTypes,
	ButtonVariantTypes,

} from '@assets/styles/muiTypes/button';


const Styles = (theme: Theme) => ({
	spinner: {
		color: theme.palette.primary.contrastText,
	} as CSSObject,
});

type stylesType = ReturnType<typeof Styles>;

interface iSubmitButtonProps extends getClassesType<stylesType>, hasChildren {
    disabled?: boolean,
    isLoading?: boolean,
    spinnerSize?: number,
    size?: ButtonSizeTypes,
    color?: ButtonColorTypes,
    variant?: ButtonVariantTypes,
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined,
}


/**
 * 
 * @param props 
 * @returns 
 */
const ControlledButton = (props: iSubmitButtonProps & Partial<ButtonBaseProps>) => {
	const styles = withStyles.getClasses(props);

	const {
		onClick,
		children = '',
		disabled = false,
		spinnerSize = 30,
		isLoading = false,
		size = ButtonSizes.large,
		color = ButtonColors.primary,
		variant = ButtonVariants.contained,
		...otherProps

	} = props;

	const renderSpinner = (size: number) => {
		return (
			<CircularProgress size={size} className={styles.spinner} />
		);
	}

	return (
		<Button
			size={size}
			color={color}
			variant={variant}
			onClick={onClick}
			disabled={disabled}
			type={Buttons.submit}
			{ ...otherProps }>

			{ isLoading ? renderSpinner(spinnerSize) : children }
		</Button>
	);
};


export default withStyles(ControlledButton, Styles);