/*
	~ Dashboard View
*/


import PricingTable from '@atoms/_stripePricingTable';

import Styles, { stylesType } from './styles';

import { useState } from 'react';
import { withStyles } from 'tss-react/mui';
// import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';


interface iProps extends getClassesType<stylesType> {}

const DashboardView = (props: iProps) => {
	// const navigate = useNavigate();
	const classes = withStyles.getClasses<stylesType>(props);
	const [  ] = useState();

	return (
		<div className={classes.container}>
			<h1>Dashboard!</h1>

			<PricingTable />
		</div>
	);
};

export default withStyles(DashboardView, Styles);