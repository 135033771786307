/**
 * 
 *  ~ MUI Library Types - Text
 * 
 */


/**
 * Text Color Prop
*/
export enum TextColors {
    primary = 'primary',
    inherit = 'inherit',
    secondary = 'secondary',
    error = 'error',
    info = 'info',
}

export type TextColorTypes = 
    TextColors.primary | 
    TextColors.inherit | 
    TextColors.secondary | 
    TextColors.error | 
    TextColors.info;

// ======================

/**
 * Text Variants Prop
*/
export enum TextVariants {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    subtitle1 = 'subtitle1',
    subtitle2 = 'subtitle2',
    body1 = 'body1',
    body2 = 'body2',
    inherit = 'inherit',
}

export type TextVariantTypes = 
    TextVariants.h1 | 
    TextVariants.h2 | 
    TextVariants.h3 | 
    TextVariants.h4 | 
    TextVariants.h5 | 
    TextVariants.h6 | 
    TextVariants.subtitle1 | 
    TextVariants.subtitle2 | 
    TextVariants.body1 | 
    TextVariants.body2 | 
    TextVariants.inherit;

// ======================