/**
 * 
 *  ~ User Action Types
 * 
 */

// import {
// 	iUserAction,
// 	iUpateUserAction,
// 	iAuthenticateUserAction,

// } from '../../State/User/types';


/**
 * User State Actions Interface
 */
// export interface iUserActionCreators {
//     resetAll(): iUserAction,
//     resetFeatureAccess(): iUserAction,
//     updateAuthentication(userDTO: iUserDTO): iUpateUserAction,
//     isFetchingUser(isFetching?: boolean): iAuthenticateUserAction,
// }



import { Action } from 'redux';
import { iUserStoreDTO } from '@domain/Interfaces/AppState/user/dto';

export enum UserActionTypes {
    RESET_ALL = 'RESET_ALL_USER_ACTION',
    UPDATE_USER = 'UPDATE_USER_USER_ACTION',
    RESET_FEATURES = 'RESET_FEATURES_USER_ACTION',
    UPDATE_FEATURE_ACCESS = 'UPDATE_FEATURE_ACCESS_USER_ACTION',
    FETCHING_FEATURE_ACCESS = 'FETCHING_USER_FEATURE_ACCESS_ACTION',
    FETCHING_AUTHENTICATION = 'FETCHING_AUTHENTICATION_USER_ACTION',
    UPDATE_USER_AUTHENTICATION = 'UPDATE_USER_AUTHENTICATION_USER_ACTION',
}

export interface featureAccessFlag {
    id: string,
    level: number,
}

export interface iAuthenticateUserAction extends Action<UserActionTypes> {
    isAuthenticated: boolean,
}

export interface isFetchingUserAction extends Action<UserActionTypes> {
    isFetching: boolean,
}

export interface updateUserFeatureAccessAction extends Action<UserActionTypes> {
    features: featureAccessFlag[],
}

// Generic
export interface iUserAction extends Action<UserActionTypes> {}

// Specific
export interface iUpateUserAction extends Action<UserActionTypes> {
    user: iUserStoreDTO,
}