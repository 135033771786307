/*
	~ School Selector Component
*/

import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { iSchoolUI } from '@interfaces/schools';
import { getClassesType } from '@interfaces/tssReact';
import { iSchoolDuct } from '@ducts/Interfaces/school';
import { transformStateSchoolsToSchoolUIs } from '@modelling/school';
import { getAllSchoolsDuct } from '@ducts/DuctSelectors/school';

import {
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormHelperText,
	SelectChangeEvent,
	SelectProps,

} from '@mui/material';


const Styles = (/* theme: Theme */) => ({
	menuItem: {} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iSchoolSelector extends Omit<SelectProps, 'classes' | 'className' | 'onChange'>, getClassesType<stylesType> {
	helperText?: string,
	onChange: (school: iSchoolUI | iSchoolUI[]) => void,
}


const SchoolSelector = (props: iSchoolSelector) => {
	const classes = withStyles.getClasses<stylesType>(props);

	const {
		id,
		value,
		onChange,
		helperText,
		label = 'Select a School',
		// This is here for destructuring only
		// eslint-disable-next-line
		classes: bob,
		...otherProps

	} = props;
	
	const stateSchools: iSchoolDuct[] = useSelector(getAllSchoolsDuct);
	const schools = transformStateSchoolsToSchoolUIs(stateSchools);

	const handleOnChange = (event: SelectChangeEvent<any>) => {
		if (Array.isArray(event?.target?.value)) {
			const uuids = event?.target?.value;
			const selectedSchools = [];

			for (const uuid of uuids) {
				const foundSchool = schools.find(school => school.uuid === uuid);

				if (foundSchool) {
					selectedSchools.push(foundSchool);
				}
			}
			onChange(selectedSchools);

		} else {
			const uuid = event?.target?.value;
			const foundSchool = schools.find(school => school.uuid === uuid);

			if (foundSchool) {
				onChange(foundSchool);
			}
		}
	};

	const renderMenuItems = () => {
		const items: ReactElement[] = [];
		for (const school of schools) {
			const { 
				uuid, 
				name,
			} = school;

			items.push(
				<MenuItem 
					value={uuid}
					key={`${uuid}-menu-item`}
					className={classes.menuItem}>

					{ name }
				</MenuItem>
			);
		}

		return items;
	};

	return (
		<FormControl fullWidth>
			<InputLabel id={`${id}-label`}>
				{ label }
			</InputLabel>

			<Select
				{ ...otherProps }
				id={id}
				label={label}
				value={value}
				labelId={`${id}-label`}
				onChange={handleOnChange}
			>
				<MenuItem value={''}>
					<em>None</em>
				</MenuItem>

				{ renderMenuItems() }
			</Select>

			{
				helperText && (
					<FormHelperText>
						{ helperText }
					</FormHelperText>
				)
			}
		</FormControl>
	);
};


export default withStyles(SchoolSelector, Styles);
