/*
	~ User State Reducer & Actions
*/

import { iUserState } from '@domain/Interfaces/AppState/user/store';

import {
	UserActionTypes,
	iUpateUserAction,
	isFetchingUserAction,
	iAuthenticateUserAction,
	updateUserFeatureAccessAction,

} from '../../Actions/User/types';


const initialState: iUserState = {
	email: '',
	id: 'none',
	isFetching: false,
	featureAccess: [],
	isAuthenticated: false,
};

type PossibleActions = 
	iUpateUserAction & 
	iAuthenticateUserAction & 
	isFetchingUserAction &
	updateUserFeatureAccessAction;


/* STATE REDUCER */

export default function reducer(state: iUserState = initialState, action: PossibleActions) {

	switch(action.type) {
		case UserActionTypes.FETCHING_FEATURE_ACCESS:
		case UserActionTypes.FETCHING_AUTHENTICATION:
			return {
				...state,
				isFetching: action.isFetching,
			};
			
		case UserActionTypes.UPDATE_USER_AUTHENTICATION:
			return {
				...state,
				isAuthenticated: action.isAuthenticated,
			};

		case UserActionTypes.UPDATE_FEATURE_ACCESS:
			return {
				...state,
				featureAccess: action.features,
			};

		case UserActionTypes.UPDATE_USER:
			return {
				...state,
				...action.user,
			};
			
		case UserActionTypes.RESET_ALL:
			return initialState;
			
		default: 
			return state;
	}

}

