/*
	~ School Events State Reducer & Actions
*/

import { iSchoolEventState } from '@domain/Interfaces/AppState/schoolEvent/store';

import {
	SchoolEventActionTypes,
	iUpateSchoolEventAction,
	iUpateAllSchoolEventsAction,
	iIsFetchingSchoolEventsAction,

} from '../../Actions/SchoolEvent/types';


const initialState: iSchoolEventState = {
	data: {},
	order: [],
	isFetching: false,
};

type PossibleActions = 
	iUpateSchoolEventAction & 
	iUpateAllSchoolEventsAction &
	iIsFetchingSchoolEventsAction;


/* STATE REDUCER */

export default function reducer(state: iSchoolEventState = initialState, action: PossibleActions) {

	switch(action.type) {
		case SchoolEventActionTypes.FETCHING_SCHOOL_EVENTS:
			return {
				...state,
				isFetching: action.isFetching,
			};
			
		case SchoolEventActionTypes.UPDATE_SCHOOL_EVENT:
			return {
				...state,
				data: {
					...state.data,
					[action.event.uuid]: {
						...state.data[action.event.uuid],
						...action.event,
					},
				},
			};
		
		case SchoolEventActionTypes.UPDATE_ALL_SCHOOL_EVENTS:
			return {
				...state,
				data: {
					...action.events.data
				},
				order: Array.from(action.events.order),
			};
			
		case SchoolEventActionTypes.RESET_ALL:
			return initialState;
			
		default: 
			return state;
	}

}

