/**
 * 
 *  ~ School Entity Data
 * 
 */

import BaseEntity, { iBaseEntity } from '../BaseEntity';

import { Moment } from 'moment';
import { SchoolType } from '@data/Shared/Enums/schools/school';


export interface iGeoLocation {
	lat: number,
	long: number,
}

export const EmptyGeoLocation: iGeoLocation = {
	lat: 0,
	long: 0,
};

export interface iSchool extends Pick<iBaseEntity, 'id'> {
	name: string,
    created: Moment,
    updated: Moment,
    type: SchoolType,
    geoLocation: iGeoLocation,

	slc?: number,
    fax?: string,
    city?: string,
    email?: string,
    phone?: string,
    website?: string,
    rceName?: string,
    grades?: string[],
    principal?: string,
    postalCode?: string,
    rceCsapCode?: number,
    schoolFamily?: string,
    civicAddress?: string,
    vicePrincipal?: string,
    principalEmail?: string,
    mailingAddress?: string,
    feederSchools?: string[],
    vicePrincipalEmail?: string,
}


/**
 * School Entity
 */
export default class School extends BaseEntity implements iSchool {
	private _fax = '';
	private _name = '';
	private _city = '';
	private _slc = -999;
	private _email = '';
	private _phone = '';
	private _website = '';
	private _rceName = '';
	private _principal = '';
	private _created: Moment;
	private _updated: Moment;
	private _postalCode = '';
	private _type: SchoolType;
	private _schoolFamily = '';
	private _civicAddress = '';
	private _rceCsapCode = -999;
	private _vicePrincipal = '';
	private _principalEmail = '';
	private _mailingAddress = '';
	private _grades: string[] = [];
	private _vicePrincipalEmail = '';
	private _feederSchools: string[] = [];

	private _geoLocation: { lat: number, long: number } = {
		lat: -999,
		long: -999,
	};

	constructor({
		id,
		slc,
		fax,
		name,
		type,
		city,
		email,
		phone,
		grades,
		website,
		created,
		updated,
		rceName,
		principal,
		postalCode,
		geoLocation,
		rceCsapCode,
		schoolFamily,
		civicAddress,
		vicePrincipal,
		feederSchools,
		principalEmail,
		mailingAddress,
		vicePrincipalEmail,

	}: iSchool) {
		// Rquired to Create
		super(id);
		this._name = name;
		this._type = type;
		this._updated = updated;
		this._created = created;
		this._geoLocation = geoLocation;
		
		// Not Required to Create
		if (slc) this._slc = slc;
		if (fax) this._fax = fax;
		if (city) this._city = city;
		if (email) this._email = email;
		if (phone) this._phone = phone;
		if (grades) this._grades = grades;
		if (website) this._website = website;
		if (rceName) this._rceName = rceName;
		if (principal) this._principal = principal;
		if (postalCode) this._postalCode = postalCode;
		if (rceCsapCode) this._rceCsapCode = rceCsapCode;
		if (schoolFamily) this._schoolFamily = schoolFamily;
		if (civicAddress) this._civicAddress = civicAddress;
		if (feederSchools) this._feederSchools = feederSchools;
		if (vicePrincipal) this._vicePrincipal = vicePrincipal;
		if (principalEmail) this._principalEmail = principalEmail;
		if (mailingAddress) this._mailingAddress = mailingAddress;
		if (vicePrincipalEmail) this._vicePrincipalEmail = vicePrincipalEmail;
	}

	set name(newName: string) {
		this._name = newName;
	}
    
	get name(): string {
		return this._name;
	}


	get slc(): number {
		return this._slc;
	}

	set slc(newSlc: number) {
		this._slc = newSlc;
	}

	get fax(): string {
		return this._fax;
	}

	set fax(newFax: string) {
		this._fax = newFax;
	}

	get type(): SchoolType {
		return this._type;
	}

	set type(newType: SchoolType) {
		this._type = newType;
	}

	get city(): string {
		return this._city;
	}

	set city(newCity: string) {
		this._city = newCity;
	}

	get email(): string {
		return this._email;
	}

	set email(newEmail: string) {
		this._email = newEmail;
	}

	get phone(): string {
		return this._phone;
	}

	set phone(newPhone: string) {
		this._phone = newPhone;
	}

	get website(): string {
		return this._website;
	}

	set website(newWebsite: string) {
		this._website = newWebsite;
	}

	get created(): Moment {
		return this._created;
	}

	set created(newCreated: Moment) {
		this._created = newCreated;
	}

	get updated(): Moment {
		return this._updated;
	}

	set updated(newUpdated: Moment) {
		this._updated = newUpdated;
	}

	get rceName(): string {
		return this._rceName;
	}

	set rceName(newRceName: string) {
		this._rceName = newRceName;
	}

	get grades(): string[] {
		return this._grades;
	}

	set grades(newGrades: string[]) {
		this._grades = newGrades;
	}

	get principal(): string {
		return this._principal;
	}

	set principal(newPrincipal: string) {
		this._principal = newPrincipal;
	}

	get postalCode(): string {
		return this._postalCode;
	}

	set postalCode(newPostalCode: string) {
		this._postalCode = newPostalCode;
	}

	get rceCsapCode(): number {
		return this._rceCsapCode;
	}

	set rceCsapCode(newRceCsapCode: number) {
		this._rceCsapCode = newRceCsapCode;
	}

	get schoolFamily(): string {
		return this._schoolFamily;
	}

	set schoolFamily(newSchoolFamily: string) {
		this._schoolFamily = newSchoolFamily;
	}

	get civicAddress(): string {
		return this._civicAddress;
	}

	set civicAddress(newCivicAddress: string) {
		this._civicAddress = newCivicAddress;
	}

	get vicePrincipal(): string {
		return this._vicePrincipal;
	}

	set vicePrincipal(newVicePrincipal: string) {
		this._vicePrincipal = newVicePrincipal;
	}

	get principalEmail(): string {
		return this._principalEmail;
	}

	set principalEmail(newPrincipalEmail: string) {
		this._principalEmail = newPrincipalEmail;
	}

	get mailingAddress(): string {
		return this._mailingAddress;
	}

	set mailingAddress(newMailingAddress: string) {
		this._mailingAddress = newMailingAddress;
	}

	get feederSchools(): string[] {
		return this._feederSchools;
	}

	set feederSchools(newFeederSchools: string[]) {
		this._feederSchools = newFeederSchools;
	}

	get vicePrincipalEmail(): string {
		return this._vicePrincipalEmail;
	}

	set vicePrincipalEmail(newVicePrincipalEmail: string) {
		this._vicePrincipalEmail = newVicePrincipalEmail;
	}


	get geoLocation(): { lat: number, long: number } {
		return this._geoLocation;
	}
	
	set geoLocation(newGeolocation: {
        lat: number,
        long: number,
    }) {
		this._geoLocation = newGeolocation;
	}

}