/*
	~ All Browser-based localStorage Session Keys
*/

import storage from 'redux-persist/lib/storage';


export const userPersistConfig = {
	storage,
	key: 'user_profile_auth_key',
//   whitelist: ['auth', 'profile'],
};