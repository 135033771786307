/*
	~ Search Filter App Bar Component
*/

import { ReactNode } from 'react';
import { CSSObject } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import { Menu } from '@mui/icons-material';
import { Theme } from '@mui/system/createTheme';
import { SearchFilter, SpecialSearchFilters } from '@interfaces/filters';
import { getClassesType } from '@interfaces/tssReact';

import {
	Chip,
	AppBar,
	IconButton,

} from '@mui/material';



const Styles = (theme: Theme) => ({
	filterBar: {
		width: '100%',
		minHeight: 60,
		position: 'relative',
		flexDirection: 'row',
		transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

	} as CSSObject,
	openDrawerButton: {
		right: 25,
		top: '15%',
		position: 'absolute',
	} as CSSObject,
	menuIcon: {
		color: '#ffffff',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iSearchFilterAppBarProps<FilterTypes> extends getClassesType<stylesType> {
	onDrawerButtonClick?: () => void,
	filters: SearchFilter<FilterTypes>[],
	onFilterDelete?: (filter: SearchFilter<FilterTypes | SpecialSearchFilters>) => void,
}

const SearchFilterAppBar = <FilterTypes,>(props: iSearchFilterAppBarProps<FilterTypes>) => {
	const classes = withStyles.getClasses<stylesType>(props);

	const {
		filters, 
		onDrawerButtonClick = () => null,
		onFilterDelete = (filter: SearchFilter<FilterTypes | SpecialSearchFilters>) => null,
	
	} = props;


	const handleFilterOnDelete = (filter: SearchFilter<FilterTypes | SpecialSearchFilters>) => () => {
		onFilterDelete(filter);
	};
	
	const handleDrawerButtonOnClick = () => {
		onDrawerButtonClick();
	};

	const renderFilterChips = () => {
		const chips: ReactNode[] = [];

		if (filters?.length > 0) {
			// Static Remove Filter chip
			chips.push(
				<>
					Filters:&nbsp;
					<Chip 
						color={'secondary'}
						label={'Remove filters'} 
						onDelete={
							handleFilterOnDelete({
								label: '',
								filter: SpecialSearchFilters.all,
							})
						} />
				</>
			);
			 
			for (const timelineFilter of filters) {
				chips.push(
					<Chip
						variant={'filled'}
						color={'secondary'}
						label={timelineFilter.label}
						onDelete={handleFilterOnDelete(timelineFilter)} />
				);
			}

		}

		return chips;
	};

	return (
		<AppBar 
			position="relative"
			classes={{
				root: classes.filterBar
			}}>

			<h3>
				{ renderFilterChips() }
			</h3>

			<IconButton
				onClick={handleDrawerButtonOnClick}
				classes={{
					root: classes.openDrawerButton,
				}}>

				<Menu className={classes.menuIcon} />
			</IconButton>
		</AppBar>
	);
};


export default withStyles(SearchFilterAppBar, Styles);