/**
 * 
 *  ~ MUI Library Types - Input
 * 
 */


// ======================


/**
 * Input Types Prop
*/
export enum Inputs {
    button = 'button',
    checkbox = 'checkbox',
    color = 'color',
    date = 'date',
    datetimelocal = 'datetime-local',
    email = 'email',
    file = 'file',
    hidden = 'hidden',
    image = 'image',
    month = 'month',
    number = 'number',
    password = 'password',
    radio = 'radio',
    range = 'range',
   reset = 'reset',
    search = 'search',
    submit = 'submit',
    tel = 'tel',
    text = 'text',
    time = 'time',
    url = 'url',
    week = 'week',
}

export type InputTypes = 
    Inputs.button |
    Inputs.checkbox |
    Inputs.color |
    Inputs.date |
    Inputs.datetimelocal |
    Inputs.email |
    Inputs.file |
    Inputs.hidden |
    Inputs.image |
    Inputs.month |
    Inputs.number |
    Inputs.password |
    Inputs.radio |
    Inputs.range |
    Inputs.reset |
    Inputs.search |
    Inputs.submit |
    Inputs.tel |
    Inputs.text |
    Inputs.time |
    Inputs.url |
    Inputs.week;

// ======================


/**
 * Input Variant Prop
*/
export enum InputVariants {
    text = 'text',
    contained = 'contained',
    outlined = 'outlined',
}

export type InputVariantTypes = InputVariants.text | InputVariants.contained | InputVariants.outlined;

// ======================


/**
 * Text Field Variant Prop
*/
export enum TextFieldVariants {
    filled = 'filled',
    outlined = 'outlined',
    standard = 'standard',
}

export type TextFieldVariantTypes = TextFieldVariants.filled | TextFieldVariants.outlined | TextFieldVariants.standard;

// ======================