/**
 * 
 *  ~ School Types
 * 
 */

import { Moment } from 'moment';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import {
	SearchFilter,
	SearchFilterGranular,
	SpecialSearchFilters,

} from './filters';


export interface iSchoolCateogies {
    filter: SchoolType,
	content: ReactJSXElement | string,
}

export enum SchoolType {
	prekAndKindergarten = 'pre_kindergarten_and_kindergarten_type',
	elementary = 'elementary_type',
	juniorHigh = 'junior_highschool_type',
	highschool = 'highschool_type',
}

export enum SchoolFilters {
    schoolType = 'filter_schools_by_school_type',
    schoolInformation = 'filter_by_school_information',
    schoolNameSearch = 'filter_schools_by_school_name_search',
}

export enum SchoolInformationFilters {
    byCity = 'filter_schools_by_city',
}

export type AllSchoolFilters = 
    SpecialSearchFilters | 
    SchoolFilters | 
    SchoolType | 
    SchoolInformationFilters;

export interface SchoolFilter extends SearchFilter<AllSchoolFilters> {}

export interface AllSchoolsFilterOption extends SearchFilterGranular<AllSchoolFilters> {}

export interface AllSchoolsFilterSubItems extends SearchFilter<AllSchoolFilters> {}

/**
 * UI Mapping of the School Ducts from the Core
 */
export interface iSchoolUI {
	uuid: string,
    type: string,
    name: string,
    created: Moment,
    updated: Moment,
    
	slc?: number,
    fax?: string,
    city?: string,
    email?: string,
    phone?: string,
    website?: string,
    rceName?: string,
    grades?: string[],
    principal?: string,
    postalCode?: string,
    rceCsapCode?: number,
    schoolFamily?: string,
    civicAddress?: string,
    vicePrincipal?: string,
    principalEmail?: string,
    mailingAddress?: string,
    feederSchools?: string[],
    vicePrincipalEmail?: string,
    geoLocation?: {
        lat: number,
        long: number,
    },
}