/**
 * 
 * 	~ Pipeline Modelling
 * 
 */

import moment from 'moment';

import { iModelling } from '@domain/Interfaces/modelling';
import { iPipeline } from '@data/Entities/Piipeline/pipeline';
import { iRemotePipelineDTO } from '@domain/Interfaces/remoteDTOs/pipeline';


export const PipelineConfigurationModelling: iModelling<iRemotePipelineDTO, iPipeline> = {
	toDTO: (pipeline: iPipeline) => {
		return {
			lastRun: pipeline.lastRun.toISOString(),
		};
	},
	toModel: (pipelineDTO: iRemotePipelineDTO) => {
		return {
			lastRun: moment(pipelineDTO.lastRun)
		};
	},
};