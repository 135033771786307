/*
	~ Section Title Component
*/

import includeClasses from 'classnames';

import { ReactNode } from 'react';
import { CSSObject } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import { hasChildren } from '@interfaces/React';
import { getClassesType } from '@interfaces/tssReact';
import { TextVariants } from '@assets/styles/muiTypes/text';

import {
	Theme, 
	Typography, 
	TypographyVariant, 
} from '@mui/material';



const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		paddingLeft: '11%',
		paddingRight: '11%',
		flexDirection: 'column',
	} as CSSObject,
	containerFullWidth: {
		width: '100%',
	} as CSSObject,
	title: {
		marginBottom: 25,
		paddingBottom: 10,
		color: theme.palette.primary.dark,
		borderBottom: `solid 5px ${theme.palette.primary.main}`,
	} as CSSObject,
	childContainer: {
		width: '100%',
		display: 'flex',
		pading: '0 20px',
		marginBottom: 50,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iSectionTitleProps extends getClassesType<stylesType>, hasChildren {
	isFullWidth?: boolean,
	containerClass?: string,
	variant?: TypographyVariant,
	titleContent: string | ReactNode | ReactNode[],
}

const SectionTitle = (props: iSectionTitleProps) => {
	const {
		children,
		titleContent,
		isFullWidth = false,
		containerClass = null,
		variant = TextVariants.h2,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<div 
			className={
				includeClasses({
					[classes.container]: true,
					[classes.containerFullWidth]: isFullWidth,
					[containerClass as string]: containerClass,
				})
			}>
			<Typography variant={variant} className={classes.title}>
				{ titleContent }
			</Typography>

			<div className={classes.childContainer}>
				{ children }
			</div>
		</div>
	);
};


export default withStyles(SectionTitle, Styles);