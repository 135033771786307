/*
	~ Password Reset View
*/

import Form from '@atoms/Form/_form';
import Input from '@atoms/Form/_input';
import SubmitButton from '@atoms/_button';
import BackButton from '@atoms/_backButton';
import FilledTitle from '@atoms/_filledTitle';

import Styles, { stylesType } from './styles';

import { MouseEvent } from 'react';
import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { validEmailRegex } from '@data/Shared/Validation/regex';
import { resetPassword } from '../../core/Ducts/Controllers/authentication';
import { selectUser } from '@ducts/Providers/ApplicationState/StateSelectors/user';

import {
	Paper,
	Button,

} from '@mui/material';
import {
	ButtonSizes, 
	ButtonColors, 
	ButtonVariants,
	Buttons,

} from '@assets/styles/muiTypes/button';


enum PasswordResetFormKeys {
	username = 'username',
}


interface iPasswordResetForm {
	[PasswordResetFormKeys.username]: string,
}


interface iPasswordResetViewProps {}


const PasswordResetView = (props: iPasswordResetViewProps) => {
	const navigate = useNavigate();
	const { isFetching } = useSelector(selectUser);
	const classes = withStyles.getClasses<stylesType>(props);


	const handleBackHomeButtonOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if(navigate) navigate(Paths.login);
	};

	const handleFormOnSubmit = (fields: iPasswordResetForm) => {
		const { username } = fields;

		resetPassword(username);
	};

	const handleBackButtonOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		event.stopPropagation();

		navigate(Paths.marketingHome);
	};


	return (
		<div className={classes.container}>
			<BackButton 
				href={'#'}
				label={'Back Home'}
				onClick={handleBackButtonOnClick} />

			<div className={classes.centeringContainer}>
				<Paper
					square
					elevation={4}
					classes={{
						root: classes.centralizedContent 
					}}>

					<FilledTitle>
						Reset Password
					</FilledTitle>

					<Form<iPasswordResetForm>
						onSubmit={handleFormOnSubmit}
						formClassOverride={classes.passwordFormContainer}>

						<Input
							fieldName={'username'}
							label={'Username / Email'}
							validation={{
								required: '*Required',
								pattern: {
									value: validEmailRegex,
									message: 'Must be a valid email address',
								},
							}} />

						<p className={classes.userInformationalText}>
							Please enter the email address you use as your username in order to 
							<span className={classes.userWarningText}> reset your password.</span>
						</p>
						<p className={classes.userInformationalText}>
							An email will appear in your inbox shortly.
						</p>

						<div className={classes.passwordFormFooter}>
							<Button
								size={'small'}
								variant={'text'}
								color={'secondary'}
								onClick={handleBackHomeButtonOnClick}>

								Back to Login
							</Button>

							<SubmitButton
								type={Buttons.submit}
								isLoading={isFetching}
								size={ButtonSizes.large}
								color={ButtonColors.primary}
								variant={ButtonVariants.contained}>
							
								Reset
							</SubmitButton>
						</div>
					</Form>
				</Paper>
			</div>
		</div>
	);
};

export default withStyles(PasswordResetView, Styles);