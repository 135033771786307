/*
    ~ Lure Row Component
*/

import LureButton from '@atoms/_button';
import includeClasses from 'classnames';

import Styles, { stylesType } from './styles';

import { ReactElement } from 'react';
import { withStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { hasChildren } from '@interfaces/React';
import { Grid, useMediaQuery, } from '@mui/material';
import { getClassesType } from '@interfaces/tssReact';
import { ButtonColors, ButtonVariants } from '@assets/styles/muiTypes/button';


export enum LureRows {
    left,
    right
}

interface iLureRow extends getClassesType<stylesType>, hasChildren {
    image: string,
    type: LureRows,
    onClick: () => void,
    buttonLabel?: string | ReactElement | ReactElement[],
}


const LureRow = (props: iLureRow) => {
	const {
		type,
		image,
		onClick,
		children,
		buttonLabel,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	const renderLureButton = () => {
		return (
			<div 
				className={
					includeClasses({
						[classes.buttonContainer]: true,
						[classes.rightButtonContainer]: type === LureRows.left,
						[classes.leftButtonContainer]: type === LureRows.right,
					})
				}>
				<LureButton 
					onClick={onClick}
					color={ButtonColors.primary}
					variant={ButtonVariants.contained}>

					{ buttonLabel || `Let's get started!` }
				</LureButton>
			</div>
		);
	};

	const renderRightRow = () => {
		return (
			<>
				{
					isMobile && (
						<Grid item xs={12} lg={6}>
							<div 
								className={classes.lureImage}
								style={{
									backgroundImage: `url(${image})`
								}} 
							/>
						</Grid>
					)
				}

				<Grid 
					item 
					lg={6}
					xs={12}
					classes={{
						root: includeClasses({
							[classes.lureContent]: true,
							[classes.lureContentRight]: true,
						})
					}}>

					{ children }
					{ renderLureButton() }
				</Grid>

				{
					!isMobile && (
						<Grid item md={6}>
							<div 
								className={classes.lureImage}
								style={{
									backgroundImage: `url(${image})`
								}} 
							/>
						</Grid>
					)
				}
			</>
		);
	};
    
	const renderLeftRow = () => {
		return (
			<>
				<Grid item xs={12} lg={6}>
					<div 
						className={classes.lureImage}
						style={{
							backgroundImage: `url(${image})`
						}} 
					/>
				</Grid>
                
				<Grid 
					item 
					lg={6}
					xs={12}
					classes={{
						root: classes.lureContent
					}}>

					{ children }
					{ renderLureButton() }
				</Grid>
			</>
		);
	};

	return (
		<div className={classes.container}>
			<Grid
				container 
				classes={{
					root: classes.lureInnerContainer 
				}}>

				{ type === LureRows.right ? renderRightRow() : renderLeftRow() }
			</Grid>
		</div>
	);
};


export default withStyles(LureRow, Styles);
