/*
    ~ Event Timeline Component
*/

import CTVIcon from '@images/ctv.png';
import CBCIcon from '@images/cbc.webp';
import includeClasses from 'classnames';
import TimelineEndMessage from '@atoms/_timelineEndMessage';

import Styles, { stylesType } from './styles';

import { Moment } from 'moment';
import { withStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import { getClassesType } from '@interfaces/tssReact';
import { TimelineEventType } from '@interfaces/schoolEvent';
import { iSchoolTimelineEvent } from '@interfaces/schoolEvent';
import { TextColors, TextVariants, } from '@globalStyles/muiTypes/text';

import {
	useCallback,
	ReactElement,
	ComponentType,
	PropsWithChildren,

} from 'react';

import {
	Twitter,
	Facebook,
	Diversity3,
	SchoolSharp,
	NewspaperSharp,
	CircleOutlined,
	LocalPoliceSharp,

} from '@mui/icons-material';

import {
	Timeline, 
	TimelineDot, 
	TimelineItem, 
	TimelineContent, 
	TimelineSeparator,
	TimelineConnector, 
	TimelineOppositeContent,

} from '@mui/lab';


interface iTimelineProps extends getClassesType<stylesType> {
	lastKnownRunTime: Moment,
	highlightOnHover?: boolean,
	showEndOfTimeline?: boolean,
	events: iSchoolTimelineEvent[],
	ItemContainer?: ComponentType<PropsWithChildren> | null,
}

const EventTimeline = (props: iTimelineProps): ReactElement => {
	const {
		events,
		highlightOnHover,
		lastKnownRunTime,
		ItemContainer = null,
		showEndOfTimeline = false,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	const handleTimelineItemOnClick = useCallback((source: string) => () => {
		window.open(source, '_blank');
	}, []);

	const renderIconByType = (type?: TimelineEventType) => {
		let icon = null;

		if (type) {
			switch (type) {
				case TimelineEventType.policeInvolved:
					icon = (
						<LocalPoliceSharp />
					);
					break;
					
				case TimelineEventType.schoolOfficial:
					icon = (
						<SchoolSharp />
					);
					break;
					
				case TimelineEventType.cbcNews:
					icon = (
						<img
							src={CBCIcon} 
							className={classes.cbcIcon} />
					);
					break;
					
				case TimelineEventType.ctvNews:
					icon = (
						<img
							src={CTVIcon} 
							className={classes.ctvIcon} />
					);
					break;
					
				case TimelineEventType.schoolNews:
					icon = (
						<NewspaperSharp />
					);
					break;
					
				case TimelineEventType.publicOrganized:
					icon = (
						<Diversity3 />
					);
					break;
					
				case TimelineEventType.facebook:
					icon = (
						<Facebook />
					);
					break;
					
				case TimelineEventType.twitter:
					icon = (
						<Twitter />
					);
					break;
			
				default:
					break;
			}
		}

		return icon;
	};

	const renderEndOfTimeline = () => {
		if (showEndOfTimeline) {
			return (
				<TimelineEndMessage />
			);	

		} else {
			return null;
		}
	};
	
	const renderTimelineItems = () => {
		const items: ReactElement[] = [];

		events.forEach(event => {
			const { 
				id,
				source,
				content,
				opposite,
				separator,

			} = event;

			items.push(
				<TimelineItem 
					key={`school_timeline_${id}`}
					onClick={handleTimelineItemOnClick(source)}
					classes={{
						root: includeClasses({
							[classes.timelineItem]: true,
							[classes.timelineItemHover]: highlightOnHover,
						}),
					}}>
					{
						opposite && (
							<TimelineOppositeContent
								align="right"
								color={opposite.color}
								variant={TextVariants.body2}
								sx={{
									m: 'auto 0'
								}}>

								{ opposite.content }
							</TimelineOppositeContent>
						)
					}
					<TimelineSeparator>
						<TimelineConnector />
						<TimelineDot
							color={separator?.dot?.color}
							variant={separator?.dot?.variant}>

							{ renderIconByType(separator?.dot?.type) }
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					{
						content && (
							<TimelineContent 
								sx={{
									px: 2,
									py: '12px', 
								}}>
								
								{ content }
							</TimelineContent>
						)
					}
				</TimelineItem>
			);
		});

		return items;
	};

	return (
		<Timeline position="alternate">
			<TimelineItem 
				classes={{
					root: classes.timelineItem
				}}>
				<TimelineSeparator>
					<TimelineConnector />
					<TimelineDot
						variant={'filled'}
						color={TextColors.primary}>

						<CircleOutlined />
					</TimelineDot>

					<Typography 
						variant={'caption'} 
						classes={{
							root: classes.startTimeLabel 
						}}>

						{ lastKnownRunTime.format('MMM D hh:mm') }
					</Typography>
					<TimelineConnector />
				</TimelineSeparator>
				
				<TimelineContent 
					sx={{
						px: 2,
						py: '12px', 
					}}>
					
				</TimelineContent>
			</TimelineItem>

			{
				ItemContainer === null ? 
					renderTimelineItems() : (
						<ItemContainer>
							{ renderTimelineItems() }
						</ItemContainer>
					) 
			}
			{ renderEndOfTimeline() }
		</Timeline>
	);
};


export default withStyles(EventTimeline, Styles);
