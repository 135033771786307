/**
 * 
 *  ~ State Selctors - Notification(s)
 * 
 */

import { RootStoreState } from '../State';
import { iNotificationState } from '@domain/Interfaces/AppState/notification/store';


export const selectNotifications = <RequestedNotificationModel>(
	modellingCallback: (notifications: iNotificationState) => RequestedNotificationModel

) => ({ notificationCenter }: RootStoreState): RequestedNotificationModel => modellingCallback(notificationCenter)