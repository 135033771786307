/*
	~ Admin Navigation Drawer Component
*/

import includeClasses from 'classnames';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { ReactNode, useState } from 'react';
import { getClassesType } from '@interfaces/tssReact';
import { getRGBAColor } from '@assets/styles/helpers';
import { ChevronLeftSharp, ChevronRightSharp } from '@mui/icons-material';
import { AdminSideMenuType, iAdminSideMenuItem } from '@interfaces/adminSideMenu';

import {
	List, 
	Drawer, 
	Divider, 
	ListItem, 
	Typography,
	IconButton,
	ListItemIcon, 
	ListItemText,
	ListItemButton,
	Theme,
 
} from '@mui/material';


const DRAWER_WIDTH = 250;
const CLOSED_DRAWER_WIDTH = 75;

const Styles = (theme: Theme) => ({
	container: {
		height: '100%',
		transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn}`
	} as CSSObject,
	openedContainer: {
		width: DRAWER_WIDTH,
	} as CSSObject,
	closedContainer: {
		width: CLOSED_DRAWER_WIDTH,
	} as CSSObject,
	
	containerPaper: {
		marginLeft: 3,
		position: 'relative',
		border: `3px solid ${getRGBAColor(theme.palette.primary.dark, 0.05)}`,
		transition: `background-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn}`,
	} as CSSObject,
	paperOpened: {
		backgroundColor: getRGBAColor(theme.palette.primary.contrastText, 1),
	} as CSSObject,
	paperClosed: {
		backgroundColor: getRGBAColor(theme.palette.primary.dark, 0.75),
	} as CSSObject,

	listItemIcon: {
		transition: `color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`
	} as CSSObject,
	iconOpened: {
		color: getRGBAColor('#000000', 0.88),
	} as CSSObject,
	iconClosed: {
		color: getRGBAColor(theme.palette.primary.contrastText, 1),
	} as CSSObject,

	header: {
		paddingTop: 15,
		display: 'flex',
		paddingBottom: 15,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		padding: '15px 5px 15px 5px',
	} as CSSObject,
	divider: {
		marginTop: 15,
		marginBottom: 35,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iSideNavigationDrawerProps extends getClassesType<stylesType> {
	listItems: iAdminSideMenuItem[],
	onItemClick?: (item: iAdminSideMenuItem) => void,
}


const SideNavigationDrawer = (props: iSideNavigationDrawerProps) => {
	const {
		listItems, 
		onItemClick = () => undefined,

	} = props;

	const [isOpen, setIsOpen] = useState(true);
	const classes = withStyles.getClasses<stylesType>(props);

	const handleDrawerToggleOnClick = () => {
		setIsOpen(!isOpen);
	};

	const renderListItem = (key: string, child: ReactNode) => {
		return (
			<ListItem
				key={key}
				disablePadding 
				sx={{
					display: 'block' 
				}}>

				{ child }
			</ListItem>
		);
	};

	const handleItemOnClick = (item: iAdminSideMenuItem) => () => {
		if (onItemClick) {
			onItemClick(item);
		}
	};
	
	const renderListItems = () => {
		const items: ReactNode[] = [];

		if (listItems?.length > 0) {
			for (const [itemIndex, item] of listItems.entries()) {
				const {
					type,
					icon,
					label,

				} = item;

				switch (type) {
					case AdminSideMenuType.divider:
						items.push(
							<Divider 
								key={`_admin_nav_list_divider_${itemIndex}`}
								classes={{
									root: classes.divider 
								}}
								 />
						);
						break;
						
					case AdminSideMenuType.header:
						if (isOpen) {
							items.push(
								renderListItem(
									`_admin_nav_list_item_${itemIndex}`,
									<Typography variant={'subtitle2'}>
										<strong>{ label }</strong>
									</Typography>
								)
							);
						}
						break;
						
					case AdminSideMenuType.option:
						items.push(
							renderListItem(
								`_admin_nav_list_item_${itemIndex}`,
								<ListItemButton
									onClick={handleItemOnClick(item)}
									sx={{
										minHeight: 48,	
										justifyContent: isOpen ? 'initial' : 'center',
										px: 2.5,
									}}>

									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: isOpen ? 3 : 'auto',
											justifyContent: 'center',
										}}
										classes={{
											root: includeClasses({
												[classes.listItemIcon]: true,
												[classes.iconOpened]: isOpen,
												[classes.iconClosed]: !isOpen,
											})
										}}>

										{ icon }
									</ListItemIcon>

									{
										isOpen && (
											<ListItemText primary={label} />
										)
									}
								</ListItemButton>
							)
						);
						break;
				
					default:
						break;
				}
			}
		}

		return items;
	};

	return (
		<Drawer
			open={isOpen}
			elevation={20}
			variant={'permanent'}
			classes={{
				paper: includeClasses({
					[classes.containerPaper]: true,
					[classes.paperOpened]: isOpen,
					[classes.paperClosed]: !isOpen,
				}),
				root: includeClasses({
					[classes.container]: true,
					[classes.openedContainer]: isOpen,
					[classes.closedContainer]: !isOpen,
				}),
			}}>

			<div className={classes.header}>
				<IconButton onClick={handleDrawerToggleOnClick}>
					{ 
						isOpen ? 
							<ChevronLeftSharp /> : 
							<ChevronRightSharp 
								sx={{
									color: '#ffffff' 
								}} /> 
					}
				</IconButton>
			</div>

			<List>
				{ renderListItems() }
			</List>
		</Drawer>
	);
};


export default withStyles(SideNavigationDrawer, Styles);