/**
 * 
 * 	~ Pipeline Store Provider
 * 
 */


// import { dispatchStoreSelector } from '../StateSelectors/createSelector';
import { PipelineActions } from '@ducts/Providers/ApplicationState/Actions';
import { iPipelineStoreProvider } from '@domain/Interfaces/AppState/pipeline/store';


export const PipelineStoreProvider: iPipelineStoreProvider = {
	actions: PipelineActions,
	selectors: {
		test() {
			//
		},
	},
};