/* eslint-disable */

/*
	~ Global Styles ansd Constants
*/


// Global Colours --------------------------------------------------

import {
	red,
	blue,
	grey,
	indigo,
	orange,

} from '@mui/material/colors';

import { getLinearGradient } from './helpers';

const __DEFAULT_WHITE = '#ffffff';
const __DEFAULT_BLACK = '#222222';
const __DEFAULT_DARK_GREY = grey[600];
const __DEFAULT_ACCENT_BLUE = blue[900];
const __DEFAULT_YELLOW = '#f5c516';
const __DEFAULT_LIGHT_GREY = '#e2d9d9';


export const PRIMARY_COLORS = {
	main: blue[600],
	light: blue[300],
	dark: blue[900],
	contrastText: __DEFAULT_WHITE,
};

export const SECONDARY_COLORS = {
	main: indigo[400],
	light: indigo[100],
	dark: indigo[900],
	contrastText: __DEFAULT_WHITE,
};

export const NOTIFICATION_COLORS = {
	main: red[600],
	light: red[400],
	dark: red[900],
	contrastText: __DEFAULT_WHITE,
};

export const ACTION_COLORS = {
	info: blue[600],
	disabled: grey[300],
	highlight: orange[400],
	hr: __DEFAULT_DARK_GREY,
	accent: __DEFAULT_YELLOW,
	contrastText: __DEFAULT_WHITE,
	infoAccent: __DEFAULT_ACCENT_BLUE,
	disabledBackground: __DEFAULT_LIGHT_GREY,
};

const DARK_MIX_GRAD = getLinearGradient('45deg', [`${PRIMARY_COLORS.dark} 25%`, `${SECONDARY_COLORS.dark} 90%`]);
const LIGHT_MIX_GRAD = getLinearGradient('33deg', [`${PRIMARY_COLORS.dark} 15%`, `${NOTIFICATION_COLORS.main} 70%`]);
const SECONDARY_LIGHT_MIX_GRAD = getLinearGradient('45deg', [`${SECONDARY_COLORS.main} 33%`, `${SECONDARY_COLORS.light} 95%`]);

export const GRADIENT_BACKGROUNDS = {
	darkMix: DARK_MIX_GRAD,
	lightMix: LIGHT_MIX_GRAD,
	
	primary: getLinearGradient('45deg', [`${PRIMARY_COLORS.dark} 25%`, `${PRIMARY_COLORS.main} 90%`]),
	primaryLight: getLinearGradient('45deg', [`${PRIMARY_COLORS.main} 25%`, `${PRIMARY_COLORS.light} 90%`]),
	secondary: getLinearGradient('45deg', [`${SECONDARY_COLORS.dark} 25%`, `${SECONDARY_COLORS.main} 90%`]),
	secondaryLight: SECONDARY_LIGHT_MIX_GRAD,
	
	error: getLinearGradient('45deg', [`${NOTIFICATION_COLORS.dark} 25%`, `${NOTIFICATION_COLORS.main} 90%`]),
	info: getLinearGradient('45deg', [`${ACTION_COLORS.infoAccent} 25%`, `${ACTION_COLORS.info} 90%`]),
};



// Global Settings -------------------------------------------------


// Global Asset URLs ------------------------------------------------


// Global Sizing ---------------------------------------------------

// Heights
export const HR_HEIGHT = 1;
export const BIN_BAR_HEIGHT = 55;
export const USER_BAR_HEIGHT = 55;
export const GAME_PIECE_BORDER = 7.5;
export const RECORD_MOMENT_HEIGHT = 143;
export const DEFAULT_NAVBAR_HEIGHT = 143;

export const PLAYER_CANVAS_WIDTH = '100vw';
export const PLAYER_CANVAS_HEIGHT = '50vh';


// Application
export const FULL_SCREEN_WIDTH = '100vw';
export const FULL_SCREEN_HEIGHT = '100vh';

export const PROFILE_MENU_ICON_SQUARE = 115;
export const PROFILE_IMAGE_CROPPER_BORDER = 50;
export const PROFILE_IMAGE_CROPPER_SQUARE = 250;
export const USER_BAR_PROFILE_MENU_SQUARESIZE = USER_BAR_HEIGHT - 25;

// Content Containers
export const CENTRALIZED_BOX = {
	width: '35%',
	minWidth: 350,
	minHeight: 300,
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
};


export const TIMER_BADGE = {
	fontWeight: 700,
	textAlign: 'center',
	color: ACTION_COLORS.highlight,
};


export const MAIN_BACKGROUND_CONTAINER = {
	width: '100%',
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
	background: GRADIENT_BACKGROUNDS.secondaryLight,
};


export const PLAYER_CANVAS = {
	width: PLAYER_CANVAS_WIDTH,
	height: PLAYER_CANVAS_HEIGHT,

	controls: {
		width: 45,
		height: 45,
	},
};

/* eslint-enable */