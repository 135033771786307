/**
 *
 *  ~ String Validation Function(s)
 *
 */


/**
 * Validates any string by given regex formula
 * @param candidate -
 * @param regex -
 * @returns
 */
export function isValid(candidate: string, regex: RegExp): boolean {
	let isValid = false;

	if (candidate && typeof candidate === 'string' && candidate !== '') {
		isValid = candidate.match( regex ) !== null;
	}

	return isValid;
}
