/*
	~ All Routes
*/


import LoginView from '@views/Login';
import RegisterView from '@views/Register';
import DashboardView from '@views/Dashboard';
import AllSchoolsView from '@views/AllSchools';
import PageNotFoundView from '@views/PageNotFound';
import SingleSchoolView from '@views/SingleSchool';
import PasswordResetView from '@views/PasswordReset';
import MarketingHomeView from '@views/MarketingHome';
import PrivacyPolicyView from '@views/PrivacyPolicy';
import SchoolTimelineView from '@views/SchoolTimeline';
import ClientPortalTimeline from '@views/ClientPortalTimeline';
import ClientPortalAllSchoolsListing from '@views/ClientPortalAllSchoolsListing';

import { RouteObject } from 'react-router-dom';

//Route Key Names
export enum RouteNames {
	LOGIN = 'LOGIN_KEY',
	REGISTER = 'REGISTER_KEY',
    DASHBOARD = 'DASHBOARD_KEY',
    ALL_SCHOOLS = 'ALL_SCHOOLS_KEY',
    SINGLE_SCHOOL = 'SINGLE_SCHOOL_KEY',
	MARKETING_HOME = 'MARKETING_HOME_KEY',
	SCHOOL_TIMELINE = 'SCHOOL_TIMELINE_KEY',
	FORGOT_PASSWORD = 'FORGOT_PASSWORD_KEY',
	PAGE_NOT_FOUND = 'PAGE_NOT_FOUND_ROUTE_KEY',
	CP_ALL_SCHOOLS = 'CP_ALL_SCHOOLS_ROUTE_KEY',
	PRIVACY_POLICY = 'PRIVACY_POLICY_ROUTE_KEY',
	CP_SINGLE_SCHOOL = 'CP_SINGLE_SCHOOL_ROUTE_KEY',
	CP_SCHOOL_TIMELINE = 'CP_SCHOOL_TIMELINE_ROUTE_KEY',
}

export enum Paths {
	login = '/login',
	marketingHome = '/',
	register = '/register',
	allSchools = '/schools',
	dashboard = '/dashboard',
	singleSchool = '/school',
	schoolTimeline = '/timeline',
	cpTimeline = '/portal/timeline',
	privacyPolicy = '/privacy-policy',
	cpSingleSchool = '/portal/school',
	forgotPassword = '/forgot-password',
	cpSchoolsListing = '/portal/schools',
}

export interface iRouteGroup {
	[view_id: string]: RouteObject
}

export interface iRouteGroupWithChildren {
	[view_id: string]: RouteObject & {
		childRoutes?: iRouteGroup,
	},
}


export interface iAllRoutes {
	insecure: iRouteGroup,
	secure: iRouteGroupWithChildren,
}


//Route Objects - Ordered By Flow Chronology
export const AllRoutes: iAllRoutes = {
	// Secure Routes
	secure: {
		[RouteNames.DASHBOARD]: {
			path: Paths.dashboard,
			element: <DashboardView />,
			errorElement: <PageNotFoundView />,
		},
		[RouteNames.CP_SCHOOL_TIMELINE]: {
			path: Paths.cpTimeline,
			element: <ClientPortalTimeline />,
			errorElement: <PageNotFoundView />,
		},
		[RouteNames.CP_ALL_SCHOOLS]: {
			path: Paths.cpSchoolsListing,
			errorElement: <PageNotFoundView />,
			element: <ClientPortalAllSchoolsListing />,
		},
		[RouteNames.CP_SINGLE_SCHOOL]: {
			path: `${Paths.cpSingleSchool}/:schoolId`,
			element: <SingleSchoolView />,
		},
	},
	// Insecure Routes
	insecure: {
		// Login Routes
		[RouteNames.LOGIN]: {
			path: Paths.login,
			element: <LoginView />,
		},
		[RouteNames.MARKETING_HOME]: {
			path: Paths.marketingHome,
			element: <MarketingHomeView />,
		},
		[RouteNames.ALL_SCHOOLS]: {
			path: Paths.allSchools,
			element: <AllSchoolsView />,
		},
		[RouteNames.SINGLE_SCHOOL]: {
			path: `${Paths.singleSchool}/:schoolId`,
			element: <SingleSchoolView />,
		},
		[RouteNames.REGISTER]: {
			path: Paths.register,
			element: <RegisterView />,
		},
		[RouteNames.FORGOT_PASSWORD]: {
			path: Paths.forgotPassword,
			element: <PasswordResetView />,
		},
		// Public Site Routes
		[RouteNames.SCHOOL_TIMELINE]: {
			path: Paths.schoolTimeline,
			element: <SchoolTimelineView />,
		},
		[RouteNames.PRIVACY_POLICY]: {
			path: Paths.privacyPolicy,
			element: <PrivacyPolicyView />,
		},
	},

};

