/*
    ~ Article Hightlighter Component
*/

import includeClasses from 'classnames';

import { Card } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { isEmpty } from '@settings/utilities';
import { Theme } from '@mui/system/createTheme';
import { hasChildren } from '@interfaces/React';
import { getClassesType } from '@interfaces/tssReact';


const Styles = (theme: Theme) => ({
	container: {
		backgroundColor: 'rgba(255, 255, 255, 0.55)',
	} as CSSObject
});

export type stylesType = ReturnType<typeof Styles>;



interface iArticleHighlighter extends getClassesType<stylesType>, hasChildren {
	overrideClasses?: string,
}


const ArticleHightlighter = (props: iArticleHighlighter) => {
	const { 
		children,
		overrideClasses = '',
	
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<Card 
			elevation={4}
			className={
				includeClasses({
					[classes.container]: true,
					[overrideClasses]: !isEmpty(overrideClasses),
				})
			}>
			{ children }
		</Card>
	);
};


export default withStyles(ArticleHightlighter, Styles);
