/**
 * 
 *  ~ State Selectors - School
 * 
 */

import { RootStoreState } from '../State';
import { UUID } from '@ducts/Types/generic';
import { iSchoolState } from '@domain/Interfaces/AppState/school/store';
import { iSchoolStoreDTO, iSchoolStoreCollectionDTO } from '@domain/Interfaces/AppState/school/dto';


/**
 * Gets all schools in store and returns them
 * @param param0 
 * @returns 
 */
export const selectAllSchools = <RequestedSchoolModel>(
	modellingCallback: (schoolsToModel: iSchoolStoreCollectionDTO) => RequestedSchoolModel
) => ({ schools }: RootStoreState): RequestedSchoolModel => modellingCallback(schools);


/**
 * Gets all schools in store by given UUIDs and returns them
 * @param param0 
 * @returns 
 */
export const selectSchoolsByUUIDs = <RequestedSchoolModel>(
	uuids: UUID[],
	modellingCallback: (schoolsToModel: iSchoolStoreCollectionDTO) => RequestedSchoolModel
) => ({ schools }: RootStoreState): RequestedSchoolModel => modellingCallback(getSchoolsByUUIDs(uuids, schools));


/**
 * Get a single school by it's UUID
 * @param uuid 
 * @param modellingCallback 
 * @returns 
 */
export const selectSchoolByUUID = <RequestedSchoolModel>(
	uuid: UUID,
	modellingCallback: (schoolToModel: iSchoolStoreDTO) => RequestedSchoolModel
) => ({ schools }: RootStoreState): RequestedSchoolModel => modellingCallback(schools?.data[uuid]);

	
/**
 * Given schools and school uuids, this function returns only schools of the given UUIDs
 * [NOT A SELECTOR!]
 * @param param0 
 * @returns 
 */
export const getSchoolsByUUIDs = (uuids: UUID[], schools: iSchoolState): iSchoolStoreCollectionDTO => {
	const schoolsCollection: iSchoolStoreCollectionDTO = {
		data: {},
		order: [],
	};

	if (schools?.order?.length > 0) {
		schoolsCollection.order = Array.from(schools.order).filter(uuid => uuids.includes(uuid));

		for (const uuid of schoolsCollection.order) {
			if (schools.data[uuid]) {
				schoolsCollection.data[uuid] = {
					...schools.data[uuid]
				};
			}
		}
	}

	return schoolsCollection;
};

