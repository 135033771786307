// /**
//  * 
//  *  ~ Pipeline Action Types
//  * 
//  */

// import { iPipelineDTO } from '../../DTOs/pipeline';

// import {
// 	iPipelineAction,
// 	iUpatePipelineAction,
// 	iIsFetchingPipelineAction,

// } from '../../State/Pipeline/types';


// /**
//  * Pipeline State Actions Interface
//  */
// export interface iPipelineActionCreators {
//     resetAll(): iPipelineAction,
//     updatePipeline(PipelineDTO: iPipelineDTO): iUpatePipelineAction,
//     isFetchingPipelines(isFetching?: boolean): iIsFetchingPipelineAction,
// }

/**
 * 
 *  ~ Pipeline Action / Action Types
 * 
 */

import { Action } from 'redux';
import { iRemotePipelineDTO } from '@domain/Interfaces/remoteDTOs/pipeline';


export enum PipelineActionTypes {
    RESET_ALL = 'RESET_PIPELINE_ACTION',
    UPDATE_PIPELINE = 'UPDATE_PIPELINE_ACTION',
    FETCHING_PIPELINE = 'FETCHING_PIPELINE_ACTION',
}

export interface iIsFetchingPipelineAction extends Action<PipelineActionTypes> {
    isFetching: boolean,
}

// Generic
export interface iPipelineAction extends Action<PipelineActionTypes> {}

// Specific
export interface iUpatePipelineAction extends Action<PipelineActionTypes> {
    pipeline: iRemotePipelineDTO,
}