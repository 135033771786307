/*
	~ Navbar Component
*/

import NavButton from '@atoms/_button';
import SiteLogo from '@images/logo.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserSettingsMenuButton from '@atoms/_userSettingsMenuButton';

import Styles, { stylesType } from './styles';

import { Paths } from '@settings/routes';
import { withStyles } from 'tss-react/mui';
import { ReactElement, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Close, Menu } from '@mui/icons-material';
import { getClassesType } from '@interfaces/tssReact';
import { ButtonColors } from '@assets/styles/muiTypes/button';
import { DrawerAnchors } from '@assets/styles/muiTypes/drawer';
import { NavbarButtonTypes, TopNavbarMenuItem } from '@interfaces/navbar';

import {
	Chip,
	List,
	Drawer,
	ListItem,
	IconButton,
	ListItemText,
	ListItemButton,

} from '@mui/material';


export interface iNavbar extends getClassesType<stylesType> {
    menuItems?: TopNavbarMenuItem[],
}


const Navbar = (props: iNavbar) => {
	const { menuItems } = props;
	const theme = useTheme();
	const classes = withStyles.getClasses<stylesType>(props);
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
	
	const [isOpen, setIsOpen] = useState(false);


	const handleDrawerOnClose = () => {
		setIsOpen(false);
	};

	const handleMobileDrawerButtonOnClick = () => {
		setIsOpen(true);
	};
	
	const renderMobileDrawer = () => {
		if (isMobile) {
			return (
				<Drawer
					open={isOpen}
					anchor={DrawerAnchors.top}
					onClose={handleDrawerOnClose}>
	
					<div className={classes.mobileDrawerContainer}>
						<div className={classes.drawerHeader}>
							<div className={classes.closeButtonContainer}>
								<IconButton onClick={handleDrawerOnClose}>
									<Close classes={{
										root: classes.closeButtonIcon 
									}} />
								</IconButton>
							</div>
							<img 
								alt={'logo'} 
								src={SiteLogo}
								className={classes.mobileLogo} />

							{ renderTitle() }
						</div>

						{ renderMobileMenuItems() }
					</div>
				</Drawer>
			);

		} else {
			return null;
		}
	};
	
	const renderNavMenuBySize = () => {
		if (isMobile) {
			// Mobile-sized Menu
			return (
				<IconButton 
					onClick={handleMobileDrawerButtonOnClick}
					classes={{
						root: classes.mobileDrawerButton
					}}>

					<Menu />
				</IconButton>
			);

		} else {
			// Medium-sized Menu
			return (
				<div className={classes.menuContainer}>
					{ renderMenuItems() }
				</div>
			);
		}
	};

	const renderMobileMenuItems = () => {
		const items: ReactElement[] = [];

		if (menuItems && menuItems?.length > 0) {
			menuItems?.forEach((item, index) => {
				const { label } = item;

				items.push(
					<ListItem key={`mobile_list_item_${index}`}>
						<ListItemButton 
							classes={{
								root: classes.mobileListItem
							}}>

							<ListItemText primary={label} />
						</ListItemButton>
					</ListItem>
				);
			});
		}

		return (
			<List>
				{ items }
			</List>
		);
	};

	const renderMenuItems = () => {
		const items: ReactElement[] = [];

		if (menuItems && menuItems?.length > 0) {
			menuItems?.forEach((item, index) => {
				const {
					url, 
					type,
					label,
					button,
					loginMenuOptions,

				} = item;

				let indexBasedMargin: object = {};

				if (index === menuItems.length - 1) {
					indexBasedMargin = {
						marginRight: 15,
					};
				}

				switch (type) {
					case NavbarButtonTypes.link:
						items.push(
							<div 
								className={classes.menuItem}
								key={`menu-list-item-${index}`}
								style={{
									...indexBasedMargin,
								}}>
		
								<a 
									href={url} 
									className={classes.menuItemLink}>
		
									{ label }
								</a>
							</div>
						);
						break;

					case NavbarButtonTypes.button:
						if (button) {
							const {
								callback,
								color = ButtonColors.primary,
							} = button;

							if (label) {
								items.push(
									<NavButton
										color={color}
										onClick={callback}
										className={classes.menuItem}
										key={`menu-list-item-${index}`}
										style={{
											...indexBasedMargin,
										}}>
			
										{ label }
									</NavButton>
								);
							}
						}
						break;
						
					case NavbarButtonTypes.loginMenu:
						if (loginMenuOptions) {
							items.push(
								<UserSettingsMenuButton 
									menuItems={loginMenuOptions}
									key={`menu-list-item-${index}`}
									buttonProps={{
										style: {
											...indexBasedMargin,
										},
									}} />
							);
						}
						break;
				
					default:
						break;
				}
			});
		}

		return items;
	};

	const renderTitle = () => {
		return (
			<h1 className={classes.title}>
				SchoolReports.ca
				<Chip 
					size={'small'} 
					label={'BETA'}
					color={'warning'}
					classes={{
						root: classes.beta,
					}} />
			</h1>
		);
	};


	return (
		<div className={classes.container}>
			<a 
				href={Paths.marketingHome}
				className={classes.logoLink}>
				<img 
					alt={'logo'} 
					src={SiteLogo}
					className={classes.logo} />

				{ renderTitle() }
			</a>

			{ renderNavMenuBySize() }
			{ renderMobileDrawer() }
		</div>
	);
};


export default withStyles(Navbar, Styles);
