/**
 * 
 *  School Event Factories
 * 
 */

import SchoolEventsInteractor from '@domain/UseCases/schoolEvents';
import SchoolEventRemoteStoreAdapter from '@ducts/Adapters/RemoteStore/schoolEvent';

import SchoolEventStoreAdapter, { iSchoolEventStoreAdapter } from '@ducts/Adapters/ApplicationState/schoolEvent';

import { CollectionNames } from '../../Domain/Types/Collection';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { iSchoolEventsInteractor } from '@domain/Interfaces/Interactor/iSchoolEventsInteractor';
import { createRemoteStoreProvider } from '@ducts/Providers/RemoteStore/Services/Firebase/RealtimeDB';
import { SchoolEventStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/schoolEvent';


/**
 * Createa a new School Events Interactor class and injects dependencies
 * @returns 
 */
export function createSchoolEventsInteractor(): iSchoolEventsInteractor {
	// Build remote store for school events, then build its provider
	const RemoteStore = createRemoteStoreProvider(CollectionNames.events);
	const SchoolEventsRemoteStoreProvider: iRemoteStore = new RemoteStore();
	// Give the created store events to the adapter
	const remoteSchoolStoreAdapter = new SchoolEventRemoteStoreAdapter(SchoolEventsRemoteStoreProvider);
	// Build store adapter for schools application state
	const schoolEventStore: iSchoolEventStoreAdapter = new SchoolEventStoreAdapter(SchoolEventStoreProvider);
	// Build school event interactor with providers
	return new SchoolEventsInteractor(
		remoteSchoolStoreAdapter,
		schoolEventStore
	);
}