/*
	~ School State Reducer & Actions
*/

import { iSchoolState } from '@domain/Interfaces/AppState/school/store';

import {
	SchoolActionTypes,
	iUpateSchoolAction,
	iUpateAllSchoolsAction,
	iIsFetchingSchoolAction,

} from '../../Actions/School/types';


const initialState: iSchoolState = {
	data: {},
	order: [],
	isFetching: false,
};

type PossibleActions = 
	iUpateSchoolAction & 
	iUpateAllSchoolsAction &
	iIsFetchingSchoolAction;


/* STATE REDUCER */

export default function reducer(state: iSchoolState = initialState, action: PossibleActions) {

	switch(action.type) {
		case SchoolActionTypes.FETCHING_SCHOOLS:
			return {
				...state,
				isFetching: action.isFetching,
			};
			
		case SchoolActionTypes.UPDATE_SCHOOL:
			return {
				...state,
				data: {
					...state.data,
					[action.school.uuid]: {
						...state.data[action.school.uuid],
						...action.school,
					},
				},
			};
		
		case SchoolActionTypes.UPDATE_ALL_SCHOOLS:
			return {
				...state,
				data: {
					...action.schools.data
				},
				order: Array.from(action.schools.order),
			};
			
		case SchoolActionTypes.RESET_ALL:
			return initialState;
			
		default: 
			return state;
	}

}

