/*
	~ Paginated Schools Table Component
*/

import SchoolsTable from '@atoms/_schoolsTable';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCallback, useState } from 'react';
import { iSchoolUI } from '@interfaces/schools';
import { PAGE_SIZE } from '@hooks/searchAndFilter';
import { usePagination } from '@hooks/searchAndFilter';
import { UUID } from '@ducts/Types/generic';


interface iPaginatedSchoolsTableProps {
	schools: iSchoolUI[],
	onSchoolClick?: (uuid: UUID) => void,
}

const PaginatedSchoolsTable = (props: iPaginatedSchoolsTableProps) => {
	const {
		schools,
		onSchoolClick,
	
	} = props;

	const [ isLoading, setIsLoading ] = useState(false);

	const {
		page,
		hasMore,
		setPage,
		loadedItems: loadedSchools,

	} = usePagination(schools);

	const handleNextPage = useCallback((newPage: number) => () => {
		setPage(newPage + PAGE_SIZE);

	}, []);

	const renderEventsLoading = useCallback(() => {
		if (isLoading) {
			return (<h1>loafing...</h1>);

		} else {
			return null;
		}

	}, []);

	let handleOnItemClick = undefined;

	if (onSchoolClick) {
		handleOnItemClick = useCallback((uuid: UUID) => {
			onSchoolClick(uuid);
	
		}, []);
	}

	return (
		<SchoolsTable 
			schools={loadedSchools}
			highlightOnHover={true}
			onItemClick={handleOnItemClick}
			ItemContainer={({ children }) =>
				<InfiniteScroll
					hasMore={hasMore}
					next={handleNextPage(page)}
					loader={renderEventsLoading()}
					dataLength={loadedSchools.length}
				>
					
					{ children }
				</InfiniteScroll>
			} />
	);
};


export default PaginatedSchoolsTable;