/**
 * 
 * 	~ School Duct-layer Modelling
 * 
 */

import { iSchoolStoreCollectionDTO, iSchoolStoreDTO } from '@domain/Interfaces/AppState/school/dto';
import { iCollectionModelling } from '@domain/Interfaces/modelling';
import { iDuctModelling } from '@ducts/Interfaces/ducts';
import { iSchoolDuct } from '@ducts/Interfaces/school';
import moment from 'moment';


/**
 * Model a School from a DTO to Model and back
 */
export const SchoolStoreDuctModelling: iDuctModelling<iSchoolDuct, iSchoolStoreDTO> = {
	toDuct: (schoolDTO: iSchoolStoreDTO) => {
		let schoolDuct: iSchoolDuct = {
			uuid: '', 
			type: '', 
			name: '',
			created: moment(),
			updated: moment(),
		};

		const {
			updated,
			created,
			...remainingEventValues

		} = schoolDTO;

		schoolDuct = {
			...remainingEventValues,
			updated: moment(updated),
			created: moment(created),
		};

		return schoolDuct;
	},
	toStoreDTO: (schoolDuct: iSchoolDuct) => {
		let schoolDTO: iSchoolStoreDTO = {
			uuid: '', 
			type: '', 
			name: '',
			created: moment().toISOString(),
			updated: moment().toISOString(),
		};

		const {
			updated,
			created,
			...remainingEventValues

		} = schoolDuct;

		schoolDTO = {
			...remainingEventValues,
			updated: moment(updated).toISOString(),
			created: moment(created).toISOString(),
		};

		return schoolDTO;
	},
};



/**
 * Model Schools from DTO Arrays to Model Collections and back
 */
export const SchoolStoreDuctCollectionModelling: iCollectionModelling<
	iSchoolStoreDTO, 
	iSchoolDuct
> = {
	toCollection: (schools: iSchoolDuct[]): iSchoolStoreCollectionDTO => {
		const collection: iSchoolStoreCollectionDTO = {
			data: {},
			order: [],
		};
		
		for(const school of schools) {
			collection.order.push(school.uuid);
			collection.data[school.uuid] = SchoolStoreDuctModelling.toStoreDTO(school);
		}

		return collection;
	},
	fromCollection: (schoolDTOCollection: iSchoolStoreCollectionDTO): iSchoolDuct[] => {
		const schools: iSchoolDuct[] = [];
		
		const {
			data,
			order,
		} = schoolDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools.push(
					SchoolStoreDuctModelling.toDuct(
						data[uuid]
					)
				)
			}
		}

		return schools;
	},
};