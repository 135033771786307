/*
	~ App Reducer Actions
*/

import { NotificationTypes } from '@data/Shared/Enums/notifications/messages';

import { 
	iNotificationCenterAction,
	NotificationCenterActionTypes,

} from './types';


/* ACTION CREATORS */

/**
 * Adds a new message to the messages in Notification Center
 * @param param0 
 * @returns 
 */
export function showMessage({
	message = '', messageType = NotificationTypes.INFO 
}): iNotificationCenterAction {
	return { 
		message,
		messageType,
		type: NotificationCenterActionTypes.ADD_MESSAGE_TO_NOTIFICATION_CENTER,
	};
}

// Removes message in Notification Center that is at the front of the queue
export function removeFirstMessageInStack() {
	return {
		type: NotificationCenterActionTypes.REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER 
	};
}