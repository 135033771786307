/**
 * 
 *  ~ Schools Use Case Functions
 * 
 */

import { log } from '@logging/Console';
import { iSchool } from '@data/Entities/Schools/school';
import { CollectionKeys, CollectionNames } from '../Types/Collection';
import { iSchoolStoreAdapter } from '@ducts/Adapters/ApplicationState/school';
import { iSchoolRemoteStoreAdapter } from '@ducts/Adapters/RemoteStore/school';
import { iSchoolInteractor } from '@domain/Interfaces/Interactor/iSchoolsInteractor';



export default class SchoolsInteractor implements iSchoolInteractor {

	private schoolsListener: Promise<() => void> | null = null;

	constructor(
        private _RemoteStoreProvider: iSchoolRemoteStoreAdapter,
		private _SchoolStore: iSchoolStoreAdapter,
	) {}

	/**
     * Subscribed to remote store updates and passes a handler to deal with each 
     * update event
     */
	async listenForSchoolUpdates(): Promise<void> {
    	try {
			this._SchoolStore.actions.isFetchingSchools(true);

    		this.schoolsListener = this._RemoteStoreProvider.subscribeTo(
    			CollectionKeys[CollectionNames.schools], 
    			this.handleSchoolsUpdate
    		);

    	} catch (error) {
			this._SchoolStore.actions.isFetchingSchools(false);
    		log('error', 'Something went wrong with school listener, error given: ', error);
    	}
	}


	private handleSchoolsUpdate = async (schoolsUpdate: iSchool[] | null): Promise<void> => {
		this._SchoolStore.actions.isFetchingSchools(false);

		if (schoolsUpdate === null || schoolsUpdate.length <= 0) {
			this._SchoolStore.actions.resetAll();

		} else {
			this._SchoolStore.actions.updateAllSchools(schoolsUpdate);
		}
	};

}