/**
 * 
 * 	~ Event Timeline Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	cbcIcon: {
		width: 30,
		borderRadius: '50%',
	} as CSSObject,
	ctvIcon: {
		width: 30,
		borderRadius: '50%',
	} as CSSObject,
	timelineItem: {
		height: 143,
	} as CSSObject,
	timelineItemHover: {
		':hover': {
			cursor: 'pointer',
			backgroundColor: 'rgba(0,0,0,0.15)',
		},
	} as CSSObject,
	startTimeLabel: {
		bottom: 4,
		borderRadius: 5,
		position: 'absolute',
		padding: '2.5px 10px',
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;