/**
 * 
 *  ~ School Action Types
 * 
 */

// import { iSchoolDTO } from '../../DTOs/school';
// import { iCollectionModel } from '../../Interfaces/modelling';

// import {
// 	iSchoolAction,
// 	iUpateSchoolAction,
// 	iUpateAllSchoolsAction,
// 	iIsFetchingSchoolAction,

// } from '../../State/School/types';


/**
 * School State Actions Interface
 */
// export interface iSchoolActionCreators {
//     resetAll(): iSchoolAction,
//     updateSchool(schoolDTO: iSchoolDTO): iUpateSchoolAction,
//     isFetchingSchools(isFetching?: boolean): iIsFetchingSchoolAction,
//     updateAllSchools(schoolDTOCollection: iCollectionModel<iSchoolDTO>): iUpateAllSchoolsAction,
// }


import { Action } from 'redux';
import { iRemoteSchoolDTO } from '@domain/Interfaces/remoteDTOs/school';
import { iSchoolStoreCollectionDTO } from '@domain/Interfaces/AppState/school/dto';


export enum SchoolActionTypes {
    RESET_ALL = 'RESET_ALL_SCHOOLS_ACTION',
    UPDATE_SCHOOL = 'UPDATE_SCHOOL_ACTION',
    FETCHING_SCHOOLS = 'FETCHING_SCHOOLS_ACTION',
    UPDATE_ALL_SCHOOLS = 'UPDATE_ALL_SCHOOLS_ACTION',
}

export interface iIsFetchingSchoolAction extends Action<SchoolActionTypes> {
    isFetching: boolean,
}

// Generic
export interface iSchoolAction extends Action<SchoolActionTypes> {}

// Specific
export interface iUpateSchoolAction extends Action<SchoolActionTypes> {
    school: iRemoteSchoolDTO,
}

export interface iUpateAllSchoolsAction extends Action<SchoolActionTypes> {
    schools: iSchoolStoreCollectionDTO,
}