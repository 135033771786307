/**
 * 
 *  ~ Notification Types
 * 
 */


/**
 * View Mapping of Store Notification Types
 */
export enum ViewNotificationTypes {
    info = 'INFO_NOTIFICATION_TYPE',
	error = 'ERROR_NOTIFICATION_TYPE',
	warning = 'WARNING_NOTIFICATION_TYPE',
	success = 'SUCCESS_NOTIFICATION_TYPE',
}


/**
 * View mapping of a Store's Notification
 */
export type iNotificationDuct = {
    message: string,
    messageType: ViewNotificationTypes,
};


/**
 * Allows strings to be Message Types
 * @param type 
 * @param key 
 * @returns 
 */
export const mapMessageTypes = (
	key: keyof typeof ViewNotificationTypes

): ViewNotificationTypes => ViewNotificationTypes[key];