/**
 * 
 *  ~ MUI Library Types - Button
 * 
 */


/**
 * Button Size Prop =====
 */

export enum ButtonSizes {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export type ButtonSizeTypes = ButtonSizes.small | ButtonSizes.medium | ButtonSizes.large;

// ======================


/**
 * Button Types Prop
*/
export enum Buttons {
    password = 'password',
    button = 'button',
    submit = 'submit',
    reset = 'reset',
}

export type ButtonTypes = 
    Buttons.password | 
    Buttons.button | 
    Buttons.submit | 
    Buttons.reset | 
    undefined;

// ======================


/**
 * Button Color Prop
*/
export enum ButtonColors {
    primary = 'primary',
    inherit = 'inherit',
    secondary = 'secondary',
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning',
}

export type ButtonColorTypes = 
    ButtonColors.primary | 
    ButtonColors.inherit | 
    ButtonColors.secondary | 
    ButtonColors.success | 
    ButtonColors.error | 
    ButtonColors.info | 
    ButtonColors.warning;

// ======================


/**
 * Button Variant Prop
*/
export enum ButtonVariants {
    text = 'text',
    contained = 'contained',
    outlined = 'outlined',
}

export type ButtonVariantTypes = ButtonVariants.text | ButtonVariants.contained | ButtonVariants.outlined;

// ======================