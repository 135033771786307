/**
 * 
 * =====================================
 *  ~ APPLICATION ENTRY POINT ----------
 * =====================================
 * 
*/

/**
 *  THIS IS TESTING SCAFFOLDING! Should NEVER be used in production!
 */
// import { createMockedData } from './mockedSchoolFactory';
// console.log(`Beginning mocked data creation! ------`);
// createMockedData().then(mockedData => {
// 	console.log(`mockedData: `, JSON.stringify(mockedData));
// 	console.log(`Finished mocking data successfully! -------`);
// });
// =================================================================

import App from '@views/App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import StoreProvider from '@atoms/_reduxStoreProvider';

import { StrictMode } from 'react';

// Inject !sole! application CSS setup
/* eslint-disable-next-line */
import './assets/styles/foundationalStyles.css';
// --

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
	
root.render(
	<StrictMode>
		<StoreProvider>
			<App />
		</StoreProvider>
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// =====================================