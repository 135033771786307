/**
 * 
 * 	~ Application State Adapters
 * 
 */

import { iAppStoreProvider } from '@domain/Interfaces/AppState/app/store';


export interface iAppStoreAdapter {
	actions: {
		storesHydrated(): void,
		rehydratingStores(): void,
		initializingApplication(): void,
		applicationNowInitialized(): void,
	},
	selectors: {
		//
	},
}


export default class AppStoreAdapter implements iAppStoreAdapter {
	constructor(
		private _AppStore: iAppStoreProvider,
	) {}

	actions = {
		...this._AppStore.actions,
	};


	selectors = {
		placeholderDeleteMe() {
			//
		},
	};
}