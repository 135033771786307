/**
 * 
 * 	~ School Event View-layer Modelling
 * 
 */

import moment from 'moment';

import { filter } from './search';
import { Typography } from '@mui/material';
import { iSchoolTimelineEvent } from '@interfaces/schoolEvent';
import { iSchoolEventDuct } from '@ducts/Interfaces/schoolEvents';
import { TextColors, TextVariants } from '@assets/styles/muiTypes/text';

import {
	TimelineFilter,
	TimelineFilters,
	TimelineEventType,
	TimelineTimeTypeFilters,
	TimelineEventTypeFilters,

} from '@interfaces/schoolEvent';

const MAX_TITLE_CHARS = 100;
const MAX_CONTENT_CHARS = 180;


export const limitAStringByLength = (content: string | undefined, limit: number) => {
	let restrictedContent = '';

	if (content) {
		if (content?.length >= limit) {
			restrictedContent = `${content.substring(0, limit)}...`;
		} else {
			restrictedContent = content;
		}
	}

	return restrictedContent;
};

export function transformStateEventsToSchoolEventTimeline(
	events: iSchoolEventDuct[],
): iSchoolTimelineEvent[] {
	const timelineEvents: iSchoolTimelineEvent[] = [];

	if (events?.length > 0) {
		events.forEach(event => {
			const {
				type,
				uuid,
				title,
				schools,
				source = '',
				description,
				datePublicized,

			} = event;

			const eventTimelineType = type as TimelineEventType;
			const timelineEvent: iSchoolTimelineEvent = {
				id: uuid,
				source,
			};

			if (eventTimelineType) {
				timelineEvent.separator = {
					dot: {
						type: eventTimelineType,
						color: TextColors.primary,
						variant: getDotFillTypeBasedOnTimelineType(eventTimelineType || TimelineEventType.cbcNews),
					},
				};
			}

			if (datePublicized) {
				timelineEvent.opposite = {
					variant: TextVariants.h3,
					color: TextColors.secondary,
					content: (
						<>
							<Typography fontWeight={'bold'} variant={TextVariants.subtitle1}>
								{
									schools?.map(
										(school, schoolIndex) => school.name + 
											`${schoolIndex < (schools.length - 1) ? ', ' : ''}`
									)
								}
							</Typography>
							<Typography variant={TextVariants.subtitle2}>
								{ moment(datePublicized).format('MMM Do, YYYY - h:ma') }
							</Typography>
						</>
					),
				};
			}

			timelineEvent.content = (
				<>
					<Typography fontWeight={'bold'} variant={TextVariants.subtitle1}>
						{ limitAStringByLength(title, MAX_TITLE_CHARS) }
					</Typography>

					<Typography variant={TextVariants.subtitle2}>
						{ limitAStringByLength(description, MAX_CONTENT_CHARS) }
					</Typography>
				</>
			);

			timelineEvents.push(timelineEvent);
		});
	}

	return timelineEvents;
}

export function getDotFillTypeBasedOnTimelineType(type: TimelineEventType): 'outlined' | 'filled' {
	let fillType: 'outlined' | 'filled' = 'filled';

	if (type && (type === TimelineEventType.cbcNews || type === TimelineEventType.ctvNews)) {
		fillType = 'outlined';
	}

	return fillType;
}

export function filterEvents(events: iSchoolEventDuct[], filters: TimelineFilter[]): iSchoolEventDuct[] {
	let filteredEvents: iSchoolEventDuct[] = [];

	if (events.length > 0 && filters.length > 0) {
		filteredEvents = filter(events, filters, checkEventDataBasedOnFilter);
	}

	return filteredEvents;
}

export function checkEventDataBasedOnFilter(
	event: iSchoolEventDuct, 
	timelineFilter: TimelineFilter
): iSchoolEventDuct | null {
	let foundEvent: iSchoolEventDuct | null = null;

	switch (timelineFilter.filter) {
		// School-based Filters
		case TimelineFilters.bySchool:
			if (event?.schoolUuids?.some(uuid => timelineFilter.keyword === uuid)) {
				foundEvent = event;
			}	
			break;

		// Event Type Filters
		case TimelineEventTypeFilters.news:
			if (event.type === TimelineEventType.cbcNews || 
				event.type === TimelineEventType.ctvNews || 
				event.type === TimelineEventType.schoolNews) {

				foundEvent = event;
			}
			break;

		case TimelineEventTypeFilters.socialMedia:
			if (event.type === TimelineEventType.facebook || event.type === TimelineEventType.twitter) {
				foundEvent = event;
			}
			break;
			
		case TimelineEventTypeFilters.police:
			if (event.type === TimelineEventType.policeInvolved) {
				foundEvent = event;
			}
			break;
			
		case TimelineEventTypeFilters.public:
			if (event.type === TimelineEventType.publicOrganized) {
				foundEvent = event;
			}
			break;
			
		case TimelineEventTypeFilters.schoolOfficial:
			if (event.type === TimelineEventType.schoolNews || event.type === TimelineEventType.schoolOfficial) {
				foundEvent = event;
			}
			break;

		// Time-based Filters
		case TimelineTimeTypeFilters.today:
			const today = moment().add(12, 'hours');

			if (event.datePublicized?.isSame(today, 'day')) {
				foundEvent = event;
			}
			break;
			

		case TimelineTimeTypeFilters.oneWeek:
			const thisWeek = moment();

			if (event.datePublicized && thisWeek.diff(event.datePublicized, 'weeks') <= 1) {
				foundEvent = event;
			}
			break;
			

		case TimelineTimeTypeFilters.oneYear:
			const thisYear = moment();

			if (event.datePublicized && thisYear.diff(event.datePublicized, 'years') <= 1) {
				foundEvent = event;
			}
			break;
	
		default:
			break;
	}

	return foundEvent;
}