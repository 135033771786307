/**
 * 
 *  ~ School Event Modelling
 * 
*/

import moment from 'moment';

import SchoolEvent, { iSchoolEvent } from '@data/Entities/Events/schoolEvent';

import { SchoolEventType } from '@data/Shared/Enums/events/schoolEvent';
import { iSchoolEventStoreDTO } from '@domain/Interfaces/AppState/schoolEvent/dto';

import {
	iModelling, 
	iCollectionModelling, 
	iCollectionStructuring, 
} from '@domain/Interfaces/modelling';

import { 
	iRemoteSchoolEventDTO,
	iRemoteSchoolEventsDTO,
	iRemoteSchoolEventsDTOCollection,
} from '@domain/Interfaces/remoteDTOs/schoolEvent';


/**
 * Model School Events from Remote DTOs to Models and back
 */
export const RemoteSchoolEventModelling: iModelling<iRemoteSchoolEventDTO, iSchoolEvent> = {
	toDTO: (schoolEvent: iSchoolEvent) => {
		const { id } = schoolEvent;

		return {
			uuid: id,
			type: schoolEvent.type,
			title: schoolEvent.title,
			source: schoolEvent.source,
			author: schoolEvent.author,
			thumbnail: schoolEvent.author,
			references: schoolEvent.references,
			schoolUuids: schoolEvent.schoolUuids,
			description: schoolEvent.description,
			created: schoolEvent.created.toISOString(),
			updated: schoolEvent.updated.toISOString(),
			datePublicized: schoolEvent.datePublicized.toISOString(),
		};
	},
	toModel: (schoolEventDTO: iRemoteSchoolEventDTO) => {
		const {
			uuid,
			type,
			created,
			updated,
			datePublicized,
			...remainingDTO

		} = schoolEventDTO;

		const transformedType = type as SchoolEventType;
		const transformedCreated = moment(created);
		const transformedUpdated = moment(updated);
		const transformedPublicationDate = moment(datePublicized);

		return new SchoolEvent({
			...remainingDTO,
			id: uuid,
			type: transformedType,
			created: transformedCreated,
			updated: transformedUpdated,
			datePublicized: transformedPublicationDate,
		});
	},
};


/**
 * Model School Events from Remote DTO Arrays to Model Collections and back
 */
export const RemoteSchoolEventCollectionModelling: iCollectionModelling<iRemoteSchoolEventDTO, iSchoolEvent> = {
	toCollection: (schoolEvents: iSchoolEvent[]): iRemoteSchoolEventsDTOCollection => {
		const collection: iRemoteSchoolEventsDTOCollection = {
			data: {},
			order: [],
		};
		
		for(const event of schoolEvents) {
			collection.order.push(event.id);
			collection.data[event.id] = RemoteSchoolEventModelling.toDTO(event);
		}

		return collection;
	},
	fromCollection: (schoolEventsDTOCollection: iRemoteSchoolEventsDTOCollection): iSchoolEvent[] => {
		const schools: iSchoolEvent[] = [];
		
		const {
			data,
			order,
		} = schoolEventsDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools.push(
					RemoteSchoolEventModelling.toModel(
						data[uuid]
					)
				)
			}
		}

		return schools;
	},
};


/**
 * Model School Events from Remote DTO Arrays to Model Collections and back
 */
export const RemoteSchoolEventCollectionStructure: iCollectionStructuring<iRemoteSchoolEventDTO> = {
	toCollection: (schoolEvents: iRemoteSchoolEventsDTO): iRemoteSchoolEventsDTOCollection => {
		const collection: iRemoteSchoolEventsDTOCollection = {
			data: {},
			order: [],
		};
		
		for(const [uuid, event] of Object.entries(schoolEvents)) {
			collection.order.push(uuid);
			collection.data[uuid] = {
				...event,
			};
		}

		return collection;
	},
	fromCollection: (schoolEventsDTOCollection: iRemoteSchoolEventsDTOCollection): iRemoteSchoolEventsDTO => {
		const schools: iRemoteSchoolEventsDTO = {};
		
		const {
			data,
			order,
		} = schoolEventsDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools[uuid] = {
					...data[uuid],
					uuid,
				};
			}
		}

		return schools;
	},
};


/**
 * Model School Events from Store DTOs to Models and back
 */
export const SchoolEventStoreModelling: iModelling<iSchoolEventStoreDTO, iSchoolEvent> = {
	toDTO: (schoolEvent: iSchoolEvent) => {
		const { id } = schoolEvent;

		return {
			uuid: id,
			type: schoolEvent.type,
			title: schoolEvent.title,
			source: schoolEvent.source,
			author: schoolEvent.author,
			thumbnail: schoolEvent.author,
			references: schoolEvent.references,
			schoolUuids: schoolEvent.schoolUuids,
			description: schoolEvent.description,
			created: schoolEvent.created.toISOString(),
			updated: schoolEvent.updated.toISOString(),
			datePublicized: schoolEvent.datePublicized.toISOString(),
		};
	},
	toModel: (schoolEventDTO: iSchoolEventStoreDTO) => {
		const {
			uuid,
			type,
			title,
			author,
			source,
			created,
			updated,
			thumbnail,
			references,
			schoolUuids,
			description,
			datePublicized,
			...remainingDTO

		} = schoolEventDTO;

		const transformedType = type as SchoolEventType;
		const transformedCreated = moment(created);
		const transformedUpdated = moment(updated);
		const transformedPublicationDate = moment(datePublicized);

		return new SchoolEvent({
			...remainingDTO,
			id: uuid,
			title: title || '',
			source: source || '',
			author: author || '',
			type: transformedType,
			thumbnail: thumbnail || '',
			created: transformedCreated,
			updated: transformedUpdated,
			references: references || {},
			schoolUuids: schoolUuids || [],
			description: description || '',
			datePublicized: transformedPublicationDate,
		});
	},
};