/**
 * 
 *  School Factories
 * 
 */

import SchoolsInteractor from '@domain/UseCases/schools';
import SchoolStoreAdapter from '@ducts/Adapters/ApplicationState/school';
import SchoolRemoteStoreAdapter from '@ducts/Adapters/RemoteStore/school';

import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { CollectionNames } from '../../Domain/Types/Collection';
import { iSchoolInteractor } from '@domain/Interfaces/Interactor/iSchoolsInteractor';
import { SchoolStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/school';
import { createRemoteStoreProvider } from '../Providers/RemoteStore/Services/Firebase/RealtimeDB';

/**
 * Createa a new Schools Interactor class and injects dependencies
 * @returns 
 */
export function createSchoolInteractor(): iSchoolInteractor {
	// Build remote store for schools, then build its provider
	const RemoteStore = createRemoteStoreProvider(CollectionNames.schools);
	const SchoolsStoreProvider: iRemoteStore = new RemoteStore();
	// Give the created store to the adapter
	const remoteSchoolStoreAdapter = new SchoolRemoteStoreAdapter(SchoolsStoreProvider);

	// Build store adapter for schools application state
	const schoolStore = new SchoolStoreAdapter(SchoolStoreProvider);
	
	// Build school interactor with providers
	return new SchoolsInteractor(
		remoteSchoolStoreAdapter,
		schoolStore
	);
}