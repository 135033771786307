/**
 * 
 *  ~ Realtime DB - Remote Store Provider Service 
 *      Use Case Mixins
 * 
 */

import camelcaseKeys from 'camelcase-keys';
import realtimeDBBase, { DataSnapshot } from 'firebase/database';
import iRemoteStoreProvider from '../Interfaces/iRemoteStoreProvider';

import { log } from '@logging/Console';
import { realtimeDB } from '../../firebase';
import { CollectionNames } from '../../../../Domain/Types/Collection';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';


type Constructor<T> = new (...args: any[]) => T;


/**
 * This interface satisfies a small portion of the Domain Layer's 
 * total required iRemoteStore Interface
 */
export interface iRemoteStoreAbilities extends iRemoteStore, iRemoteStoreProvider<typeof realtimeDBBase> {}

/**
 * 
 * @param base 
 * @returns 
 */
export function addBaseActions<baseType>(
	collectionKey: CollectionNames,
	base: baseType & Constructor<iRemoteStoreProvider<typeof realtimeDBBase>>
    
): baseType & Constructor<iRemoteStoreAbilities> & Constructor<iRemoteStoreProvider<typeof realtimeDBBase>> {

	return class extends base implements iRemoteStoreAbilities {


		/**
         * Adds a Base Item to remote store
         * @param dto
         */
		async add<iDTO extends object>(dto: iDTO): Promise<string> {
			try {
				let id = '';

				const response = await this.remoteStoreProvider.push(
					this.remoteStoreProvider.ref(realtimeDB, collectionKey),
					{
						...dto 
					}
				);

				if (response?.key) {
					id = response.key;
				}

				return id;
    
			} catch (error) {
				log('error', `error in adding a base item! error: `, error);

				return '';
			}
		}
        

		/**
         * Sets the Base Item manually
         * @param dto 
         * @returns 
         */
		async set<iDTO extends object>(id: string, dto: iDTO): Promise<void> {
			try {
				await this.remoteStoreProvider.set(
					await this.remoteStoreProvider.ref(
						realtimeDB, `${collectionKey}/${id}`
					),
					{
						...dto 
					}
				);
                
			} catch (error) {
				log('error', `error in setting a base item! error: `, error);
			}
		}


		/**
         * 
         * @param id 
         * @param dto 
         */
		async update<iDTO extends object>(id: string, dto: iDTO): Promise<void> {
			try {
				await this.remoteStoreProvider.update(
					await this.remoteStoreProvider.ref(
						realtimeDB,
						`${collectionKey}/${id}`
					),
					{
						...dto 
					}
				);

			} catch (error) {
				// @todo: create proper Error class for this with tests
				// throw new Error('Update User profile failed');
				log('error', `error in updating a base item! error: `, error);
			}
		}


		/**
         * Get a Base Item by id
         * @param id 
         * @returns 
         */
		async get<iDTO extends object>(id: string): Promise<iDTO> {
			try {
				const response = await this.remoteStoreProvider.get(
					await this.remoteStoreProvider.ref(
						realtimeDB, `${collectionKey}/${id}`
					)
				);

				let dto = {} as iDTO;

				if (response.exists()) {
					dto = {
						...dto,
						...response.val(),
					};
				}

				return dto;
                
			} catch (error) {
				log('error', `error in getting a base item! error: `, error);
				return {} as iDTO;
			}
		}
        

		/**
         * Listen for changes to id key and invoke callback on detected changes
         * @param callback 
         */
		async subscribeTo<iDTO extends object>(path = '', callback: (dto: iDTO | null) => object): Promise<() => void> {
			try {
				return await this.remoteStoreProvider.onValue(
					this.remoteStoreProvider.ref(realtimeDB, path),
					this.handleItemOnUpdate(callback)
				);
    
			} catch (error) {
				log('error', `error in subscribing to a base item! error: `, error);
				return () => undefined;
			}
		}


		/**
         * 
         * @param updateCallback 
         * @returns 
         */
		handleItemOnUpdate = <iDTO>(updateCallback: (dto: iDTO | null) => object) => 
			async (snapshot: DataSnapshot): Promise<void> => {
				let newDTO = {} as iDTO;

				if (snapshot && snapshot.exists()) {
					const response: { [id: string]: any } = snapshot.val();

					if (response) {
						const normalizedValues: { [id: string]: any } = {};

						for (const [key, value] of Object.entries(response)) {
							normalizedValues[key] = camelcaseKeys(value, {
								deep: true,
								preserveConsecutiveUppercase: true,
							});
						}
						newDTO = {
							...newDTO,
							...normalizedValues,
						};
						updateCallback(newDTO);
                
					} else {
						// @todo: Needs to be a proper error class
						throw new Error('handleOnUpdate function: no response');
					}
				
				} else {
					const response = snapshot.val();

					// Items removed or gone
					if (response === null) {
						updateCallback(null);
					}
				}
			}
	};
}