/**
 * 
 *  ~ Notification(s) Use Case Functions
 * 
 */

import { log } from '@logging/Console';
import { NotificationModelling } from '@domain/Modelling/notification';
import { iNotification } from '@data/Entities/Notifications/notification';
import { iNotificationInteractor } from '@domain/Interfaces/Interactor/iNotificationInteractor';
import { iNotificationStoreProvider } from '@domain/Interfaces/AppState/notification/store';


export default class NotificationInteractor implements iNotificationInteractor {

	constructor(
		private _notificationStore: iNotificationStoreProvider,
	) {}
	
	/**
     * 
     * @param notification 
     */
	async notifyApplication(notification: iNotification): Promise<void> {
		try {
			this._notificationStore.actions.showMessage(
				NotificationModelling.toDTO(notification)
			);
            
		} catch (error) {
			log('error', `Could not notify application's notification center, error: `, error);
		}
	}
    

	/**
     * Removes the last notification from stored messagess
     */
	async removeLastNotificationFromMessages(): Promise<void> {
		try {
			this._notificationStore.actions.removeFirstMessageInStack();
            
		} catch (error) {
			log('error', `Could not remove last notification, error: `, error);
		}
	}

}