/**
 * 
 * 	~ Notification Store Provider
 * 
 */


// import { dispatchStoreSelector } from '../StateSelectors/createSelector';
import { NotificationCenterActions } from '@ducts/Providers/ApplicationState/Actions';
import { iNotificationStoreProvider } from '@domain/Interfaces/AppState/notification/store';


export const NotificationStoreProvider: iNotificationStoreProvider = {
	actions: NotificationCenterActions,
	selectors: {
		test() {
			//
		},
	},
};