/**
 * 
 *  ~ Pipeline Use Case Functions
 * 
 */

import { log } from '@logging/Console';
import { iPipeline } from '@data/Entities/Piipeline/pipeline';
import { CollectionKeys, CollectionNames } from '../Types/Collection';
import { iPipelineStoreAdapter } from '@ducts/Adapters/ApplicationState/pipeline';
import { iPipelineRemoteStoreAdapter } from '@ducts/Adapters/RemoteStore/pipeline';
import { iPipelineInteractor } from '@domain/Interfaces/Interactor/iPipelineInteractor';


export default class PipelineInteractor implements iPipelineInteractor {

	private pipelineListener: Promise<() => void> | null = null;

	constructor(
        private _RemoteStoreProvider: iPipelineRemoteStoreAdapter,
		private _PipelineStore: iPipelineStoreAdapter
	) {}

	/**
     * Subscribed to remote store updates and passes a handler to deal with each 
     * update event
     */
	async listenForPipelineUpdates(): Promise<void> {
    	try {
			this._PipelineStore.actions.isFetchingPipelines(true);

    		this.pipelineListener = this._RemoteStoreProvider.subscribeTo(
    			CollectionKeys[CollectionNames.pipeline], 
    			this.handlePipelineUpdate
    		);

    	} catch (error) {
			this._PipelineStore.actions.isFetchingPipelines(false);
    		log('error', 'Something went wrong with pipeline listener, error given: ', error);
    	}
	}


	private handlePipelineUpdate = async (pipelineUpdate: iPipeline | null): Promise<void> => {
		this._PipelineStore.actions.isFetchingPipelines(false);

		if (pipelineUpdate === null) {
			this._PipelineStore.actions.resetAll();

		} else {
			this._PipelineStore.actions.updatePipeline(
				pipelineUpdate
			);
		}
	};

}