/*
~ Single School Component
*/

import Navbar from '@molecules/Navbar';
import SectionTitle from '@atoms/_sectionTitle';
import TestLogo from '@images/millwood_logo.jpg';
import CompactTimeline from '@cells/CompactTimeline';

import Styles, { stylesType } from './styles';

import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import { getClassesType } from '@interfaces/tssReact';
import { useNavigate, useParams } from 'react-router-dom';
import { mainNavMenuItems } from '@settings/mainNavOptions';
import { getSchoolDuct } from '@ducts/DuctSelectors/school';
import { Map, APIProvider as MapsProvider } from '@vis.gl/react-google-maps';

import {
	Grid, 
	Paper, 
	Typography,
 
} from '@mui/material';


interface iSingleSchoolProps extends getClassesType<stylesType> {}

const SingleSchool = (props: iSingleSchoolProps) => {
	const [coordinates, setCoordinates] = useState({
		lat: 44.651070,
		long: -63.582687,
	});
	const navigate = useNavigate();
	const classes = withStyles.getClasses<stylesType>(props);
	const { schoolId } = useParams();

	if (schoolId) {
		const school = useSelector(getSchoolDuct(schoolId));
		console.log(`school: `, school);

		useEffect(() => {
			if (school) {
				if (coordinates.lat !== school?.geoLocation?.lat || 
					coordinates.long !== school?.geoLocation.long) {

					setCoordinates({
						lat: school?.geoLocation?.lat || 0,
						long: school?.geoLocation?.long || 0,
					});
				}
			}
			
		}, [school]);
		
		return (
			<div className={classes.container}>
				<Navbar 
					menuItems={
						mainNavMenuItems({
							signupButtonCallback: () => {
								navigate(Paths.allSchools);
							}
						})
					} />

				<div className={classes.contentBody}>
					<div className={classes.centered}>
						<SectionTitle 
							isFullWidth 
							containerClass={classes.titleContainer}
							titleContent={ school?.name || 'School Information' } />

						<Grid container columnSpacing={5}>
							<Grid item md={6} xs={12}>
								{
									school && (
										<div
											className={classes.schoolImage}
											style={{
												backgroundImage: `url(${TestLogo})`,
											}} />
									)
								}
							</Grid>
							
							<Grid item md={6} xs={12}>
								<MapsProvider apiKey='AIzaSyDq3Lm1nFgjkY8hxF_oXvWu_n-gyOx0x38'>
									<Map
										style={{
											width: '100%',
											height: '50vh',
										}}
										center={{
											lat: coordinates.lat,
											lng: coordinates.long,
										}}
										zoom={16}
										disableDefaultUI={true}
										gestureHandling={'greedy'}
										controlled={true}
									/>
								</MapsProvider>
							</Grid>
						</Grid>
						
						<Grid 
							container 
							columnSpacing={5}
							sx={{
								mt: 15,
							}}>
							<Grid item xs={12} md={6}>
								<Paper sx={{
									p:5,
									minHeight: 275,
								}}>
									<Typography
										variant='h5'
										sx={{
											my: 2.5,
											textDecoration: 'underline' 
										}}>

										Contact Information
									</Typography>

									{
										school?.phone && (
											<Typography variant='body1' className={classes.detail}>
												<strong>Phone:</strong> { school?.phone }
											</Typography>
										)
									}
									{
										school?.fax && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Fax:</strong> { school?.fax }
											</Typography>
										)
									}
									{
										school?.email && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Email:</strong> { school?.email }
											</Typography>
										)
									}
									{
										school?.city && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>City:</strong> { school?.city }
											</Typography>
										)
									}
									{
										school?.civicAddress && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Civic Address:</strong> { school?.civicAddress }
											</Typography>
										)
									}
									{
										school?.mailingAddress && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Mailing Address:</strong> { school?.mailingAddress }
											</Typography>
										)
									}
									{
										school?.mailingAddress && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Postal Code:</strong> { school?.postalCode }
											</Typography>
										)
									}
								</Paper>
							</Grid>

							<Grid item xs={12} md={6}>
								<Paper sx={{
									p:5,
									minHeight: 275,
								}}>
									<Typography 
										variant='h5'
										sx={{
											my: 2.5,
											textDecoration: 'underline' 
										}}>

										Faculty
									</Typography>

									{
										school?.principal && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Principal Name:</strong> { school?.principal }
											</Typography>
										)
									}
									{
										school?.principalEmail && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Principal Email:</strong> { school?.principalEmail }
											</Typography>
										)
									}
									{
										school?.vicePrincipal && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Vice Principal Name:</strong> { school?.vicePrincipal }
											</Typography>
										)
									}
									{
										school?.vicePrincipalEmail && (
											<Typography 
												variant='body1' 
												classes={{
													root: classes.detail 
												}}>

												<strong>Vice Principal Email:</strong> { school?.vicePrincipalEmail }
											</Typography>
										)
									}
								</Paper>
							</Grid>
						</Grid>

						<Grid 
							container 
							columnSpacing={5}
							sx={{
								mt: 15,
								pb: 25,
							}}>
							<Grid item xs={12} md={6}>
								<Paper sx={{
									p: 5,
									minHeight: 275,
								}}>
									<Typography
										variant='h5'
										sx={{
											my: 2.5,
											textDecoration: 'underline' 
										}}>

										Regional Information
									</Typography>

									{
										school?.rceName && (
											<Typography variant='body1' className={classes.detail}>
												<strong>RCE Name:</strong> { school?.rceName }
											</Typography>
										)
									}
									{
										school?.rceCsapCode && (
											<Typography variant='body1' className={classes.detail}>
												<strong>RCE CSAP Code:</strong> { school?.rceCsapCode }
											</Typography>
										)
									}
									{
										school?.schoolFamily && (
											<Typography variant='body1' className={classes.detail}>
												<strong>School Family:</strong> { school?.schoolFamily }
											</Typography>
										)
									}
									{
										school?.slc && (
											<Typography variant='body1' className={classes.detail}>
												<strong>SLC:</strong> { school?.slc }
											</Typography>
										)
									}
								</Paper>
							</Grid>
						</Grid>

						<CompactTimeline schoolUUID={schoolId} />
					</div>
				</div>
			</div>
		);
		
	} else {
		// Show that school doesn't exist and to go back
		return null;
	}
};


export default withStyles(SingleSchool, Styles);