/**
 * 
 * 	School Event Duct Modelling
 * 
 */

import moment from 'moment';

import { iDuctModelling } from '@ducts/Interfaces/ducts';
import { iSchoolEventDuct } from '@ducts/Interfaces/schoolEvents';
import { iCollectionModelling } from '@domain/Interfaces/modelling';
import { iSchoolEventStoreCollectionDTO, iSchoolEventStoreDTO } from '@domain/Interfaces/AppState/schoolEvent/dto';


/**
 * Model a School Event from a DTO to Model and back
 */
export const SchoolEventStoreDuctModelling: iDuctModelling<iSchoolEventDuct, iSchoolEventStoreDTO> = {
	toDuct: (schoolEventDTO: iSchoolEventStoreDTO) => {
		let schoolEventDuct: iSchoolEventDuct = {
			uuid: '', 
			type: '', 
		};

		const {
			updated,
			created,
			datePublicized,
			...remainingEventValues

		} = schoolEventDTO;

		schoolEventDuct = {
			...remainingEventValues,
			updated: moment(updated),
			created: moment(created),
			datePublicized: moment(datePublicized),
		};

		return schoolEventDuct;
	},
	toStoreDTO: (schoolEventDuct: iSchoolEventDuct) => {
		let schoolEventDTO: iSchoolEventStoreDTO = {
			uuid: '',
			type: '',
		};

		const {
			updated,
			created,
			datePublicized,
			...remainingEventValues

		} = schoolEventDuct;

		schoolEventDTO = {
			...remainingEventValues,
			updated: moment(updated).toISOString(),
			created: moment(created).toISOString(),
			datePublicized: moment(datePublicized).toISOString(),
		};

		return schoolEventDTO;
	},
};


/**
 * Model School Events from DTO Arrays to Model Collections and back
 */
export const SchoolEventStoreDuctCollectionModelling: iCollectionModelling<
	iSchoolEventStoreDTO, 
	iSchoolEventDuct
> = {
	toCollection: (schools: iSchoolEventDuct[]): iSchoolEventStoreCollectionDTO => {
		const collection: iSchoolEventStoreCollectionDTO = {
			data: {},
			order: [],
		};
		
		for(const school of schools) {
			collection.order.push(school.uuid);
			collection.data[school.uuid] = SchoolEventStoreDuctModelling.toStoreDTO(school);
		}

		return collection;
	},
	fromCollection: (schoolDTOCollection: iSchoolEventStoreCollectionDTO): iSchoolEventDuct[] => {
		const schools: iSchoolEventDuct[] = [];
		
		const {
			data,
			order,
		} = schoolDTOCollection;

		for(const uuid of order) {
			if (Object.hasOwn(data, uuid)) {
				schools.push(
					SchoolEventStoreDuctModelling.toDuct(
						data[uuid]
					)
				)
			}
		}

		return schools;
	},
};