/**
 * 
 *  ~ Firebase Authentication Provider
 * 
 */

import * as firebaseAuth from 'firebase/auth';
import iAuthProvider from '../../Interfaces/iAuthProvider';

import { addUserAccountActions } from '../../UseCaseMixins/authentication';


class FirebaseAuthProvider implements iAuthProvider {
	public authProvider = firebaseAuth;
}

// Add Actions To Auth Provider
const AuthProviderUser = addUserAccountActions(
	FirebaseAuthProvider
);

export default AuthProviderUser;