/*
	~ Login styles
*/


import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {
		flex: 1,
		width: '100%',
		display: 'flex',
		minHeight: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: theme.palette.primary.contrastText,
	} as CSSObject,

	formContainer: {} as CSSObject,

	centeringContainer: {
		flex: 1,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	loginBox: {
		width: '35%',
		minWidth: 350,
		minHeight: 300,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	} as CSSObject,
	
	noProfileButton: {
		height: 50,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	} as CSSObject,
	noProfileIcon: {
		fontSize: 20,
		marginLeft: 20,
	} as CSSObject,

	loginFieldContainer: {
		width: '90%',
		paddingTop: 30,
		display: 'flex',
		flexDirection: 'column',
	} as CSSObject,

	username: {
		textAlign: 'left', 
	} as CSSObject,
	password: {
		textAlign: 'left', 
	} as CSSObject,
	usernameFormControl: {
		marginBottom: 25, 
	} as CSSObject,

	forgotPasswordButton: {
		fontStyle: 'italic',
		textTransform: 'none',

	} as CSSObject,

	buttonFooter: {
		width: '100%',
		paddingTop: 20,
		display: 'flex',
		paddingBottom: 25,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	} as CSSObject,

	buildDetails: {
		fontSize: 10,
		width: '100%',
		display: 'flex',
		padding: '0 35px',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		backgroundColor: 'transparent',

		left: 0,
		bottom: 0,
		position: 'absolute',

		'@media only screen and (min-width: 500px)': {
			fontSize: 14, 
		},

	} as CSSObject,
	buildDetailDivider: {
		marginLeft: 10,
		marginRight: 10,
	} as CSSObject,

	footerDivider: {
		width: '100%', 
	} as CSSObject,
	registrationText: {
		marginTop: '0.35em',
		marginBottom: '0.35em',
	} as CSSObject,
	registerButton: {
		fontStyle: 'italic',
		textTransform: 'capitalize',
		color: theme.palette.primary.light,
	} as CSSObject,
});


export type stylesType = ReturnType<typeof Styles>;


export default Styles;