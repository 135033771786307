/*
	~ Schools Table Component
*/

import { Paths } from '@settings/routes';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { Theme } from '@mui/system/createTheme';
import { iSchoolUI } from '@interfaces/schools';
import { getClassesType } from '@interfaces/tssReact';

import {
	KeyboardArrowUpSharp,
	KeyboardArrowDownSharp,
	
} from '@mui/icons-material';

import {
	Paper,
	Table,
	TableRow,
	Collapse,
	TableHead,
	TableBody,
	TableCell,
	Typography,
	IconButton,
	TableContainer,
	
} from '@mui/material';

import {
	useState,
	ReactNode,
	MouseEvent,
	ComponentType,
	PropsWithChildren,
	useCallback,

} from 'react';
import { UUID } from '@ducts/Types/generic';



const Styles = (theme: Theme) => ({
	container: {} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iSchoolsTableProps extends getClassesType<stylesType> {
	schools: iSchoolUI[],
	highlightOnHover?: boolean,
	onItemClick?: (uuid: UUID) => void,
	ItemContainer?: ComponentType<PropsWithChildren> | null,
}

const SchoolsTable = (props: iSchoolsTableProps) => {
	const {
		schools,
		onItemClick = null,
		ItemContainer = null,

	} = props;

	const navigate = useNavigate();
	const [isInformationOpen, setIsInformationOpen] = useState({} as { [id: string]: boolean });

	/**
	 * Handles user interaciton with table rows and if the component 
	 * is handed a callback, it will use that instead of navigating 
	 * to the school by UUID
	 */
	const handleTableRowOnClick = useCallback((uuid: string) => () => {
		if (onItemClick) {
			onItemClick(uuid);

		} else {
			navigate(`${Paths.cpSingleSchool}/${uuid}`)
		}

	}, []);

	// const classes = withStyles.getClasses<stylesType>(props);


	const renderTableRows = () => {
		const rows: ReactNode[] = [];

		if (schools?.length > 0) {
			for (const [schoolIndex, school] of schools.entries()) {
				const { uuid } = school;

				rows.push(
					<>
						<TableRow
							key={`table_row_${schoolIndex}`}
							onClick={handleTableRowOnClick(uuid)}
							sx={{
								cursor: 'pointer',
								'& > *': {
									borderBottom: 'unset'
								}
							}}>
							<TableCell component='th' scope='row'>
								{ school.name }
							</TableCell>
							<TableCell align='right'>{school.city}</TableCell>
							<TableCell align='right'>{school.civicAddress}</TableCell>
							<TableCell align='right'>
								<a href={school.website || ''}>
									Offical Website
								</a>
							</TableCell>
							<TableCell>
								<IconButton
									aria-label='expand row'
									size='small'
									onClick={
										(event: MouseEvent<HTMLButtonElement>) => {
											event.stopPropagation();
											event.preventDefault();

											setIsInformationOpen({
												...isInformationOpen,
												[uuid]: !isInformationOpen[uuid],
											})
										}
									}>
									{
										isInformationOpen[uuid] ? <KeyboardArrowUpSharp /> : <KeyboardArrowDownSharp />
									}
								</IconButton>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell colSpan={6} sx={{
								pb: 0,
								pt: 0,
							}}>
								<Collapse
									unmountOnExit
									timeout='auto'
									in={isInformationOpen[uuid]}
									sx={{
										py: 6,
										pl: 12,
										pr: 6,
									}}>

									<Typography 
										variant='body2' 
										component='h6' 
										sx={{
											fontWeight: 900, 
										}}>

											School Information
									</Typography>
		
									<Table size='small' aria-label='purchases'>
										<TableHead>
											<TableRow>
												<TableCell>Email</TableCell>
												<TableCell align='right'>Phone</TableCell>
												<TableCell align='right'>Principal</TableCell>
												<TableCell align='right'>Vice Principal</TableCell>
												<TableCell align='right'>Postal Code</TableCell>
												<TableCell align='right'>Feeder Schools</TableCell>
											</TableRow>
										</TableHead>
		
										<TableBody>
											<TableRow>
												<TableCell component='th' scope='row'>
													{ school.email }
												</TableCell>

												<TableCell align='right'>
													{ school.phone }
												</TableCell>

												<TableCell align='right'>
													{ school.principal }
												</TableCell>

												<TableCell align='right'>
													{ school.vicePrincipal }
												</TableCell>
												
												<TableCell align='right'>
													{ school.postalCode }
												</TableCell>
												
												<TableCell align='right'>
													{
														school.feederSchools?.map(
															(f, fIndex) => fIndex !== school.feederSchools?.length ? 
																`${f}, ` : 
																f
														)
													}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Collapse>
							</TableCell>
						</TableRow>
					</>
				);
			}
		}

		return (
			<TableBody>
				{ rows }
			</TableBody>
		);
	};

	const renderTable = () => {
		return (
			<Table aria-label={'collapsible table'}>
				<TableHead>
					<TableRow>
						<TableCell>School Name</TableCell>
						<TableCell align='right'>City</TableCell>
						<TableCell align='right'>Address</TableCell>
						<TableCell align='right'>Website</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>

				{ renderTableRows() }
			</Table>
		);
	};

	return (
		<TableContainer component={Paper}>
			{
				ItemContainer === null ? 
					renderTable() : (
						<ItemContainer>
							{ renderTable() }
						</ItemContainer>
					) 
			}
		</TableContainer>
	);
};


export default withStyles(SchoolsTable, Styles);