/*
	~ Register Styles
*/

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {
		flex: 1,
		width: '100%',
		display: 'flex',
		minHeight: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: theme.palette.primary.contrastText,
	} as CSSObject,

	centeringContainer: {
		flex: 1,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,

	registerBox: {
		width: '35%',
		minWidth: 350,
		minHeight: 300,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	} as CSSObject,

	createProfileForm: {
		width: '90%',
		paddingTop: 20,
		paddingBottom: 20,
	} as CSSObject,

	registerTitleContainer: {
		width: '100%',
		paddingTop: 35,
		marginBottom: 25,
		paddingBottom: 35,

		background: theme.palette.primary.dark,
		
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderColor: theme.palette.action.disabled,
	} as CSSObject,
	registerTitle: {
		textTransform: 'uppercase',
		margin: '0px 35px 0px 35px',
		color: theme.palette.primary.contrastText,
	} as CSSObject,

	stepContainer: {
		width: '100%',
		paddingLeft: 20,
		paddingRight: 20,
	} as CSSObject,
	stepLabel: {
		color: 'red',
	} as CSSObject,

	profileButtonContainer: {
		padding: 20,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	} as CSSObject,
	profileButtonHeader: {
		marginTop: 10,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	profileMenuButton: {
		width: 85,
		height: 85,
	} as CSSObject,
	profileButtonInput: {
		flex: 1,
		width: '100%',
		display: 'flex',
	} as CSSObject,

	createProfileFormFooter: {
		width: '100%',
		marginTop: 15,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	} as CSSObject,
});


export type stylesType = ReturnType<typeof Styles>;

export default Styles;