/**
 * 
 * 	~ State Selectors - App
 * 
 */

import { RootStoreState } from '../State';
import { iAppState } from '@domain/Interfaces/AppState/app/store';


/**
 * Get Application Status which is related to anything/everything 
 * going on in the application right now
 * 
 * @param modellingCallback 
 * @returns 
 */
export const applicationStatus = <isAppInitializedState>(
	modellingCallback: (status: iAppState) => isAppInitializedState
) => ({ app }: RootStoreState): isAppInitializedState => modellingCallback(app);