/**
 * 
 *  ~ Application Use Case Functions
 * 
 */

import { log } from '@logging/Console';
import { iAppStoreProvider } from '@domain/Interfaces/AppState/app/store';
import { iUserInteractor } from '@domain/Interfaces/Interactor/iUserInteractor';
import { iSchoolInteractor } from '@domain/Interfaces/Interactor/iSchoolsInteractor';
import { iAppInteractor } from '@domain/Interfaces/Interactor/iApplicationInteractor';
import { iPipelineInteractor } from '@domain/Interfaces/Interactor/iPipelineInteractor';
import { iSchoolEventsInteractor } from '@domain/Interfaces/Interactor/iSchoolEventsInteractor';


export default class ApplicationInteractor implements iAppInteractor {

	constructor(
		private _userInteractor: iUserInteractor,
		private _schoolInteractor: iSchoolInteractor,
		private _pipelineInteractor: iPipelineInteractor,
		private _schoolEventsInteractor: iSchoolEventsInteractor,
		private _applicationStore: iAppStoreProvider,
	) {}


	/**
     * INITIALIZATION OF APPLICATION
	 * [Typically used to load things...]
	 * 
	 * First official business logic function of the application launch phase.
	 * 
	 * This is meant to be run after runtime has begun, the application has 
	 * launched, the UI has loaded and all stores have been loaded and/or 
	 * rehydrated
     */
	async initialize(): Promise<void> {
		try {
			// Inform the application that initialization is now in progress
			this._applicationStore.actions.initializingApplication();
            
			/**
             * 
             *  Set up application data listeners
             * 
             */
			await this._pipelineInteractor.listenForPipelineUpdates(); // <-- Pipeline
			await this._userInteractor.listenForUserAccessUpdates(); // <-- User
			await this._schoolInteractor.listenForSchoolUpdates(); // <-- Schools
			await this._schoolEventsInteractor.listenForSchoolEventUpdates(); // <-- Events
			// ======================
            
			// Inform the application that initialization process is now completed
			this._applicationStore.actions.applicationNowInitialized();
            
		} catch (error) {
			log('error', `Could not initialize the application, error: `, error);
		}
	}

}