/**
 * 
 * 	~ Search Modelling & Transformations
 * 
 */

import { SearchFilter } from '@interfaces/filters';


export function filter<ItemData, FilterTypes>(
	items: ItemData[], 
	filters: SearchFilter<FilterTypes>[],
	itemComparisonCallback: (
		item: ItemData, 
		searchFilter: SearchFilter<FilterTypes>
	) => ItemData | null,
): ItemData[] {
	let filteredItems: ItemData[] = [];

	if (items.length > 0 && filters.length > 0) {
		filteredItems = Array.from(items);
		const aggregateFilters = Array.from(filters.filter(filter => filter.aggregate));
		const exclusiveFilters = Array.from(filters.filter(filter => !filter.aggregate));
		
		// Aggregate all items by filters first to make sure nothing is left out
		if (aggregateFilters.length > 0) {
			filteredItems = applyAggregateFiltersToSearchItems(
				filteredItems, 
				aggregateFilters, 
				itemComparisonCallback
			);
		}
		
		if (exclusiveFilters.length > 0) {
			/**
			 * Now that all inclusive items are aggregated into the array, filter 
			 * this array by the exclusive filters list, reducing it each time
			 */
			for (const filter of exclusiveFilters) {
				/**
				 * The only reason to drill down with these items in an exclusive filtration 
				 * process would be because the user wants to refine the reults anyway. Therefore 
				 * if even one check comes back false, then all items would be removed by 
				 * intention.
				 */
				if (filteredItems.length > 0) {
					filteredItems = applyExclusiveFilterToSearchItems(
						filteredItems, 
						filter,
						itemComparisonCallback
					);
	
				} else {
					/**
					 * There is no ned to continue exclusive filtration loop if there are 
					 * no items to filter
					 */
					break;
				}
			}
		}
	}

	return filteredItems;
}


/**
 * 
 * 
 * @param items 
 * @param searchFilter 
 * @returns 
 */
export function applyExclusiveFilterToSearchItems<ItemData, FilterTypes>(
	items: ItemData[], 
	searchFilter: SearchFilter<FilterTypes>,
	itemComparisonCallback: (
		item: ItemData, 
		searchFilter: SearchFilter<FilterTypes>
	) => ItemData | null
): ItemData[] {
	const filteredItems: ItemData[] = [];

	for (const item of items) {
		const foundItem = itemComparisonCallback(item, searchFilter);

		if (foundItem !== null) {
			filteredItems.push({
				...foundItem,
			});
		}
	}

	return filteredItems;
}


/**
 * This is a type of item filter that supports the concept of: 
 * 'If item has this filter, include it with the other items 
 * that have other filters'
 * @param items 
 * @param searchFilters 
 * @returns 
 */
export function applyAggregateFiltersToSearchItems<ItemData, FilterTypes>(
	items: ItemData[], 
	searchFilters: SearchFilter<FilterTypes>[],
	itemComparisonCallback: (
		item: ItemData, 
		searchFilter: SearchFilter<FilterTypes>
	) => ItemData | null
): ItemData[] {
	const filteredItems: ItemData[] = [];

	for (const item of items) {
		for (const searchFilter of searchFilters) {
			const foundItem = itemComparisonCallback(item, searchFilter);

			if (foundItem !== null) {
				filteredItems.push({
					...foundItem,
				});
			}
		}
	}

	return filteredItems;
}