/**
 * 
 * 
 * 
 */

import { filter } from '@modelling/search';
import { useEffect, useState } from 'react';
import { SearchFilter, SpecialSearchFilters } from '@interfaces/filters';


export const PAGE_SIZE = 100;

export interface iSearchFiltersHook<ItemData, FilterTypes> {
	items: ItemData[],
	onSort?: (itemA: ItemData, itemB: ItemData) => number,
	onAdd?: (searchFilters: SearchFilter<FilterTypes>[]) => void,
	onRemove?: (searchFilter: SearchFilter<FilterTypes>) => void,
	onFilter: (
		item: ItemData, 
		searchFilter: SearchFilter<FilterTypes>
	) => ItemData | null,
}

export interface iSearchFiltersHookResponse<ItemData, FilterTypes> {
	items: ItemData[],
	filters: SearchFilter<FilterTypes>[],
	addFilter: (searchFilters: SearchFilter<FilterTypes>[]) => void,
	removeFilter: (searchFilter: SearchFilter<FilterTypes>) => void,
}

export function useSearchFilters<ItemData, FilterTypes>({
	items,
	onAdd,
	onSort,
	onRemove,
	onFilter,

}: iSearchFiltersHook<ItemData, FilterTypes>): iSearchFiltersHookResponse<ItemData, FilterTypes> {
	const [loadedItems, setLoadedItems] = useState({} as ItemData[]);
	const [filters, setFilters] = useState([] as SearchFilter<FilterTypes>[]);
	
	useEffect(() => {
		let newItemsList: ItemData[] = [];
		
		if (items?.length > 0) {
			// Apply filtration... ----------------------
			if (filters?.length > 0) {
				newItemsList = filter(items, filters, onFilter);
			} else {
				newItemsList = Array.from(items);
			}
			
			// Apply sorting... ------------------------------
			if (onSort) {
				newItemsList = newItemsList.sort(onSort);
			}

			// Update Filtered/Sorted Items -----------------
			setLoadedItems(newItemsList);
			

		} else if (items.length === 0) {
			setLoadedItems([]);
		}

	}, [items, filters]);


	const isAlreadFilteredBy = (filterToCheck: SearchFilter<FilterTypes>) => {
		let foundToBeFilteredBy = false;

		foundToBeFilteredBy = filters.some(
			(searchFilter: SearchFilter<FilterTypes>) => {
				let foundMatch = false;

				if (searchFilter.filter === filterToCheck.filter && searchFilter.label === filterToCheck.label) {
					if (filterToCheck.keyword && searchFilter.keyword === filterToCheck.keyword) {
						foundMatch = true;

					} else {
						foundMatch = true;
					}
				}
		
				return foundMatch;
			}
		);

		return foundToBeFilteredBy
	};

	const addFilter = (searchFilters: SearchFilter<FilterTypes>[]) => {
		let transformedFilters = Array.from(filters);

		for (const filter of searchFilters) {
			if (isAlreadFilteredBy(filter)) {
				transformedFilters = transformedFilters.filter(f => f !== filter);
				
			} else {
				transformedFilters.push(filter);
			}
		}
		
		setFilters(transformedFilters);

		if (onAdd) {
			onAdd(searchFilters);
		}
	};

	const removeFilter = (searchFilter: SearchFilter<FilterTypes>) => {
		if (isAlreadFilteredBy(searchFilter)) {
			const transformedFilters: SearchFilter<FilterTypes>[] = Array.from(filters).filter(
				filter => {
					if (searchFilter.keyword && filter.keyword) {
						return filter.keyword !== searchFilter.keyword;

					} else {
						return filter.filter !== searchFilter.filter;
					}
				}
			);

			setFilters(transformedFilters);

			if (onRemove) {
				onRemove(searchFilter);
			}
		
		// Special use case to remove "*" | "all"
		} else if (searchFilter.filter === SpecialSearchFilters.all) {
			setFilters([]);
		}
	};

	return {
		filters,
		addFilter,
		removeFilter,
		items: loadedItems,
	};
}

export interface iPaginationResponse<ItemData> {
	page: number,
	hasMore: boolean,
	loadedItems: ItemData[],
	setPage?: (newPage: number) => void,
}

export function usePagination<ItemData>(items: ItemData[]) {
	const [page, setPage] = useState(PAGE_SIZE);
	const [hasMore, setHasMore] = useState(true);
	const [loadedItems, setLoadedItems] = useState([] as ItemData[]);

	useEffect(() => {
		let newItems: ItemData[] = [];
		if (items.length > 0) {
			newItems = items.slice(0, page);
		}
		setLoadedItems(newItems);

		// If page position is larger than the remaining events count
		if (newItems.length === 0 || page > newItems.length) {
			setHasMore(false);
			
		/**
		 * If there was nothing left and new events came in, combined 
		 * with the page position being smaller (now) than the 
		 * remaining events count
		*/
		} else if (page <= newItems.length) {
			setHasMore(true);
		}

	}, [items, page]);

	return {
		page,
		setPage,
		hasMore,
		loadedItems,
	};
}