/**
 *
 *  ~ Application Validation Function(s) - Date & Time
 *
 */

import moment from 'moment';


/**
 * Uses Moment library to determine validity of a given date string
 * @param dateString - String to validate
 * @returns True or False based on validity of given date string
 */
export function isDateStringValid(dateString: string): boolean {
	let isValid = false;

	if (dateString) {
		const dateFromString: moment.Moment = moment(dateString);

		if (dateFromString) {
			isValid = dateFromString.isValid();
		}
	}

	return isValid;
}
