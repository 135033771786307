/**
 * 
 * 	~ School Store Provider
 * 
 */


// import { dispatchStoreSelector } from '../StateSelectors/createSelector';
import { SchoolActions } from '@ducts/Providers/ApplicationState/Actions';
import { iSchoolStoreProvider } from '@domain/Interfaces/AppState/school/store';


export const SchoolStoreProvider: iSchoolStoreProvider = {
	actions: SchoolActions,
	selectors: {
		test() {
			//
		},
		// getUser: () =>
		// 	dispatchStoreSelector(
		// 		getUser
		// 	),
	},
};