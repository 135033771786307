/**
 * 
 * 	~ School Event Types and Interfaces
 * 
 */

import { SchoolType } from './schools';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { TextColorTypes, TextVariantTypes } from '@assets/styles/muiTypes/text';

import {
	SearchFilter,
	SearchFilterGranular,
	SpecialSearchFilters,

} from './filters';


export interface iSchoolTimelineEvent {
    id: string,
    source: string,
	opposite?: {
		color?: TextColorTypes,
		content: ReactJSXElement,
		variant?: TextVariantTypes,
	},
	separator?: {
		dot?: {
			color?: TextColorTypes,
			type: TimelineEventType,
			variant: 'outlined' | 'filled',
		},
	},
	content?: ReactJSXElement,
}


export interface TimelineFilter extends SearchFilter<AllTimelineFilters> {}

export type AllTimelineFilters = 
	SpecialSearchFilters |
	SchoolType |
	TimelineFilters | 
	TimelineTimeTypeFilters | 
	TimelineEventTypeFilters;

export interface TimelineFilterOption extends SearchFilterGranular<AllTimelineFilters> {}

export interface TimelineFilterSubItems extends SearchFilter<AllTimelineFilters> {}

export enum TimelineFilters {
	bySchool = 'filter_events_by_school',
	bySchoolType = 'filter_events_by_school_type',
	bySchoolCommunity = 'filter_events_by_school_community',
	byTime = 'filter_events_by_time',
	byEventType = 'filter_events_by_event_type',
}

export enum TimelineTimeTypeFilters {
	oneYear = 'filter_events_by_year',
	today = 'filter_by_todays_events',
	oneWeek = 'filter_by_recent_events',
}

export enum TimelineEventTypeFilters {
	news = 'filter_by_news_events',
	schoolOfficial = 'filter_by_school_official_events',
	police = 'filter_by_police_events',
	public = 'filter_by_public_events',
	socialMedia = 'filter_by_social_media_events',
}

export enum TimelineEventType {
	policeInvolved = 'policeInvolved',
	schoolOfficial = 'schoolOfficial',
	publicOrganized = 'publicOrganized',
	schoolNews = 'schoolNews',
	cbcNews = 'cbcNews',
	ctvNews = 'ctvNews',
	facebook = 'facebook',
	twitter = 'twitter',
}