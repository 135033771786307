/**
 * 
 * ~ Firebase-related Conversion / Transformation Function(s)
 * 
 */

import { User as FirebaseUser, UserCredential } from 'firebase/auth';
import { iRemoteUserDTO } from '@domain/Interfaces/remoteDTOs/user';


/**
 * 
 * @param responseToNormalize 
 * @returns 
 */
export function normalizeUserCredentialResponse(responseToNormalize: UserCredential): iRemoteUserDTO {
	const userDTO: iRemoteUserDTO = {};

	if (responseToNormalize.user.email) {
		userDTO.email = responseToNormalize.user.email;
	}
	if (responseToNormalize.user.uid) {
		userDTO.id = responseToNormalize.user.uid;
	}

	if (userDTO.email && userDTO.id) {
		userDTO.isAuthenticated = true;
	}
    
	return userDTO;
}

/**
 * 
 * @param responseToNormalize 
 * @returns 
 */
export function normalizeUserResponse(responseToNormalize: FirebaseUser): iRemoteUserDTO {
	const userDTO: iRemoteUserDTO = {};

	if (responseToNormalize.email) {
		userDTO.email = responseToNormalize.email;
	}
	if (responseToNormalize.uid) {
		userDTO.id = responseToNormalize.uid;
	}

	if (userDTO.email && userDTO.id) {
		userDTO.isAuthenticated = true;
	}
    
	return userDTO;
}