/**
 * 
 *  ~ Authentication Interactor Builders
 * 
 */

import UserStoreAdapter, { iUserStoreAdapter } from '@ducts/Adapters/ApplicationState/user';

import AuthProvider from '../Providers/Authentication';
import AuthInteractor from '@domain/UseCases/authentication';
import iAuthInteractor from '@domain/Interfaces/Interactor/iAuthenticationInteractor';

import { createAppInteractor } from './app';
import { iRemoteAuthentication } from '@domain/Interfaces/iAuthenticationService';
import { iAppInteractor } from '@domain/Interfaces/Interactor/iApplicationInteractor';
import { UserStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/user';
import NotificationStoreAdapter, { iNotificationStoreAdapter } from '@ducts/Adapters/ApplicationState/notification';
import { NotificationStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/notification';


/**
 * Builds and returns a new instance of Authentication Interactor
 * @returns Authentication Interactor
 */
export function createAuthInteractor(): iAuthInteractor {
	// Create auth provider
	const AuthenticationProvider: iRemoteAuthentication = new AuthProvider();
	// Create schools provider
	const AppInteractor: iAppInteractor = createAppInteractor();
	// Create User Application State provider
	const userStore: iUserStoreAdapter = new UserStoreAdapter(UserStoreProvider);
	// Create Notification Center Application State provider
	const NotificationStore: iNotificationStoreAdapter = new NotificationStoreAdapter(NotificationStoreProvider)
    
	return new AuthInteractor(
		AuthenticationProvider,
		AppInteractor,
		userStore,
		NotificationStore
	);
}

/**
 * New user key for internal use only, does not go to backend
 * @returns 
 */
export const createNewUserStub = () => `new_user_${new Date().toISOString()}`;