/**
 * 
 *  ~ School Event Use Case Functions
 * 
 */

import { log } from '@logging/Console';
import { iSchoolEvent } from '@data/Entities/Events/schoolEvent';
import { CollectionKeys, CollectionNames } from '../Types/Collection';
import { iSchoolEventStoreAdapter } from '@ducts/Adapters/ApplicationState/schoolEvent';
import { iSchoolEventRemoteStoreAdapter } from '@ducts/Adapters/RemoteStore/schoolEvent';
import { iSchoolEventsInteractor } from '@domain/Interfaces/Interactor/iSchoolEventsInteractor';


export default class SchoolEventsInteractor implements iSchoolEventsInteractor {

	private schoolEventsListener: Promise<() => void> | null = null;

	constructor(
        private _RemoteStoreProvider: iSchoolEventRemoteStoreAdapter,
		private _SchoolEventStore: iSchoolEventStoreAdapter,
	) {}

	/**
     * Subscribed to remote store updates and passes a handler to deal with each 
     * update event
     */
	async listenForSchoolEventUpdates(): Promise<void> {
    	try {
			this._SchoolEventStore.actions.isFetchingSchoolEvents(true);

    		this.schoolEventsListener = this._RemoteStoreProvider.subscribeTo(
    			CollectionKeys[CollectionNames.events],
    			this.handleSchoolEventsUpdate
    		);
            
    	} catch (error) {
			this._SchoolEventStore.actions.isFetchingSchoolEvents(false);
    		log('error', 'Something went wrong with school listener, error given: ', error);
    	}
	}

	/**
	 * Handles an incoming remote school update and models it to internal standards 
	 * before pushing to redux store
	 * @param schoolEventsUpdate 
	 */
	private handleSchoolEventsUpdate = async (schoolEventsUpdate: iSchoolEvent[] | null): Promise<void> => {
		this._SchoolEventStore.actions.isFetchingSchoolEvents(false);

		if (schoolEventsUpdate === null) {
			this._SchoolEventStore.actions.resetAll();

		} else {
			if (schoolEventsUpdate.length > 0) {
				this._SchoolEventStore.actions.updateAllSchoolEvents(schoolEventsUpdate);
			}
		}
	};

}