/**
 * 
 * 	~ Category Lure Options
 * 
 */

import { SchoolType } from '@interfaces/schools';
import { iSchoolCateogies } from '@interfaces/schools';



export const schoolCategories: iSchoolCateogies[] = [
	{
		filter: SchoolType.prekAndKindergarten,
		content: (
			<>
				Pre-Kindergarten<br />
				&<br />
				Kindergarten
			</>
		),
	},
	{
		content: 'Elementary',
		filter: SchoolType.elementary,
	},
	{
		content: 'Junior Highschool',
		filter: SchoolType.juniorHigh,
	},
	{
		content: 'Senior Highschool',
		filter: SchoolType.highschool,
	},
];