/*
	~ Application Container Styles
*/

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


export type styleType = {
	container: CSSObject,
	isLoading: CSSObject,
};


export default (theme: Theme): styleType => ({
	container: {
		display: 'flex',
		minHeight: '100vh',
	},
	isLoading: {
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: theme.palette.action.disabledBackground,
	},
});