/**
 * 
 *  ~ Remote Store Provider Service 
 *      Use Case Mixins
 * 
 */


import firestoreType from 'firebase/firestore';
import iRemoteStoreProvider from '../Interfaces/iRemoteStoreProvider';

import { log } from '@logging/Console';
import { firestore } from '../../firebase';
import { CollectionNames } from '../../../../Domain/Types/Collection';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';

import {
	DocumentData,
	DocumentSnapshot,

} from 'firebase/firestore';


type Constructor<T> = new (...args: any[]) => T;


/**
 * This interface satisfies a small portion of the Domain Layer's 
 * total required iRemoteStore Interface
 */
export interface iRemoteStoreAbilities extends iRemoteStore, iRemoteStoreProvider<typeof firestoreType> {}

/**
 * 
 * @param base 
 * @returns 
 */
export function addBaseActions<baseType>(
	collectionKey: CollectionNames,
	base: baseType & Constructor<iRemoteStoreProvider<typeof firestoreType>>
    
): baseType & Constructor<iRemoteStoreAbilities> & Constructor<iRemoteStoreProvider<typeof firestoreType>> {

	return class extends base implements iRemoteStoreAbilities {

		/**
         * Adds a Base Item to remote store
         * @param dto
         */
		async add<iDTO extends object>(dto: iDTO): Promise<string> {
			try {
				let id = '';

				const response = await this.remoteStoreProvider.addDoc(
					this.remoteStoreProvider.collection(firestore, collectionKey),
					{
						...dto 
					}
				);

				if (response && response.id) {
					id = response.id;
				}

				return id;
    
			} catch (error) {
				log('error', `error in adding a base item! error: `, error);

				return '';
			}
		}
        
        
		/**
         * Sets the Base Item manually
         * @param dto 
         * @returns 
         */
		async set<iDTO extends object>(id: string, dto: iDTO): Promise<void> {
			try {
				await this.remoteStoreProvider.setDoc(
					await this.remoteStoreProvider.doc(
						firestore, collectionKey, id
					),
					{
						...dto 
					}
				);
                
			} catch (error) {
				log('error', `error in setting a base item! error: `, error);
			}
		}


		/**
         * 
         * @param id 
         * @param dto 
         */
		async update<iDTO extends object>(id: string, dto: iDTO): Promise<void> {
			try {
				await this.remoteStoreProvider.updateDoc(
					await this.remoteStoreProvider.doc(
						firestore, 
						collectionKey, 
						`${collectionKey}/${id}`
					),
					{
						...dto 
					}
				);

			} catch (error) {
				// @todo: create proper Error class for this with tests
				// throw new Error('Update User profile failed');
				log('error', `error in updating a base item! error: `, error);
			}
		}


		/**
         * Get a Base Item by id
         * @param id 
         * @returns 
         */
		async get<iDTO extends object>(id: string): Promise<iDTO> {
			try {
				const response = await this.remoteStoreProvider.getDoc(
					await this.remoteStoreProvider.doc(
						firestore, collectionKey, `/${id}`
					)
				);

				let dto = {} as iDTO;

				if (response.exists()) {
					dto = {
						...dto,
						...response.data(),
					};
				}

				return dto;
                
			} catch (error) {
				log('error', `error in getting a base item! error: `, error);
				return {} as iDTO;
			}
		}
        

		/**
         * Listen for changes to id key and invoke callback on detected changes
         * @param callback 
         */
		async subscribeTo<iDTO extends object>(
			id: string,
			callback: (dto: iDTO | null) => object
		): Promise<() => void> {
			try {
				return await this.remoteStoreProvider.onSnapshot(
					this.remoteStoreProvider.doc(firestore, collectionKey, id),
					{
						next: this.handleItemOnUpdate(callback)
					}
				);
    
			} catch (error) {
				log('error', `error in subscribing to a base item! error: `, error);
				return () => undefined;
			}
		}


		/**
         * 
         * @param updateCallback 
         * @returns 
         */
		handleItemOnUpdate = <iDTO>(updateCallback: (dto: iDTO | null) => object) => 
			async (document: DocumentSnapshot<DocumentData>): Promise<void> => {
				try {
					let newDTO = {} as iDTO;
	
					if (document && document.exists()) {
						const response = document.data();
					
						if (response) {
							newDTO = {
								...newDTO,
								...response,
							};
	
							updateCallback(newDTO);
					
						} else {
							// @todo: Needs to be a proper error class
							throw new Error('handleOnUpdate function: no response');
						}
	
					} else {
						const response = document.data();
	
						// Items removed or gone
						if (response === null) {
							updateCallback(null);
						}
					}
					
				} catch (error) {
					log('error', 'error in handling update snapshot from firesire, error: ', error);
				}
			}

	};

}