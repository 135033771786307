/*
	~ MarketingHome Component
*/

import Navbar from '@molecules/Navbar';
import KidsImage from '@images/library.png';
import CategoryLureBox from '@atoms/_lureBox';
import DemoTimeline from '@cells/DemoTimeline';
import SectionTitle from '@atoms/_sectionTitle';
import ArrowButton from '@molecules/_arrowButton';
import FamilyImage from '@images/family_school.png';
import ArticleHighlight from '@atoms/_articleHighlighter';
import BooksCirclesImage from '@images/books_circles.png';

import Styles, { stylesType } from './styles';
import LureRow, { LureRows } from '@molecules/LureRow';

import { Paths } from '@settings/routes';
import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { SchoolType } from '@interfaces/schools';
import { getClassesType } from '@interfaces/tssReact';
import { mainNavMenuItems } from '@settings/mainNavOptions';
import { ButtonColors } from '@assets/styles/muiTypes/button';
import { schoolCategories } from '@settings/cateogryLureOptions';

import {
	TimelineFilters, 
	TimelineTimeTypeFilters,

} from '@interfaces/schoolEvent';

import {
	Grid, 
	useMediaQuery,
	
} from '@mui/material';

import {
	useState,
	useCallback,
	ReactElement,
	MouseEventHandler,
	
} from 'react';

import {
	SchoolSharp,
	ChevronRightSharp,

} from '@mui/icons-material';
// import { APIProvider, Map } from '@vis.gl/react-google-maps';


const mapCenterConfig = {
	halifax: {
		lat: 44.651070,
		lng: -63.582687
	},
	bedford: {
		lat: 44.7258,
		lng: -63.6540,
	},
	windsor: {
		lat: 44.98028,
		lng: -64.12917,
	},
	dartmouth: {
		lat: 44.666668,
		lng: -63.566666,
	},
	lowerSackville: {
		lat: 44.77599,
		lng: -63.67865,
	},
};


interface iMarketingHome extends getClassesType<stylesType> {}


const MarketingHome = (props: iMarketingHome) => {
	const navigate = useNavigate();
	const [map, setMap] = useState(null);
	const [coordinates, setCoordinates] = useState(mapCenterConfig.halifax);
	const classes = withStyles.getClasses<stylesType>(props);
	
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	// const onMapLoad = useCallback(function callback(newMap: any) {
	// 	const bounds = new window.google.maps.LatLngBounds(coordinates);
	// 	newMap.fitBounds(bounds);
	// 	setMap(newMap);
	// }, []);

	const onUnmount = useCallback(() => {
		setMap(null);
	}, []);

	const onLandingButtonClick = () => {
		navigate(`${Paths.schoolTimeline}?${TimelineFilters.byTime}=${TimelineTimeTypeFilters.today}`);
	};
	
	const handleCategoryOnClick = (filter: SchoolType) => () => {
		navigate(`${Paths.schoolTimeline}?${TimelineFilters.bySchoolType}=${filter}`);
	};

	const renderCategories = () => {
		const categories: ReactElement[] = [];

		schoolCategories.forEach((category, index) => {
			const {
				filter, content 
			} = category;

			categories.push(
				<CategoryLureBox
					slideInText={'View Schools'}
					key={`school_category_${index}`}
					onClick={handleCategoryOnClick(filter)}
					slideInIcon={
						<SchoolSharp 
							classes={{
								root: classes.categoryIcon
							}} />
					}>

					<h3 className={classes.lureBoxContent}>
						{ content }
					</h3>
				</CategoryLureBox>
			);
		});

		return categories;
	};

	const renderArrowButton = (
		buttonLabel: string, 
		onClick: MouseEventHandler<HTMLButtonElement>
	) => {
		return (
			<ArrowButton 
				onClick={onClick}
				label={buttonLabel} 
				color={ButtonColors.primary} />
		);
	};

	
	const renderLanding = () => {
		return (
			<div className={classes.landing}>
				<Grid container className={classes.landingContainer}>
					{
						isMobile && (
							<Grid xs={12} lg={6} item>
								<div 
									className={classes.landingImage}
									style={{
										backgroundImage: `url(${BooksCirclesImage})`
									}} 
								/>
							</Grid>
						)
					}

					<Grid xs={12} lg={6} item>
						<div className={classes.landingFeaturedAd}>
							<h2 className={classes.landingFeatureTitle}>
								Your window<br />
								into <em><u>Schools</u></em> and <em><u>Education!</u></em>
							</h2>

							<h4 className={classes.landingFeatureDescription}>
								We collect all the data we can find about schools you care about 
								<br />
								so that we can elevate it and serve it to you
							</h4>

							{ 
								renderArrowButton(
									'See Today',
									onLandingButtonClick
								)
							}
						</div>
					</Grid>

					{
						!isMobile && (
							<Grid xs={12} sm={6} item>
								<div 
									className={classes.landingImage}
									style={{
										backgroundImage: `url(${BooksCirclesImage})`
									}} 
								/>
							</Grid>
						)
					}

				</Grid>
			</div>
		);
	};


	return (
		<div className={classes.container}>
			<Navbar 
				menuItems={
					mainNavMenuItems({
						signupButtonCallback: () => {
							navigate(Paths.register);
						}
					})
				} />
			
			{ renderLanding() }

			{
				!isMobile && (
					<SectionTitle titleContent={'Checkout events by...'}>
						{ renderCategories() }
					</SectionTitle>
				)
			}

			<LureRow 
				image={FamilyImage}
				type={LureRows.left}
				onClick={() => {/** */}}
				buttonLabel={(
					<>
						<h3 className={classes.schoolsButtonLabel}>
							See Our Solution
						</h3>

						<ChevronRightSharp 
							classes={{
								root: classes.schoolsChevronRight
							}} />
					</>
				)}>
				
				<>
					<ArticleHighlight overrideClasses={classes.highlighted}>
						<h2 className={classes.highlightedText}>
							<span 
								style={{
									textDecoration: 'underline' 
								}}>

									Parents
							</span>
								&nbsp;have <em>a right</em><br />
								to <em>know about</em><br />
							<strong>their</strong> children’s&nbsp;
							<span 
								style={{
									textDecoration: 'underline' 
								}}>

									school
							</span>
						</h2>
					</ArticleHighlight>

					<h4 className={classes.familySectionDescription}>
						<u>We are parents ourselves.</u><br /><br />
						When we went in search of information about our childrens schools,
						we did not like what we found.<br /><br />
						<strong>We aspire to <strong>END</strong> this <em>problem</em>.</strong>
					</h4>
				</>
			</LureRow>

			<div className={classes.timelines}>
				<div className={classes.timelinesLayout}>
					<DemoTimeline />
				</div>
			</div>

			<LureRow 
				image={KidsImage}
				type={LureRows.right}
				onClick={() => {/** */}}
				buttonLabel={(
					<>
						<h3 className={classes.schoolsButtonLabel}>
							See Our Solution
						</h3>

						<ChevronRightSharp 
							classes={{
								root: classes.schoolsChevronRight
							}} />
					</>
				)}>
				
				<>
					<ArticleHighlight overrideClasses={classes.highlighted}>
						<h2 className={classes.infoGatherTitle}>
							If it <em>exists</em> in the public internet space...
						</h2>
					</ArticleHighlight>

					<h4 className={classes.infoGatherSectionDescription}>
						We are trying to collect all content related to your local schools 
						by finding all public information about them and putting it in one 
						place for you to find.
					</h4>
				</>
			</LureRow>
		</div>
	);
};


export default withStyles(MarketingHome, Styles);