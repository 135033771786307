/**
 * 
 *  Pipeline Factories
 * 
 */

import PipelineInteractor from '@domain/UseCases/pipeline';
import PipelineStoreAdapter from '@ducts/Adapters/ApplicationState/pipeline';
import PipelineRemoteStoreAdapter from '@ducts/Adapters/RemoteStore/pipeline';

import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { CollectionNames } from '../../Domain/Types/Collection';
import { iPipelineInteractor } from '@domain/Interfaces/Interactor/iPipelineInteractor';
import { PipelineStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/pipeline';
import { createRemoteStoreProvider } from '../Providers/RemoteStore/Services/Firebase/RealtimeDB';


/**
 * Createa a new Pipeline Interactor class and injects dependencies
 * @returns 
 */
export function createPipelineInteractor(): iPipelineInteractor {
	// Build remote store for pipeline config, then build its provider
	const RemoteStore = createRemoteStoreProvider(CollectionNames.pipeline);
	const PipelineRemoteStoreProvider: iRemoteStore = new RemoteStore();
	// Give the created remote store to the adapter
	const remotePipelineStoreAdapter = new PipelineRemoteStoreAdapter(PipelineRemoteStoreProvider);

	// Build store adapter for pipeline application state
	const pipelineStore = new PipelineStoreAdapter(PipelineStoreProvider);

	// Build Pipeline interactor with providers
	return new PipelineInteractor(
		remotePipelineStoreAdapter,
		pipelineStore
	);
}