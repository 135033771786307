/**
 * 
 *  ~ Notification Center
 * 
 */

import { NotificationTypes } from '@data/Shared/Enums/notifications/messages';



/**
 * Notification Message
 */
export interface iNotification {
	message: string
	messageType: NotificationTypes
}


export default class Notification implements iNotification {

	private _message = '';
	private _messageType: NotificationTypes;
    
    
	constructor({
		message,
		messageType = NotificationTypes.SUCCESS,
        
	}: {
        message?: string,
        messageType: NotificationTypes,

    }) {
		this._messageType = messageType;
        
		if (message) {
			this._message = message;
		}
	}


	// Message -----------------------------------------
    
	get message(): string {
		return this._message;
	}
    
	set message(newMessage: string) {
		this.message = newMessage;
	}
    
    

	// Message Type ------------------------------------

	get messageType(): NotificationTypes {
		return this._messageType;
	}

	set messageType(newMessageType: NotificationTypes) {
		this.messageType = newMessageType;
	}

}