/**
 * 
 *  ~ Authenticated Route Component
 * 
 */

import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUser } from '@ducts/Providers/ApplicationState/StateSelectors/user';


/**
 * This route protects any authenticated route by wrapping it and 
 * ensuring that the user does not log in twice or register twice
 * @returns 
 */
const AuthenticatedRoute = () => {
	const { isAuthenticated } = useSelector(selectUser);

	if (isAuthenticated) {
		return (
			<Navigate to={Paths.dashboard} />
		);
        
	} else {
		return ( <Outlet /> );
	}
};


export default AuthenticatedRoute;