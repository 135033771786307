// /**
//  * 
//  *  ~ Application Action Types
//  * 
//  */

// import { iAppAction } from '../../State/App/types';


// /**
//  * Application State Actions Interface
//  */
// export interface iAppActionCreators {
//     rehydratingStores(): iAppAction,
//     storesHydrated(): iAppAction,
//     initializingApplication(): iAppAction,
//     applicationNowInitialized(): iAppAction,
// }


// !!!!!!!!!!!!!!!!!! READY FOR DELETION !!!!!!!!!!!!!!!!!!!!!!!!!!!!


/**
 * 
 *  ~ App Action / Action Types
 * 
 */

import { Action } from 'redux';


export enum AppActionTypes {
    INITIALIZING = 'INITIALIZING_APP_ACTION',
    INITIALIZED = 'APP_IS_INITIALIZED_ACTION',
    REDUX_PERSIST_HYDRATED = 'persist/REHYDRATE',
    REHYDRATING_STORES = 'REHYDRATING_STORES_ACTION',
    STORES_FULLY_HYDRATED = 'STORES_FULLY_HYDRATED_ACTION',
}

export interface iAppAction extends Action<AppActionTypes> {}