/*
	~ Dashboard Styles
*/

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		overflowX: 'hidden',
		flexDirection: 'column',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export default Styles;