/*
	~ Paginated Event Timeline Component
*/

import moment from 'moment';
import EventTimeline from '@molecules/EventTimeline';
import TimelineSkeleton from '@atoms/_timelineSkeleton';
import TimelineEndMessage from '@atoms/_timelineEndMessage';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useState } from 'react';
import { PAGE_SIZE } from '@hooks/searchAndFilter';
import { usePagination } from '@hooks/searchAndFilter';
import { iSchoolTimelineEvent } from '@interfaces/schoolEvent';
import { getPipelineDuct } from '@ducts/DuctSelectors/pipeline';
import { useSelector } from 'react-redux';


interface iPaginatedEventTimelineProps {
	events: iSchoolTimelineEvent[],
}

const PaginatedEventTimeline = (props: iPaginatedEventTimelineProps) => {
	const { events } = props;
	const pipeline = useSelector(getPipelineDuct);
	const [isLoading, setIsLoading] = useState(false);

	const {
		page,
		hasMore,
		setPage,
		loadedItems: loadedEvents,

	} = usePagination(events);

	const handleNextPage = (newPage: number) => () => {
		setPage(newPage + PAGE_SIZE);
	};

	const renderEventsLoading = () => {
		if (isLoading) {
			return (
				<TimelineSkeleton />
			);

		} else {
			return null;
		}
	};
	
	const renderEndMessage = () => {
		return (
			<TimelineEndMessage />
		);
	};

	return (
		<EventTimeline 
			events={loadedEvents}
			highlightOnHover={true}
			showEndOfTimeline={false}
			lastKnownRunTime={pipeline?.lastRun || moment()}
			ItemContainer={({ children }) =>
				<InfiniteScroll
					hasMore={hasMore}
					next={handleNextPage(page)}
					loader={renderEventsLoading()}
					endMessage={renderEndMessage()}
					dataLength={loadedEvents.length}
				>
					
					{ children }
				</InfiniteScroll>
			} />
	);
};


export default PaginatedEventTimeline;