/**
 *
 *  ~ Model-level Errors
 *
 */


export enum MODEL_ERRORS {
    unknown = 'UNKNOWN_MODEL_ERROR',
    missingId = 'MISSING_REQUIRED_ID',
    invalidUrl = 'INVALID_URL_FORMAT',
    invalidName = 'INVALID_NAME_FORMAT',
    idWrongFormat = 'ID_IS_WRONG_FORMAT',
    invalidEmail = 'INVALID_EMAIL_FORMAT',
    invalidZipCode = 'INVALID_ZIP_CODE_FORMAT',
    missingProperty = 'MISSING_REQUIRED_PROPERTY',
    messageMaxLength = 'MESSAGE_MAX_LENGTH_EXCEEDED',
    abnormalStatusCode = 'STASTUS_CODE_OUT_OF_BOUNDS',
    invalidPhoneNumber = 'INVALID_PHONE_NUMBER_FORMAT',
    poorlyFormedDateString = 'BAD_DATE_OR_TIME_STRING_FORMAT',
}
