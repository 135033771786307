/*
	~ Timeline Skeleton Component
*/

import { Skeleton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';



const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		height: '50vh',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,
	skeletonRow: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	rightContentRow: {} as CSSObject,
	rowContent: {
		width: 375,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

const SKELETON_CIRCLE_SIZE = 42.5;

interface iTimelineSkeletonProps extends getClassesType<stylesType> {}

const TimelineSkeleton = (props: iTimelineSkeletonProps) => {
	const classes = withStyles.getClasses<stylesType>(props);

	const renderRightRow = () => {
		return (
			<div className={classes.rowContent}>
				<Skeleton
					height={20}
					width={'100%'}
					animation={'pulse'}
					variant={'rectangular'}
				/>
				
				<Skeleton
					height={75}
					width={'100%'}
					animation={'pulse'}
					variant={'rectangular'}
					sx={{
						mt: 1.5 
					}}
				/>
			</div>
		);
	};
	
	const renderLeftRow = () => {
		return (
			<div className={classes.rowContent}>
				<Skeleton
					height={20}
					width={'100%'}
					animation={'pulse'}
					variant={'rectangular'}
				/>
				
				<Skeleton
					height={75}
					width={'100%'}
					animation={'pulse'}
					variant={'rectangular'}
					sx={{
						mt: 1.5 
					}}
				/>
			</div>
		);
	};

	const renderSkeletonRowRight = () => {
		return (
			<div className={classes.skeletonRow}>
				<Skeleton
					width={375}
					height={25}
					animation={'pulse'}
					variant={'rectangular'}
				/>
				
				<Skeleton
					animation={'pulse'}
					variant={'circular'}
					width={SKELETON_CIRCLE_SIZE}
					height={SKELETON_CIRCLE_SIZE}
					sx={{
						mx: 3,
					}}
				/>

				{ renderRightRow() }
			</div>
		);
	};
	
	const renderSkeletonRowLeft = () => {
		return (
			<div className={classes.skeletonRow}>
				{ renderLeftRow() }
				
				<Skeleton
					animation={'pulse'}
					variant={'circular'}
					width={SKELETON_CIRCLE_SIZE}
					height={SKELETON_CIRCLE_SIZE}
					sx={{
						mx: 3,
					}}
				/>

				<Skeleton
					width={375}
					height={25}
					animation={'pulse'}
					variant={'rectangular'}
				/>
			</div>
		);
	};

	return (
		<div className={classes.container}>
			{ renderSkeletonRowRight() }
			{ renderSkeletonRowLeft() }
			{ renderSkeletonRowRight() }
			{ renderSkeletonRowLeft() }
		</div>
	);
};


export default withStyles(TimelineSkeleton, Styles);