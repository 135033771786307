/**
 * 
 *  ~ Static Data for Admin Side Navbar Component
 * 
 */

import { useMemo } from 'react';
import { Paths } from './routes';

import {
	SideMenuItemIds,
	AdminSideMenuType,
	iAdminSideMenuItem,

} from '@interfaces/adminSideMenu';

import {
	MapSharp,
	SchoolSharp,
	DashboardSharp,
	CalendarMonthSharp,

} from '@mui/icons-material';


export const adminSideNavMenuItems = (): iAdminSideMenuItem[] => useMemo(() => ([
	{
		label: 'Dashboard',
		url: Paths.dashboard,
		icon: <DashboardSharp />,
		id: SideMenuItemIds.dashboard,
		type: AdminSideMenuType.option,
	},
	{
		type: AdminSideMenuType.divider,
	},
	{
		label: 'Schools',
		type: AdminSideMenuType.header,
	},
	{
		label: 'All Schools',
		icon: <SchoolSharp />,
		url: Paths.cpSchoolsListing,
		id: SideMenuItemIds.allSchools,
		type: AdminSideMenuType.option,
	},
	{
		label: 'Event Timeline',
		url: Paths.cpTimeline,
		icon: <CalendarMonthSharp />,
		type: AdminSideMenuType.option,
		id: SideMenuItemIds.eventTimeline,
	},
	{
		type: AdminSideMenuType.divider,
	},
	{
		label: 'Crime in Schools',
		type: AdminSideMenuType.header,
	},
	{
		icon: <MapSharp />,
		label: 'Crime heat map',
		url: Paths.schoolTimeline,
		type: AdminSideMenuType.option,
		id: SideMenuItemIds.crimeHeatMap,
	},
]), []);