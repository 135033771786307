/*
	~ Timeline End Message Component
*/

import { useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { Tooltip, Typography } from '@mui/material';
import { getClassesType } from '@interfaces/tssReact';
import { StopCircleSharp } from '@mui/icons-material';



const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,
	emptyIcon: {
		fontSize: 50,
	} as CSSObject,
	emptyTitle: {
		fontSize: 25,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iTimelineEndMessageProps extends getClassesType<stylesType> {}

const TimelineEndMessage = (props: iTimelineEndMessageProps) => {
	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<div 
			className={classes.container} 
			style={{
				marginBottom: 100,
			}}>
			<Tooltip title={'There are no more events with the current filters applied'}>
				<>
					<StopCircleSharp color='error' className={classes.emptyIcon} />

					<Typography variant={'caption'}>
						END OF EVENTS
					</Typography>
				</>
			</Tooltip>
		</div>
	);
};


export default withStyles(TimelineEndMessage, Styles);