/**
 * 
 *  ~ Notification Center Icons
 * 
 */

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
// import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { ViewNotificationTypes } from '@interfaces/notifications';

import { 
	Info, 
	Warning,

} from '@mui/icons-material';


const Styles = () => ({
	messageIcon: {
		marginRight: 15,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iNotificationIcon extends getClassesType<stylesType> {
    type: ViewNotificationTypes,
}


const NotificationIcon = (props: iNotificationIcon) => {
	const { type } = props;
	const classes = withStyles.getClasses<stylesType>(props);

	if (type) {
		let icon = null;

		switch(type) {
			case ViewNotificationTypes.success:
				icon = (
					<Info 
						classes={{
							root: classes.messageIcon 
						}} /> 
				);
				break;

			case ViewNotificationTypes.error:
				icon = (
					<Warning 
						classes={{
							root: classes.messageIcon 
						}} />
				);
				break;

			default:
				break;
		}

		return icon;

	} else {
		return null;
	}
};

export default withStyles(NotificationIcon, Styles);