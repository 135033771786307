/**
 * 
 * 	~ Navbar Interfaces & Types
 * 
 */

import { ButtonColorTypes } from '@assets/styles/muiTypes/button';


export enum NavbarButtonTypes {
	link,
	button,
	loginMenu,
	iconButton,
}

export enum LoginMenuOptionType {
	option,
	divider,
	iconOption,
}

export interface LoginMenuOptions {
	label?: string,
	callback?: () => void,
	type: LoginMenuOptionType,
	options?: {
		[id: string]: any,
	},
}

export interface iNavbarMenuItem {
    url?: string,
    label?: string,
	type: NavbarButtonTypes,
	button?: {
		callback: () => void,
		color?: ButtonColorTypes,
	},
}

export interface TopNavbarMenuItem extends iNavbarMenuItem {
	loginMenuOptions?: LoginMenuOptions[],
}