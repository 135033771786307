/**
 * 
 *  ~ Firestore Collections - Remote Store Provider Service 
 *      Use Case Mixins
 * 
 */


import firestoreType from 'firebase/firestore';
import iRemoteStoreProvider from '../Interfaces/iRemoteStoreProvider';

import { log } from '@logging/Console';
import { firestore } from '../../firebase';
import { CollectionNames } from '../../../../Domain/Types/Collection';
import { iRemoteCollectionStore } from '@domain/Interfaces/iRemoteStore';

import {
	DocumentData,
	QuerySnapshot,

} from 'firebase/firestore';


type Constructor<T> = new (...args: any[]) => T;


/**
 * This interface satisfies a small portion of the Domain Layer's 
 * total required iRemoteStore Interface
 */
export interface iFirestoreCollectionsAbilities extends iRemoteCollectionStore, 
                                                         iRemoteStoreProvider<typeof firestoreType> {}

/**
 * 
 * @param base 
 * @returns 
 */
export function addCollectionsActions<baseType>(
	collectionKey: CollectionNames,
	base: baseType & Constructor<iRemoteStoreProvider<typeof firestoreType>>
    
): baseType & Constructor<iFirestoreCollectionsAbilities> & Constructor<iRemoteStoreProvider<typeof firestoreType>> {

	return class extends base implements iFirestoreCollectionsAbilities {

		public defaultDocId = 'user';
        
        
		/**
         * Adds a Base Item to subcollection key remote store
         * @param dto
         */
		async addToSubcollection<iDTO extends object>(
			subcollectionKey: string, 
			dto: iDTO, 
			docId?: string
		): Promise<string> {
			try {
				let id = '';
				const controlledDocId: string = docId || this.defaultDocId;
                
				// Get doc where Sub-collection exists
				const docRef = await this.remoteStoreProvider.collection(
					firestore, 
					collectionKey,
					controlledDocId,
					subcollectionKey
				);

				// If Doc Exists, Add Sub-collection
				if (docRef && docRef.id) {
					const response = await this.remoteStoreProvider.addDoc(
						docRef,
						{
							...dto 
						}
					);
					// If new item was added to Sub-collection successfully...
					if (response && response.id) {
						// Return this id
						id = response.id;
					}
				}

				return id;
    
			} catch (error) {
				log('error', `error in adding by key to a base item! error: `, error);
				return '';
			}
		}
        

		/**
         * Listen for changes to id key and invoke callback on detected changes
         * @param callback 
         */
		async subscribeToSubcollection<iDTO extends object>(
			docId: string, 
			subcollectionId: string, 
			callback: (dto: iDTO[]) => object
		): Promise<() => void> {
			try {
				return await this.remoteStoreProvider.onSnapshot(
					await this.remoteStoreProvider.collection(
						firestore, 
						collectionKey, 
						docId, 
						subcollectionId
					),
					{
						next: this.handleCollectionOnUpdate(callback) 
					}
				);
    
			} catch (error) {
				log('error', `error in subscribing to a base item! error: `, error);
				return () => undefined;
			}
		}


		/**
         * 
         * @param updateCallback 
         * @returns 
         */
		handleCollectionOnUpdate = <iDTO>(updateCallback: (dto: iDTO[]) => object) => 
			async (query: QuerySnapshot<DocumentData>): Promise<void> => {
				const newDTOs = [] as iDTO[];
            
				if (query && query.docs && query.docs.length > 0) {
					for (const doc of query.docs) {
						const newDTO: iDTO = doc.data() as iDTO;
                    
						if (doc) {
							newDTOs.push({
								...newDTO,
								id: doc.id,
							});
                    
						} else {
							// @todo: Needs to be a proper error class
							throw new Error('handleOnUpdate function: no doc');
						}
					}

					if (newDTOs && newDTOs.length > 0) {
						updateCallback(newDTOs);
					}
				}
			}

	};

}