/*
	~ Notification Center styles
*/

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	error: {
		paddingLeft: 0,
		paddingRight: 0,
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.error.main,
	} as CSSObject,
	boxShape: {
		fontSize: 18,
		display: 'flex',
		fontWeight: 'bold',
		padding: '8px 20px',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	} as CSSObject,
	info: {
		paddingLeft: 0,
		paddingRight: 0,
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.main,
		// border: `${theme.sizes.hrHeight}px ${theme.palette.secondary.dark} solid`,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;