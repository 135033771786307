/**
 * 
 *  ~ Selector Controllers - Pipeline
 * 
 */

import moment from 'moment';

import { iPipelineDuct } from '@ducts/Interfaces/pipeline';
import { iPipelineStoreDTO } from '@domain/Interfaces/AppState/pipeline/dto';
import { selectPipelineData } from '@ducts/Providers/ApplicationState/StateSelectors/pipeline';


/**
 * Get state pipeline data and model as a pipeline duct
 */
export const getPipelineDuct = selectPipelineData((pipeline: iPipelineStoreDTO) => {
	let pipelineData: iPipelineDuct | null = null;

	if (pipeline) {
		const { lastRun } = pipeline;

		if (lastRun) {
			pipelineData = {
				lastRun: moment(pipeline.lastRun),
			}
		}
	}

	return pipelineData;
});