/**
 * 
 * 	~ Compact Timeline Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';

const Styles = (theme: Theme) => ({
	schoolSelector: {
		width: '25%',
	} as CSSObject,
	mobileSchoolSelector: {
		width: '100%',
	} as CSSObject,
	timelinesTitlebar: {
		width: '100%',
		display: 'flex',
		marginBottom: 100,
		alignItems: 'center',
		flexDirection: 'row',
		borderBottom: `solid 5px ${theme.palette.primary.main}`,
	} as CSSObject,
	timelineTitle: {
		width: '100%',
		marginRight: 35,
		color: theme.palette.primary.dark,

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			textAlign: 'center',
		},
	} as CSSObject,
	footer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: '35px 0',
			textAlign: 'center',
		},
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;