/*
	~ MaterialUITheme Component
*/

import Theme from '@globalStyles/centralMuiThemeStyles';

import { ThemeProvider } from '@mui/system';
import { hasChildren } from '@interfaces/React';


const MUITheme = (props: hasChildren) => (
	<ThemeProvider theme={Theme}>
		{ (props.children || null) }
	</ThemeProvider>
);

export default MUITheme;