/**
 * 
 * ~ Configure the Redux Store
 * 
 */

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

import thunk from 'redux-thunk';
import combinedReducers from './State/index';

import { persistStore } from 'redux-persist';

import {
	compose ,
	createStore, 
	applyMiddleware, 

} from 'redux';

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

export const store = createStore(
	combinedReducers,
	undefined,
	composeEnhancers(
		applyMiddleware(
			thunk
		)
	),
);

const persistor = persistStore(store);

/**
 * !!!! THIS IS FOR TESTING PURPOSES ONLY!!!!
 * 
 *  	DO NOT USE IN PRODUCTION!
 * 
 * !!!! THIS IS FOR TESTING PURPOSES ONLY!!!!
 */
// persistor.purge();
// ----------=====================-----------

export default {
	store, 
	persistor,
};