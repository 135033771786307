/**
 * 
 * 	~ School Event Adapter for Remote Store
 * 
 */

import { iSchoolEvent } from '@data/Entities/Events/schoolEvent';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { iRemoteSchoolEventsDTO } from '@domain/Interfaces/remoteDTOs/schoolEvent';

import {
	RemoteSchoolEventCollectionModelling,
	RemoteSchoolEventCollectionStructure,
} from '@domain/Modelling/schoolEvent';


export interface iSchoolEventRemoteStoreAdapter {
	subscribeTo(id: string, callback: (schools: iSchoolEvent[] | null) => object): Promise<() => void>
}


export default class SchoolEventRemoteStoreAdapter implements iSchoolEventRemoteStoreAdapter {

	constructor(
		private _RemoteStoreProvider: iRemoteStore,
	) {}
	
	async subscribeTo(
		id: string, 
		callback: (schools: iSchoolEvent[] | null) => object
	): Promise<() => void> {
		return this._RemoteStoreProvider.subscribeTo<iRemoteSchoolEventsDTO>(
			id, 
			(schoolEventsDTOResponse: iRemoteSchoolEventsDTO | null) => {
				if (schoolEventsDTOResponse) {
					return callback(
						RemoteSchoolEventCollectionModelling.fromCollection(
							RemoteSchoolEventCollectionStructure.toCollection(schoolEventsDTOResponse)
						)
					);
				
				} else {
					return {};
				}
			}
		);
	}

}