/**
 * 
 * 	~ School Application State Adapters
 * 
 */

import { iSchool } from '@data/Entities/Schools/school';
import { iSchoolStoreProvider } from '@domain/Interfaces/AppState/school/store';
import { SchoolStoreCollectionModelling, SchoolStoreModelling } from '@domain/Modelling/school';


export interface iSchoolStoreAdapter {
	actions: {
		resetAll(): void,
		updateSchool(school: iSchool): void,
		updateAllSchools(schools: iSchool[]): void,
		isFetchingSchools(isFetching?: boolean): void,
	},
	selectors: {
		//
	},
}


export default class SchoolStoreAdapter implements iSchoolStoreAdapter {
	constructor(
		private _SchoolStore: iSchoolStoreProvider,
	) {}

	actions = {
		...this._SchoolStore.actions,
		updateSchool: (school: iSchool) => {
			this._SchoolStore.actions.updateSchool(
				SchoolStoreModelling.toDTO(school)
			)
		},
		updateAllSchools: (schools: iSchool[]) => {
			this._SchoolStore.actions.updateAllSchools(
				SchoolStoreCollectionModelling.toCollection(schools)
			)
		},
	};


	selectors = {
		placeholderDeleteMe: () => {
			//
		},
	};
}