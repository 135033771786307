/**
 * 
 *  ~ Static Data for Main Navigation Component(s)
 * 
 */

import { useMemo } from 'react';
import { Paths } from './routes';
import { NavbarButtonTypes, iNavbarMenuItem } from '@interfaces/navbar';


export interface iMainNavMenuItems {
	signupButtonCallback: () => void,
}


export const mainNavMenuItems = ({ signupButtonCallback }: iMainNavMenuItems): iNavbarMenuItem[] => useMemo(() => ([
	{
		label: 'Today',
		url: Paths.schoolTimeline,
		type: NavbarButtonTypes.link,
	},
	{
		label: 'Schools',
		url: Paths.allSchools,
		
		type: NavbarButtonTypes.link,
	},
	{
		label: 'Timeline',
		url: Paths.schoolTimeline,
		type: NavbarButtonTypes.link,
	},
	// {
	// 	url: 'http://',
	// 	label: 'Crime',
	// 	type: NavbarButtonTypes.link,
	// },
	{
		url: 'http://',
		label: 'Sign Up',
		type: NavbarButtonTypes.button,
		button: {
			callback: signupButtonCallback,
		},
	},
	{
		label: 'Log In',
		url: Paths.login,
		type: NavbarButtonTypes.link,
	},

]), []);