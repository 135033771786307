/**
 * 
 * ======================================== 
 *  Core Application Logic Entry Point ----
 * ======================================== 
 * 
*/

import ConfigureStore from '@ducts/Providers/ApplicationState/configureStore';

import { initialize } from './Ducts/Controllers/app';
import { Provider as ReactReduxProvider } from 'react-redux';

const { store } = ConfigureStore;

export {
	store,
	initialize,
	ReactReduxProvider,
};

// ======================================== 