/**
 *
 *  ~ Login Errors
 *
 */


export enum LOGIN_ERRORS {
    userDisabled = 'USER_DISABLED',
    userNotFound = 'USER_NOT_FOUND',
    invalidEmail = 'INVALID_EMAIL_FORMAT',
    userNotLoggedIn = 'USER_NOT_LOGGED_IN',
    userNotLoggedOut = 'USER_NOT_LOGGED_OUT',
    unauthorizedAccess = 'UNAUTHORIZED_ACCESS',
    invalidPassword = 'INVALID_PASSWORD_FORMAT',
    missingToken = 'MISSING_TOKEN_FROM_SECURED_ENDPOINT',
}


export interface iLoginMessage {
    [errorType: string]: string,
}

export const LOGIN_MESSAGES: iLoginMessage = {
	[LOGIN_ERRORS.invalidEmail]: 'Invalid email format!',
	[LOGIN_ERRORS.userNotLoggedIn]: 'User is not logged in!',
	[LOGIN_ERRORS.userDisabled]: 'This user has been disabled!',
	[LOGIN_ERRORS.userNotLoggedOut]: 'User could not be logged out!',
	[LOGIN_ERRORS.userNotFound]: 'There are no users with this email',
	[LOGIN_ERRORS.invalidPassword]: 'Incorrect password given for this user',
	[LOGIN_ERRORS.unauthorizedAccess]: 'This user cannot access this resource or account',
	[LOGIN_ERRORS.missingToken]: 'For some reason the Firebase token is missing in the network request',
};