/**
 * 
 *  ~ Static Data for Admin Top Navbar Component
 * 
 */

import { useMemo } from 'react';
import { LogoutSharp } from '@mui/icons-material';

import {
	iNavbarMenuItem,
	NavbarButtonTypes,
	LoginMenuOptionType,

} from '@interfaces/navbar';


export interface iAdminTopNavMenuProps {
	logoutCallback: () => void,
}


export const adminTopNavMenuItems = ({ logoutCallback }: iAdminTopNavMenuProps): iNavbarMenuItem[] => useMemo(() => ([
	{
		type: NavbarButtonTypes.loginMenu,
		loginMenuOptions: [
			{
				type: LoginMenuOptionType.divider,
			},
			{
				label: 'Log Out',
				callback: logoutCallback,
				type: LoginMenuOptionType.iconOption,
				options: {
					icon: <LogoutSharp />,
				},
			},
		],
	},
]), []);