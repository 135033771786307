/**
 * 
 *  ~ View-layer Utility Functions
 * 
 */


/**
 * Checks a variable to see if it's empty
 *  !! ONLY ACCEPTS PRIMITIVES !!
 * 
 * @param variable 
 * @returns 
 */
export function isEmpty(variable: any): boolean {
	let foundEmpty = true;

	if (variable && variable !== null && variable !== undefined) {
		if (typeof variable === 'string' && variable !== '' && variable !== ' ') {
			// String
			foundEmpty = false;
		} else if (typeof variable === 'number' && !isNaN(variable) && Number(variable) !== undefined) {
			// Number
			foundEmpty = false;
		} else if (typeof variable === 'object' && !Array.isArray(variable) && Object.keys(variable).length > 0) {
			// Object non-array
			foundEmpty = false;
		} else if (typeof variable === 'object' && Array.isArray(variable) && variable.length > 0) {
			// Object array
			foundEmpty = false;
		}
	}

	return foundEmpty;
}