/**
 * 
 * 	~ Static Options for Timeline Filter Menu Components
 * 
 */

import { useMemo } from 'react';

import { SchoolType } from '@interfaces/schools';

import {
	TimelineFilters,
	TimelineFilterOption,
	TimelineTimeTypeFilters,
	TimelineEventTypeFilters,

} from '@interfaces/schoolEvent';
import { SpecialSearchFilters } from '@interfaces/filters';


export const TimelineFilterLabels: { [id: string]: string } = {
	[SpecialSearchFilters.all]: 'All',
	[TimelineFilters.byTime]: 'Time',
	[TimelineFilters.bySchool]: 'School',
	[TimelineFilters.bySchoolType]: 'School Type',
	[TimelineFilters.byEventType]: 'Event Type',
	[TimelineFilters.bySchoolCommunity]: 'Location',

	[TimelineTimeTypeFilters.today]: 'Today',
	[TimelineTimeTypeFilters.oneWeek]: 'One Week',
	[TimelineTimeTypeFilters.oneYear]: 'One Year',

	[SchoolType.prekAndKindergarten]: 'Pre-k & Kindergarten',
	[SchoolType.elementary]: 'Elementary',
	[SchoolType.juniorHigh]: 'Junior High',
	[SchoolType.highschool]: 'Highschool',

	[TimelineEventTypeFilters.news]: 'News',
	[TimelineEventTypeFilters.schoolOfficial]: 'School Official Publication',
	[TimelineEventTypeFilters.police]: 'Pollice Media',
	[TimelineEventTypeFilters.public]: 'Public Information',
	[TimelineEventTypeFilters.socialMedia]: 'Social Media',
};

/**
 * Creates all main timeline filteration options
 */
export const getFilterOptions = (): TimelineFilterOption[] => useMemo(() => [
	{
		aggregate: true,
		filter: SpecialSearchFilters.all,
		label: TimelineFilterLabels[SpecialSearchFilters.all],
	},
	{
		label: TimelineFilterLabels[TimelineFilters.byTime],
		filter: TimelineFilters.byTime,
		subItems: [
			{
				aggregate: false,
				filter: TimelineTimeTypeFilters.today,
				label: TimelineFilterLabels[TimelineTimeTypeFilters.today],
			},
			{
				aggregate: false,
				filter: TimelineTimeTypeFilters.oneWeek,
				label: TimelineFilterLabels[TimelineTimeTypeFilters.oneWeek],
			},
			{
				aggregate: false,
				filter: TimelineTimeTypeFilters.oneYear,
				label: TimelineFilterLabels[TimelineTimeTypeFilters.oneYear],
			},
		]
	},
	{
		label: TimelineFilterLabels[TimelineFilters.byEventType],
		filter: TimelineFilters.byEventType,
		subItems: [
			{
				aggregate: false,
				filter: TimelineEventTypeFilters.news,
				label: TimelineFilterLabels[TimelineEventTypeFilters.news],
			},
			{
				aggregate: false,
				filter: TimelineEventTypeFilters.schoolOfficial,
				label: TimelineFilterLabels[TimelineEventTypeFilters.schoolOfficial],
			},
			{
				aggregate: false,
				filter: TimelineEventTypeFilters.police,
				label: TimelineFilterLabels[TimelineEventTypeFilters.police],
			},
			{
				aggregate: false,
				filter: TimelineEventTypeFilters.public,
				label: TimelineFilterLabels[TimelineEventTypeFilters.public],
			},
			{
				aggregate: false,
				filter: TimelineEventTypeFilters.socialMedia,
				label: TimelineFilterLabels[TimelineEventTypeFilters.socialMedia],
			},
		]
	},
	{
		label: TimelineFilterLabels[TimelineFilters.bySchoolType],
		filter: TimelineFilters.bySchoolType,
		subItems: [
			{
				aggregate: true,
				filter: SchoolType.prekAndKindergarten,
				label: TimelineFilterLabels[SchoolType.prekAndKindergarten],
			},
			{
				aggregate: true,
				filter: SchoolType.elementary,
				label: TimelineFilterLabels[SchoolType.elementary],
			},
			{
				aggregate: true,
				filter: SchoolType.juniorHigh,
				label: TimelineFilterLabels[SchoolType.juniorHigh],
			},
			{
				aggregate: true,
				filter: SchoolType.highschool,
				label: TimelineFilterLabels[SchoolType.highschool],
			},
		]
	},
	{
		filter: TimelineFilters.bySchool,
		label: TimelineFilterLabels[TimelineFilters.bySchool],
		subItems: [],
	},
	{
		label: TimelineFilterLabels[TimelineFilters.bySchoolCommunity],
		filter: TimelineFilters.bySchoolCommunity,
		subItems: [
			{
				label: 'Halifax',
				filter: TimelineEventTypeFilters.news
			},
		]
	},

], []);