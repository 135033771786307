/**
 * 
 * ~ Firebase Utilities
 * 
 */


// Import the functions you need from the SDKs you need
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getProducts, getStripePayments } from '@invertase/firestore-stripe-payments';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
	measurementId: 'G-1FXC220KMN',
	projectId: 'schoolreport-40abd',
	messagingSenderId: '653505520772',
	storageBucket: 'schoolreport-40abd.appspot.com',
	authDomain: 'schoolreport-40abd.firebaseapp.com',
	apiKey: 'AIzaSyBbJPMyCyvAabLufRd70g-2Nd7wnKE3euA',
	appId: '1:653505520772:web:9e58118d2976e897af8ed1',
};

// if (process.env.REACT_APP_ENV_MODE === 'production') {
// 	firebaseConfig.databaseURL = 'https://schoolreport-40abd-prod.firebaseio.com';
	
// } else if (process.env.REACT_APP_ENV_MODE === 'staging') {
// 	firebaseConfig.databaseURL = 'https://schoolreport-40abd-default-rtdb.firebaseio.com';
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const realtimeDB = getDatabase(app);
export const analytics = getAnalytics(app);
export const fireAuthentication = getAuth(app);

// Turn off unless in prod
if (process.env.NODE_ENV !== 'production') {
	setAnalyticsCollectionEnabled(analytics, false);
}

// Connect emulator if in local dev
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
	connectAuthEmulator(fireAuthentication, 'http://127.0.0.1:9099');
	connectDatabaseEmulator(realtimeDB, '127.0.0.1', 9000);
	connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
} 

const payments = getStripePayments(app, {
	productsCollection: 'products',
	customersCollection: 'customers',
});

console.log(`payments: `, payments);

// const products = await getProducts(payments, {
// 	includePrices: true,
// 	activeOnly: true,
// 	// where: [
// 	// 	['metadata.type', '==', 'books'],
// 	// 	['metadata.rating', '>=', 4],
// 	// ],
// 	limit: 10,
// });

// console.log(`products: `, products);

// for (const product of products) {
// 	console.log(`product: `, product);
// }