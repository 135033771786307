/**
 * 
 *  ~ Firebase Firestore DB Provider
 * 
 */

import firestoreType from 'firebase/firestore';
import * as firestore from 'firebase/firestore';
import iRemoteStoreProvider from '../../Interfaces/iRemoteStoreProvider';

import { CollectionNames } from '../../../../../Domain/Types/Collection';
import { addBaseActions } from '../../UseCaseMixins/firestoreAbilities';
import { addCollectionsActions } from '../../UseCaseMixins/firestoreCollectionsAbilities';


// 
class FirestoreProvider implements iRemoteStoreProvider<typeof firestoreType> {
	public remoteStoreProvider = firestore;
}


// Add Actions To Auth Provider
const createRemoteStore = (key: CollectionNames) => addBaseActions(key, FirestoreProvider);
const createRemoteCollectionStore = (key: CollectionNames) => addCollectionsActions(key, createRemoteStore(key));

export default createRemoteCollectionStore;