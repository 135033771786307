/**
 * 
 * 	~ School Filter Options for UI
 * 
 */

import { useMemo } from 'react';

import {
	SchoolType,
	SchoolFilters,
	AllSchoolsFilterOption,
	SchoolInformationFilters,

} from '@interfaces/schools';
import { SpecialSearchFilters } from '@interfaces/filters';


export const SchoolFilterLabels: { [id: string]: string } = {
	[SpecialSearchFilters.all]: 'All',
	[SchoolFilters.schoolType]: 'School Type',
	[SchoolFilters.schoolNameSearch]: 'Search by Name',
	[SchoolFilters.schoolInformation]: 'School Information',
	
	[SchoolType.elementary]: 'Elementary',
	[SchoolType.highschool]: 'Highschool',
	[SchoolType.juniorHigh]: 'Junior High',
	[SchoolType.prekAndKindergarten]: 'Pre-K',

	[SchoolInformationFilters.byCity]: 'City',
};


export const getSchoolFilterOptions = (): AllSchoolsFilterOption[] => useMemo(() => [
	{
		aggregate: true,
		filter: SpecialSearchFilters.all,
		label: SchoolFilterLabels[SpecialSearchFilters.all],
	},
	{
		aggregate: true,
		filter: SchoolFilters.schoolNameSearch,
		label: SchoolFilterLabels[SchoolFilters.schoolNameSearch],
		subItems: [],
	},
	{
		aggregate: true,
		filter: SchoolFilters.schoolType,
		label: SchoolFilterLabels[SchoolFilters.schoolType],
		subItems: [
			{
				aggregate: true,
				filter: SchoolType.prekAndKindergarten,
				label: SchoolFilterLabels[SchoolType.prekAndKindergarten],
			},
			{
				aggregate: true,
				filter: SchoolType.elementary,
				label: SchoolFilterLabels[SchoolType.elementary],
			},
			{
				aggregate: true,
				filter: SchoolType.juniorHigh,
				label: SchoolFilterLabels[SchoolType.juniorHigh],
			},
			{
				aggregate: true,
				filter: SchoolType.highschool,
				label: SchoolFilterLabels[SchoolType.highschool],
			},
		],
	},
	{
		aggregate: true,
		filter: SchoolFilters.schoolInformation,
		label: SchoolFilterLabels[SchoolFilters.schoolInformation],
		subItems: [
			{
				aggregate: true,
				filter: SchoolInformationFilters.byCity,
				label: SchoolFilterLabels[SchoolInformationFilters.byCity],
			},
		],
	},

], []);