// /**
//  * 
//  *  ~ Notification Center Action Types
//  * 
//  */

// import {
// 	NotificationMessage,
// 	iNotificationCenterAction,

// } from '../../State/NotificationCenter/types';


// /**
//  * Notification Center State Actions Interface
//  */
// export interface iNotificationCenterActionCreators {
//     showMessage(notification: NotificationMessage): iNotificationCenterAction,
//     removeFirstMessageInStack(): iNotificationCenterAction
// }


import { Action } from 'redux';
import { NotificationTypes } from '@data/Shared/Enums/notifications/messages';



export enum NotificationCenterActionTypes {
	ADD_MESSAGE_TO_NOTIFICATION_CENTER = 'ADD_MESSAGE_TO_NOTIFICATION_CENTER_ACTION',
	REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER = 'REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER_ACTION',
}

export interface iNotificationCenterAction extends Action<NotificationCenterActionTypes> {
	message: string,
	messageType: NotificationTypes,
}