/**
 * 
 * 	~ MUI Library Types - Animation
 * 
 */

export enum SlideAnimations {
	up = 'up',
	down = 'down',
}

export type SlideAnimationTypes = SlideAnimations.up | SlideAnimations.down;