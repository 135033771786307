/**
 * 
 *  App Factories
 * 
 */


import ApplicationInteractor from '@domain/UseCases/application';

import AppStoreAdapter, { iAppStoreAdapter } from '@ducts/Adapters/ApplicationState/app';

import { createUserInteractor } from './user';
import { createSchoolInteractor } from './school';
import { createPipelineInteractor } from './pipeline';
import { createSchoolEventsInteractor } from './schoolEvent';
import { iUserInteractor } from '@domain/Interfaces/Interactor/iUserInteractor';
import { iSchoolInteractor } from '@domain/Interfaces/Interactor/iSchoolsInteractor';
import { iAppInteractor } from '@domain/Interfaces/Interactor/iApplicationInteractor';
import { iPipelineInteractor } from '@domain/Interfaces/Interactor/iPipelineInteractor';
import { iSchoolEventsInteractor } from '@domain/Interfaces/Interactor/iSchoolEventsInteractor';
import { AppStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/app';


/**
 * Createa a new App Interactor class and injects dependencies
 * @returns 
 */
export function createAppInteractor(): iAppInteractor {
	// Build all providers
	const userProvider: iUserInteractor = createUserInteractor();
	const schoolsProvider: iSchoolInteractor = createSchoolInteractor();
	const pipelineProvider: iPipelineInteractor = createPipelineInteractor();
	const schoolEventsProvider: iSchoolEventsInteractor = createSchoolEventsInteractor();
	// Build store adapter for application state
	const appStore: iAppStoreAdapter = new AppStoreAdapter(AppStoreProvider);
	
	return new ApplicationInteractor(
		userProvider,
		schoolsProvider, 
		pipelineProvider, 
		schoolEventsProvider,
		appStore
	);
}