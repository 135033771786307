/**
 * 
 * 	~ App Store Provider
 * 
 */


// import { dispatchStoreSelector } from '../StateSelectors/createSelector';
import { iAppStoreProvider } from '@domain/Interfaces/AppState/app/store';
import { ApplicationActions } from '@ducts/Providers/ApplicationState/Actions';


export const AppStoreProvider: iAppStoreProvider = {
	actions: ApplicationActions,
	selectors: {
		test() {
			//
		},
		// getUser: () =>
		// 	dispatchStoreSelector(
		// 		getUser
		// 	),
	},
};