/**
 * 
 * 	~ School Event Application State Adapters
 * 
 */

import { iSchoolEvent } from '@data/Entities/Events/schoolEvent';
import { iSchoolEventStoreProvider } from '@domain/Interfaces/AppState/schoolEvent/store';
import { RemoteSchoolEventCollectionModelling, RemoteSchoolEventModelling } from '@domain/Modelling/schoolEvent';


export interface iSchoolEventStoreAdapter {
	actions: {
		resetAll(): void,
		isFetchingSchoolEvents(isFetching?: boolean): void,
		updateSchoolEvent(schoolEventDTO: iSchoolEvent): void,
		updateAllSchoolEvents(schoolEvents: iSchoolEvent[]): void,
	},
	selectors: {
		//
	},
}


export default class SchoolEventStoreAdapter implements iSchoolEventStoreAdapter {
	constructor(
		private _SchoolStore: iSchoolEventStoreProvider,
	) {}

	actions = {
		...this._SchoolStore.actions,
		updateSchoolEvent: (school: iSchoolEvent) => {
			this._SchoolStore.actions.updateSchoolEvent(
				RemoteSchoolEventModelling.toDTO(school)
			);
		},
		updateAllSchoolEvents: (schools: iSchoolEvent[]) => {
			this._SchoolStore.actions.updateAllSchoolEvents(
				RemoteSchoolEventCollectionModelling.toCollection(schools)
			)
		},
	};


	selectors = {
		placeholderDeleteMe: () => {
			//
		},
	};
}