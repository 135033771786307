/*
	~ Pipeline State Reducer & Actions
*/

import { iPipelineState } from '@domain/Interfaces/AppState/pipeline/store';

import {
	PipelineActionTypes,
	iUpatePipelineAction,
	iIsFetchingPipelineAction,

} from '../../Actions/Pipeline/types';


const initialState: iPipelineState = {
	lastRun: null,
	isFetching: false,
};

type PossibleActions = 
	iUpatePipelineAction & 
	iIsFetchingPipelineAction;


/* STATE REDUCER */

export default function reducer(state: iPipelineState = initialState, action: PossibleActions) {

	switch(action.type) {
		case PipelineActionTypes.FETCHING_PIPELINE:
			return {
				...state,
				isFetching: action.isFetching,
			};
			
		case PipelineActionTypes.UPDATE_PIPELINE:
			return {
				...state,
				lastRun: action.pipeline.lastRun,
			};
		
		case PipelineActionTypes.RESET_ALL:
			return initialState;
			
		default: 
			return state;
	}

}

