/**
 *
 *  ~ Model Errors
 *
 */

import ErrorBase from './base';

import { MODEL_ERRORS } from '@data/Shared/Enums/errors/model';

export const MESSAGE_MAX_CHARS = 250;


/**
  * Model Error Interface
  */
export interface IModelError extends ErrorBase {
    type: MODEL_ERRORS,
}

/**
 * Model-level Errors
 */
export default class ModelError implements IModelError {
	_message: string;
	_type: MODEL_ERRORS;

	constructor({
		type,
		message,
	}: {
        message: string,
        type: MODEL_ERRORS,
    }) {
		this._message = message;
		this._type = type;
	}


	// Message
	get message(): string {
		return this._message;
	}

	set message(newMessage: string) {
		if (newMessage.length <= MESSAGE_MAX_CHARS) {
			this._message = newMessage;

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.messageMaxLength,
				message: `Message can only be ${MESSAGE_MAX_CHARS} chars or less`,
			});
		}
	}

	// Type
	get type(): MODEL_ERRORS {
		return this._type;
	}

	set type(newType: MODEL_ERRORS) {
		this._type = newType;
	}
}
