/**
 * 
 *  ~ User Modelling
 * 
*/

import User, { iUser } from '@data/Entities/User/user';
import FeatureAccess, { iFeatureAccess } from '@data/Entities/User/feature';

import ModelError from '@data/Entities/errors/model';
import { MODEL_ERRORS } from '@data/Shared/Enums/errors/model';
import { iRemoteUserDTO } from '@domain/Interfaces/remoteDTOs/user';
import { iUserStoreDTO } from '@domain/Interfaces/AppState/user/dto';
import { iModelSet, iModelling } from '@domain/Interfaces/modelling';
import { iFeatureAccessDTO } from '@domain/Interfaces/remoteDTOs/uac';


/**
 * Model Users & Authentication from DTOs to Models and back
 */
export const RemoteUserModelling: iModelling<iRemoteUserDTO, iUser> = {
	toDTO: (user: iUser) => {
		const {
			id,
			email,
			features,
			isAuthenticated,

		} = user;

		return {
			id,
			email,
			isAuthenticated,
			featureAccess: Array.from(features),
		};
	},
	toModel: (userDTO: iRemoteUserDTO) => {
		const {
			id,
			email,
			featureAccess,
			isAuthenticated,

		} = userDTO;

		if (id && email) {
			let modelledFeatures: iFeatureAccess[] = [];

			if (featureAccess) {
				modelledFeatures = ManyFeatureAccessModelling.toManyModels(featureAccess);
			}

			return new User({
				id,
				email,
				isAuthenticated,
				features: modelledFeatures,
			});

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.missingId,
				message: 'Missing an ID or an Email when converting Remote User DTO to Authentication Entity',
			});
		}
	},
};


/**
 * Model Users & Authentication from DTOs to Models and back
 */
export const UserStoreModelling: iModelling<iUserStoreDTO, iUser> = {
	toDTO: (user: iUser) => {
		const {
			id,
			email,
			features,
			isAuthenticated,

		} = user;

		return {
			id,
			email,
			isAuthenticated,
			featureAccess: ManyFeatureAccessModelling.toManyDTOs(features)
		};
	},
	toModel: (userDTO: iUserStoreDTO) => {
		const {
			id,
			email,
			featureAccess,
			isAuthenticated,

		} = userDTO;

		if (id && email) {
			let modelledFeatures: iFeatureAccess[] = [];

			if (featureAccess) {
				modelledFeatures = ManyFeatureAccessModelling.toManyModels(featureAccess);
			}

			return new User({
				id,
				email,
				isAuthenticated,
				features: modelledFeatures,
			});

		} else {
			throw new ModelError({
				type: MODEL_ERRORS.missingId,
				message: 'Missing an ID or an Email when converting Remote User DTO to Authentication Entity',
			});
		}
	},
};


/**
 * Model UAC for Feature Access from DTOs to Models and back 
 */
export const FeatureAccessModelling: iModelling<iFeatureAccessDTO, iFeatureAccess> = {
	toDTO: (feature: iFeatureAccess) => {
		return {
			id: feature.id,
			level: feature.level,
		};
	},
	toModel: (featureDTO: iFeatureAccessDTO) => {
		const {
			id,
			level,
		} = featureDTO;

		return new FeatureAccess({
			id,
			level,
		});
	},
};


/**
 * Model UAC for multiple features
 */
export const ManyFeatureAccessModelling: iModelSet<iFeatureAccessDTO, iFeatureAccess> = {
	toManyDTOs: (features: iFeatureAccess[]) => {
		const featureDTOs: iFeatureAccessDTO[] = [];

		if (features?.length > 0) {
			for (const feature of features) {
				featureDTOs.push(
					FeatureAccessModelling.toDTO(feature)
				);
			}
		}

		return featureDTOs;
	},
	toManyModels: (featureDTOs: iFeatureAccessDTO[]) => {
		const featureModels: iFeatureAccess[] = [];

		if (featureDTOs?.length > 0) {
			for (const feature of featureDTOs) {
				featureModels.push(
					FeatureAccessModelling.toModel(feature)
				);
			}
		}

		return featureModels;
	},
}