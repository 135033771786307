/*
	~ Client Portal All Schools Component
*/

import includeClasses from 'classnames';
import CitySelector from '@atoms/_schoolCitySelector';
import BackToTopButton from '@atoms/_backToTopButton';
import SearchFilterAppBar from '@atoms/_searchFilterAppBar';
import SearchFilterDrawer from '@atoms/_searchFilterDrawer';
import PaginatedSchoolsTable from '@molecules/PaginatedSchoolsTable';

import Styles, { stylesType } from './styles';

import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { iSchoolDuct } from '@ducts/Interfaces/school';
import { useSearchFilters } from '@hooks/searchAndFilter';
import { SpecialSearchFilters } from '@interfaces/filters';
import { getAllSchoolsDuct } from '@ducts/DuctSelectors/school';
import { getSchoolFilterOptions } from '@settings/schoolFilterOptions';

import {
	checkSchoolDataBasedOnFilter, 
	transformStateSchoolsToSchoolUIs,

} from '@modelling/school';

import {
	SchoolFilter,
	AllSchoolFilters,
	AllSchoolsFilterOption,
	SchoolFilters,
	SchoolInformationFilters,

} from '@interfaces/schools';
import { UUID } from '@ducts/Types/generic';
import { Paths } from '@settings/routes';


interface iAllSchoolsProps extends getClassesType<stylesType> {}


const ClientPortalAllSchools = (props: iAllSchoolsProps) => {
	const navigate = useNavigate();
	const filtrationOptions = getSchoolFilterOptions();
	const classes = withStyles.getClasses<stylesType>(props);
	const stateSchools: iSchoolDuct[] = useSelector(getAllSchoolsDuct);
	
	const [ selectedCity, setSelectedCity ] = useState('');
	const [ filterDrawerIsOpen, setFilterDrawerIsOpen ] = useState(true);
	
	// Send State School Ducts into Filtration
	const {
		filters,
		addFilter,
		removeFilter,
		items: schoolDucts,

	} = useSearchFilters({
		items: stateSchools,
		onFilter: checkSchoolDataBasedOnFilter,
		onSort: (schoolA: iSchoolDuct, schoolB: iSchoolDuct) => {
			if (schoolA?.name && schoolB?.name) {
				return schoolA.name.localeCompare(schoolB.name);
			} else {
				return 0;
			}
		}
	});

	// Transform to usable UI school models
	const schools = transformStateSchoolsToSchoolUIs(schoolDucts);

	const handleListItemOnClick = useCallback((filter: string) => {
		// Special "All" filter and functionality
		if (filter === SpecialSearchFilters.all) {
			removeFilter({
				label: '',
				filter: SpecialSearchFilters.all,
			});
		}
	}, []);
	
	const handleSubListItemOnClick = useCallback((filter: SchoolFilter) => {
		addFilter([filter]);
	}, []);

	const handleFilterOnDelete = useCallback((filter: SchoolFilter) => {
		removeFilter(filter);

		if (filter.filter === SchoolInformationFilters.byCity) {
			setSelectedCity('');
		} else if (filter.filter === SpecialSearchFilters.all) {
			setSelectedCity('');
		}
	}, []);

	const handleOnSchoolClick = useCallback((uuid: UUID) => {
		navigate(`${Paths.cpSingleSchool}/${uuid}`)

	}, []);
	
	return (
		<div className={classes.container}>
			<SearchFilterAppBar<AllSchoolFilters>
				filters={filters}
				onFilterDelete={handleFilterOnDelete}
				onDrawerButtonClick={() => setFilterDrawerIsOpen(true)} />

			<div className={classes.content}>
				<div 
					className={
						includeClasses({
							[classes.schoolsContainer]: true,
							[classes.schoolsContainerCompact]: filterDrawerIsOpen,
						})}>

					<PaginatedSchoolsTable
						schools={schools}
						onSchoolClick={handleOnSchoolClick} />
				</div>

				<SearchFilterDrawer<AllSchoolFilters>
					isOpen={filterDrawerIsOpen}
					filtrationOptions={filtrationOptions}
					onListItemClick={handleListItemOnClick}
					onSubListItemClick={handleSubListItemOnClick}
					onDrawerClose={() => {setFilterDrawerIsOpen(false)}}
					renderSubListItem={(schoolFilter: AllSchoolsFilterOption) => {
						if (schoolFilter.filter === SchoolFilters.schoolNameSearch) {
							return (
								<CitySelector
									value={selectedCity}
									id={'school-city-selector'}
									onChange={(city: string) => {
										if (city) {
											setSelectedCity(city);

											addFilter([
												{
													label: city,
													keyword: city,
													aggregate: false,
													filter: SchoolInformationFilters.byCity,
												}
											]);
										}
									}}
								/>
							);
						
						} else {
							return null;
						}
					}}
					 />
			</div>

			<BackToTopButton />
		</div>
	);
};


export default withStyles(ClientPortalAllSchools, Styles);