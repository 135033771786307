/**
 * 
 *  ~ Authentication Provider Service 
 *      Error Handling Functions
 * 
 *    NOTE: The error messages in these errors are not meant to be consumed by any 
 *    frontend or viewed by any user of the system. Instead these errors are 
 *    for the developers using this provider...
 * 
 */

import firebaseAuth from 'firebase/auth';

import { log } from '@logging/Console';
import { LOGIN_ERRORS } from '@data/Shared/Enums/errors/login';
import { REGISTRATION_ERRORS } from '@data/Shared/Enums/errors/registration';
import { triggerLoginError, triggerRegistrationError } from '@domain/Validation/authErrorHandling';


/**
 * @TODO - Firebase dependency should be removed here! Likely need to put this back in a provider 
 * class and map to a new set of enum/keys for triggering errors
 * 
 * The LOGIN_ERRORS and REGISTRATION_ERRORS enums are both mapped strings already, just 
 * need to map in the right domain
 */

/**
 * Handles Firebase Errors
 * @param error 
 */
export function handleError(error: firebaseAuth.AuthError) {
	const {
		code, 
		message,

	} = error;

	switch (code) {
		case 'auth/user-not-found':
			log('error', `User not found error! `, message);
			triggerLoginError(LOGIN_ERRORS.userNotFound);
			break;

		case 'auth/no-current-user':
			log('error', `User is not logged in! `, message);
			triggerLoginError(LOGIN_ERRORS.userNotLoggedIn);
			break;

		case 'auth/wrong-password':
			log('error', `Wrong password error! `, message);
			triggerLoginError(LOGIN_ERRORS.invalidPassword);
			break;

		case 'auth/invalid-email':
			log('error', `User email is invalid! `, message);
			triggerLoginError(LOGIN_ERRORS.invalidEmail);
			break;

		case 'auth/user-disabled':
			log('error', `This user  has been disabled! `, message);
			triggerLoginError(LOGIN_ERRORS.userDisabled);
			break;

		case 'auth/email-already-in-use':
			log('error', `This email is already in use! `, message);
			triggerRegistrationError(REGISTRATION_ERRORS.emailAlreadyInUse);
			break;

		case 'auth/invalid-email':
			log('error', `Invalid or incorrectly formatted email `, message);
			triggerRegistrationError(REGISTRATION_ERRORS.invalidEmail);
			break;

		case 'auth/operation-not-allowed':
			log('error', `Cannot perform this operation, it is not allowed!: `, message);
			triggerRegistrationError(REGISTRATION_ERRORS.operationNotAllowed);
			break;

		case 'auth/weak-password':
			log(
				'error',
				`This passwork has been deemed "too weak" for the system... please recreate it with more letters`,
				message
			);
			triggerRegistrationError(REGISTRATION_ERRORS.weakPassword);
			break;

		default:
			break;
	}
}