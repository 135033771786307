/**
 * 
 *  ~ Authentication Use Case Functions
 * 
 */


import LoginError from '@data/Entities/errors/login';
import Authentication from '@data/Entities/User/user';
import RegistrationError from '@data/Entities/errors/registration';
import iAuthInteractor from '@domain/Interfaces/Interactor/iAuthenticationInteractor';

import { log } from '@logging/Console';
import { iAuth } from '@data/Entities/User/authentication';
import { LOGIN_ERRORS } from '@data/Shared/Enums/errors/login';
import { iRemoteUserDTO } from '@domain/Interfaces/remoteDTOs/user';
import { iUserStoreAdapter } from '@ducts/Adapters/ApplicationState/user';
import { NotificationTypes } from '@data/Shared/Enums/notifications/messages';
// import { REGISTRATION_ERRORS } from '@data/Shared/Enums/errors/registration';
import { iRemoteAuthentication } from '@domain/Interfaces/iAuthenticationService';
import { iAppInteractor } from '@domain/Interfaces/Interactor/iApplicationInteractor';
import { iNotificationStoreAdapter } from '@ducts/Adapters/ApplicationState/notification';
import { triggerLoginError, triggerRegistrationError } from '@domain/Validation/authErrorHandling';


export default class AuthenticationInteractor implements iAuthInteractor  {

	constructor(
        private _AuthenticationProvider: iRemoteAuthentication,
        private _AppInteractor: iAppInteractor,
		private _UserStore: iUserStoreAdapter,
		private _NotificationCenterStore: iNotificationStoreAdapter
	) {}


	/**
     * Register a new user Authentication Provider
     * @param user 
     */
	async createUser(user: iAuth): Promise<void> {
		try {
			// Inform Application State, App is Fetching Auth
			this._UserStore.actions.isFetchingUser();
			// Fetch Auth
			const authResponse: iRemoteUserDTO = await this._AuthenticationProvider.registerNewUser(
				user.email,
				user.password
			);

			if (authResponse) {
				// Inform the Application about successful user creation
				this._NotificationCenterStore.actions.showMessage({
					messageType: NotificationTypes.SUCCESS,
					message: `Your new account has been created for ${user.email}`,
				});
				// Inform Application State, Updated User Response
				this._UserStore.actions.updateUser({
					...authResponse,
				});
				// Subscribe to auth changes
				this.listenToAuthUpdates();
				// Refresh stores
				this._AppInteractor.initialize(); // <-- GO!
			}

			// Inform Application State, App Is Not Fetching Auth
			this._UserStore.actions.isFetchingUser(false);
            
		} catch (error) {
			log('warning', `error occurred when creating a user: `, error);

			if (error instanceof RegistrationError) {
				triggerRegistrationError(error.type)

			} else if (error instanceof LoginError) {
				triggerLoginError(error.type);
			}
		}
	}
    
    
	/**
     * 
     * @param user 
     * @returns 
    */
	async authenticateUser(user: iAuth): Promise<void> {
		try {
			const {
				email, 
				password,

			} = user;

			if (email && password) {
				// Validate credentials
				new Authentication({
					id: email, 
					password: password,
				});
				// Inform Application State, App is Fetching Auth
				this._UserStore.actions.isFetchingUser();
				// Authenticate User
				const authResponse: iRemoteUserDTO = await this._AuthenticationProvider.authenticateUser(
					email, 
					password
				);
                
				if (authResponse) {
					const newAuth =  {
						...authResponse
					};
    
					// Inform Application State, Updated Auth Response
					this._UserStore.actions.updateUser(newAuth);
					// Inform Application State, App Is Not Fetching Auth
					this._UserStore.actions.isFetchingUser(false);
					// Listen for updates to Authentication
					this.listenToAuthUpdates();
					this._AppInteractor.initialize(); // <-- GO!
				}
            
			} else {
				// ?? do something here?
			}
           
		} catch (error) {
			if (error instanceof LoginError) {
				switch(error.type) {
					case LOGIN_ERRORS.userNotFound:
						this._NotificationCenterStore.actions.showMessage({
							message: 'This username / email does not exist!',
							messageType: NotificationTypes.ERROR,
						});
						break;
                        
					case LOGIN_ERRORS.invalidPassword:
						this._NotificationCenterStore.actions.showMessage({
							message: 'The password entered is not the correct password for this username / email',
							messageType: NotificationTypes.ERROR,
						});
						break;

					default:
						break;
				}
			}
		}
	}


	/**
     * Subscribe to Authentication updates
     */
	async listenToAuthUpdates(): Promise<void> {
		this._AuthenticationProvider.subscribeToAuthentication(this.handleAuthenticationUpdate);
	}


	/**
     * Handle Authentication Updates
     * @param userDTO 
     */
	private handleAuthenticationUpdate = (userDTO: iRemoteUserDTO): void => {
		log('info', `userDTO: ------------------------------------------: `, userDTO);
		const auth = this._UserStore.selectors.getAuth();
		log('info', `user from state: ------------------------------------------: `, auth);

		// If auth provider shows logged out, ensure the user is logged out
		if (auth.isAuthenticated === false && userDTO.isAuthenticated === false) {
			this._UserStore.actions.resetAll();
			this._AuthenticationProvider.unauthenticateUser();
            
		// If logged in or about to log in, update Auth
		} else {
			this._UserStore.actions.updateUser(userDTO);
		}
	};


	/**
     * Wipes user authentication and user-based state models
     */
	async unauthenticateUser(): Promise<void> {
		try {
			this._AuthenticationProvider.unauthenticateUser();
			this._UserStore.actions.resetAll();
            
		} catch (error) {
			log('warning', `Could not log user out. Unknown error! `, error);
		}
	}
	
	
	/**
	 * Resets the users password by sending out an email to the 
	 * user with instructions to follow
	 * @param email 
	 */
	async resetPassword(email: string): Promise<void> {
		try {
			this._AuthenticationProvider.sendPasswordResetEmail(email);
			
		} catch (error) {
			log('warning', `Could not reset user password. Unknown error! `, error);
		}
	}
    
}