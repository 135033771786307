/**
 * 
 *  ~ Event Entity
 * 
 */

import BaseEntity, { iBaseEntity } from '../BaseEntity';

import { Moment } from 'moment';
import { UUID } from '@data/Shared/Types/genericModels';
import { SiteReference } from '@data/Shared/Types/schoolEvent';
import { SchoolEventType } from '@data/Shared/Enums/events/schoolEvent';
import { guardDateTimeFromPoorlyFormedStrings } from '@data/Shared/Validation/model/dateTime';
import { guardURLFromInvalidFormat } from '@data/Shared/Validation/model/web';



export interface iSchoolEvent extends Pick<iBaseEntity, 'id'> {
	title: string,
	author: string,
	source: string,
    created: Moment,
    updated: Moment,
	thumbnail: string,
	description: string,
	schoolUuids: UUID[],
    type: SchoolEventType,
	datePublicized: Moment,
	references: SiteReference,
}


/**
 * School Event Entity
 */
export default class SchoolEvent extends BaseEntity implements iSchoolEvent {
	private _title = '';
	private _source = '';
	private _author = '';
	private _thumbnail = '';
	private _created: Moment;
	private _updated: Moment;
	private _schoolUuids: UUID[];
	private _description: string;
	private _type: SchoolEventType;
	private _datePublicized: Moment;
	private _references: SiteReference;
	
	constructor({
		id,
		type,
		title,
		author,
		source,
		created,
		updated,
		thumbnail,
		references,
		description,
		schoolUuids,
		datePublicized,

	}: iSchoolEvent) {
		super(id);

		this._type = type;
		this._title = title;
		this._source = source;
		this._author = author;
		this._updated = updated;
		this._created = created;
		this._thumbnail = thumbnail;
		this._references = references;
		this._schoolUuids = schoolUuids;
		this._description = description;
		this._datePublicized = datePublicized;
	}

	set title(newTitle: string) {
		this._title = newTitle;
	}
    
	get title(): string {
		return this._title;
	}

	get type(): SchoolEventType {
		return this._type;
	}

	set type(newType: SchoolEventType) {
		this._type = newType;
	}
	
	get description(): string {
		return this._description;
	}

	set description(newDescription: string) {
		this._description = newDescription;
	}
	
	get references(): SiteReference {
		return this._references;
	}

	set references(newReferences: SiteReference) {
		this._references = newReferences;
	}
	
	get schoolUuids(): UUID[] {
		return this._schoolUuids;
	}

	set schoolUuids(newschoolUuids: UUID[]) {
		this._schoolUuids = newschoolUuids;
	}

	get author(): string {
		return this._author;
	}

	set author(newAuthor: string) {
		this._author = newAuthor;
	}
	
	get source(): string {
		return this._source;
	}

	@guardURLFromInvalidFormat('_source')
	set source(newSource: string) {
		this._source = newSource;
	}

	get thumbnail(): string {
		return this._thumbnail;
	}

	set thumbnail(newThumbnail: string) {
		this._thumbnail = newThumbnail;
	}

	get datePublicized(): Moment {
		return this._datePublicized;
	}

	@guardDateTimeFromPoorlyFormedStrings()
	set datePublicized(newDatePublicized: Moment) {
		this._datePublicized = newDatePublicized;
	}


	get created(): Moment {
		return this._created;
	}

	@guardDateTimeFromPoorlyFormedStrings()
	set created(newCreated: Moment) {
		this._created = newCreated;
	}
	
	get updated(): Moment {
		return this._updated;
	}
	
	@guardDateTimeFromPoorlyFormedStrings()
	set updated(newUpdated: Moment) {
		this._updated = newUpdated;
	}

}