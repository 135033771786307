/*
	~ Application State Reducer & Actions
*/

import { iAppState } from '@domain/Interfaces/AppState/app/store';

import {
	iAppAction, 
	AppActionTypes, 

} from '../../Actions/App/types';


const initialState: iAppState = {
	isHydrated: false,
	isInitialized: false,
	isRehydrating: false,
	isInitializing: false,
};


/* STATE REDUCER */

export default function reducer(state: iAppState = initialState, action: iAppAction) {

	switch(action.type) {
		case AppActionTypes.REHYDRATING_STORES:
			return {
				...state,
				isHydrated: false,
				isRehydrating: true,
			};
			
		case AppActionTypes.REDUX_PERSIST_HYDRATED:
		case AppActionTypes.STORES_FULLY_HYDRATED:
			return {
				...state,
				isHydrated: true,
				isRehydrating: false,
			};
		
		case AppActionTypes.INITIALIZING:
			return {
				...state,
				isInitialized: false,
				isInitializing: true,
			};
			
		case AppActionTypes.INITIALIZED:
			return {
				...state,
				isInitialized: true,
				isInitializing: false,
			};
		
		default: 
			return state;
	}

}

