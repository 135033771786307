/*
	~ Client Portal Layout Component
*/

import Navbar from '@molecules/Navbar';
import SideNavigationDrawer from '@atoms/_sideNavigationDrawer';

import { PropsWithChildren } from 'react';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { logout } from '@ducts/Controllers/authentication';
import { iAdminSideMenuItem } from '@interfaces/adminSideMenu';
import { adminTopNavMenuItems } from '@settings/clientPortalTopNavOptions';
import { adminSideNavMenuItems } from '@settings/clientPortalSideNavOptions';



const Styles = (theme: Theme) => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	} as CSSObject,
	content: {
		flex: 1,
		width: '100%',
		display: 'flex',

	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iClientPortalLayoutProps extends getClassesType<stylesType>, PropsWithChildren {}

const ClientPortalLayout = (props: iClientPortalLayoutProps) => {
	const { children } = props;
	const navigate = useNavigate();
	// const [, set] = useState(0);
	const classes = withStyles.getClasses<stylesType>(props);

	const handleItemClick = (item: iAdminSideMenuItem) => {
		console.log(`item: `, item);
		if (item) {
			const { url } = item;
			console.log(`url: `, url);

			if (url) {
				navigate(url);
			}
		}
	};

	return (
		<div className={classes.container}>
			<Navbar
				menuItems={
					adminTopNavMenuItems({
						logoutCallback: () => {
							logout();
						},
					})
				} />

			<div className={classes.content}>
				<SideNavigationDrawer 
					onItemClick={handleItemClick}
					listItems={
						adminSideNavMenuItems()
					} />

				{ children }
			</div>
		</div>
	);
};


export default withStyles(ClientPortalLayout, Styles);