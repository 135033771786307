/**
 * 
 * 	~ User Application State Adapters
 * 
 */

import { iUser } from '@data/Entities/User/user';
import { iAuth } from '@data/Entities/User/authentication';
import { UserStoreModelling } from '@domain/Modelling/user';
import { AuthStoreModelling } from '@domain/Modelling/authentication';
import { iUserStoreProvider } from '@domain/Interfaces/AppState/user/store';
import { iUserStoreDTO } from '@domain/Interfaces/AppState/user/dto';


export interface iUserStoreAdapter {
	actions: {
		resetAll(): void,
		resetFeatureAccess(): void,
		updateUser(user: Partial<iUser>): void,
		isFetchingUser(isFetching?: boolean): void,
		updateAuthentication(isAuthenticated: boolean): void,
	},
	selectors: {
		getUser(): iUser,
		getAuth(): iAuth,
	},
}


export default class UserStoreAdapter implements iUserStoreAdapter {
	constructor(
		private _UserStore: iUserStoreProvider,
	) {}

	actions = {
		...this._UserStore.actions,
		updateAuthentication: (isAuthenticated: boolean) => {
			this._UserStore.actions.updateUserAuthenticationState(isAuthenticated);
		},
		updateUser: (user: iUser) => {
			const userDTO = UserStoreModelling.toDTO(user);
			const userUpdate: iUserStoreDTO = {};

			if (userDTO.id !== undefined && userDTO.id !== '' && userDTO.id !== 'none') {
				userUpdate.id = userDTO.id;
			}
			if (userDTO.email !== undefined && userDTO.email !== '' && userDTO.email !== 'none') {
				userUpdate.email = userDTO.email;
			}
			if (userDTO.isAuthenticated !== undefined) {
				userUpdate.isAuthenticated = userDTO.isAuthenticated;
			}
			if (userDTO.isFetching !== undefined) {
				userUpdate.isFetching = userDTO.isFetching;
			}
			if (userDTO.featureAccess) {
				userUpdate.featureAccess = userDTO.featureAccess;
			}

			this._UserStore.actions.updateAuthentication({
				...userUpdate,
			})
		},
	};


	selectors = {
		getUser: (): iUser =>
			UserStoreModelling.toModel(
				this._UserStore.selectors.getUser()
			),
		getAuth: (): iAuth =>
			AuthStoreModelling.toModel(
				this._UserStore.selectors.getAuth()
			),
	};
}


