/**
 * 
 *  ~ Selector Controllers - School
 * 
 */

import { UUID } from '@ducts/Types/generic';
import { SchoolEventStoreDuctCollectionModelling } from '@ducts/Modelling/schoolEvent';

import { 
	selectAllEventsByschool,
	selectAllSchoolEventsWithRelatedSchools, 
} from '@ducts/Providers/ApplicationState/StateSelectors/schoolEvent';

import { 
	iSchoolEventStoreCollectionDTO, 
	iSchoolEventStoreCollectionRelationshipsDTO, 
} from '@domain/Interfaces/AppState/schoolEvent/dto';


/**
 * Gets all State-stored School Events as Ducts
 */
export const selectAllSchoolEventsDuct = selectAllSchoolEventsWithRelatedSchools(
	(eventsAndSchools: iSchoolEventStoreCollectionRelationshipsDTO) => 
		SchoolEventStoreDuctCollectionModelling.fromCollection(eventsAndSchools)
);


/**
 * Gets all events from state and filters down to the queried UUIDs
 * @param schoolUUIDs 
 * @returns 
 */
export const selectAllEventsByschoolUUIDDuct = (schoolUUIDQuery: UUID) => selectAllEventsByschool(
	schoolUUIDQuery,
	(eventsState: iSchoolEventStoreCollectionDTO) => SchoolEventStoreDuctCollectionModelling.fromCollection(eventsState)
)
