/*
	~ Pipeline Reducer Actions
*/

import { iRemotePipelineDTO } from '@domain/Interfaces/remoteDTOs/pipeline';

import {
	iPipelineAction,
	PipelineActionTypes,
	iUpatePipelineAction,
	iIsFetchingPipelineAction,

} from './types';


/* ACTION CREATORS */

export function isFetchingPipelines(isFetching = true): iIsFetchingPipelineAction {
	return {
		isFetching,
		type: PipelineActionTypes.FETCHING_PIPELINE,
	};
}

export function updatePipeline(pipelineDTO: iRemotePipelineDTO): iUpatePipelineAction {
	return {
		pipeline: pipelineDTO,
		type: PipelineActionTypes.UPDATE_PIPELINE,
	};
}

export function resetAll(): iPipelineAction {
	return {
		type: PipelineActionTypes.RESET_ALL 
	};
}