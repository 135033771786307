/*
    ~ Back Button Component
*/

import { MouseEvent } from 'react';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { ChevronLeftSharp } from '@mui/icons-material';


const Styles = (theme: Theme) => ({
	anchor: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		textDecoration: 'none',
		justifyContent: 'flex-start',
	} as CSSObject,
	buttonLabel: {
		fontSize: '1.25em',
		color: theme.palette.primary.dark,
	} as CSSObject,
	backChevron: {
		fontSize: '2.5em',
		color: theme.palette.primary.dark,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iBackButton extends getClassesType<stylesType>, Pick<HTMLAnchorElement, 'href'> {
    label: string,
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void,
}


const BackButton = (props: iBackButton) => {
	const {
		label,
		onClick,
		href = '#',

	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<a 
			href={href}
			onClick={onClick}
			className={classes.anchor}>

			<ChevronLeftSharp
				classes={{
					root: classes.backChevron
				}} />

			<h3 className={classes.buttonLabel}>
				{ label }
			</h3>
		</a>
	);
};


export default withStyles(BackButton, Styles);
