/**
 * 
 *  ~ Central Logging of all Application
 * 
 */


// Colors ----
const defaultColor = '#125492';
const successColor = '#33d66f';
const informationColor = '#0084ff';
const errorColor = '#b03838';
const warningColor = '#e07e14';
// ====---


/**
 *  All types of available logs
 * 
 * 		@TODO - Change this to an enum!!
 * 
 */
export const logTypes = {
	info: 'info',
	error: 'error',
	sucess: 'success',
	warning: 'warning',
};


/**
 * 
 * @param {*} color 
 * @param  {...any} args 
 */
export function log(colorType: keyof typeof logTypes, ...args: any[]) {
	let color: string = defaultColor;
	let harshWarning = false;

	switch (colorType) {
		case logTypes.sucess:
			color = successColor;
			break;

		case logTypes.info:
			color = informationColor;
			break;

		case logTypes.error:
			color = errorColor;
			harshWarning = true;
			break;
            
		case logTypes.warning:
			harshWarning = true;
			color = warningColor;
			break;

		default:
			color = defaultColor;
			break;
	}

	if (harshWarning) {
		console.warn('%c%s', `color: ${color};`, ...args);
        
	} else {
		console.log('%c%s', `color: ${color};`, ...args);
	}
}