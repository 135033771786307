/**
 * 
 *  ~ Marketing Home Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {
		width: '100vw',
		display: 'flex',
		overflowX: 'hidden',
		flexDirection: 'column',
	} as CSSObject,
	landing: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'flex-start',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			height: 'auto',
			marginBottom: '7.5vh',
		},
	} as CSSObject,
	landingImage: {
		width: '50vw',
		height: '90vh',
		backgroundSize: '120%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left center',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			height: '45.5vh',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
		},
	} as CSSObject,
	landingContainer: {
		width: '80%',
		alignItems: 'center',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
		},
	} as CSSObject,

	landingFeaturedAd: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			marginTop: 5,
			alignItems: 'center',
			justifyContent: 'center',
		},
	} as CSSObject,
	landingFeatureTitle: {
		fontWeight: 600,
		fontSize: '2.25em',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			paddingLeft: 10,
			paddingBottom: 10,
			textAlign: 'center',
		},
	} as CSSObject,
	landingFeatureDescription: {
		fontWeight: 400,
		marginBottom: 60,
		fontSize: '1.25em',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			paddingLeft: 10,
			paddingBottom: 10,
			textAlign: 'center',
		},
	} as CSSObject,

	familySectionDescription: {
		fontWeight: 400,
		marginBottom: 60,
		fontSize: '1.25em',
		textAlign: 'right',
		padding: '25px 0 0 15px',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			textAlign: 'center',
		},
	} as CSSObject,
	highlighted: {
		width: '85%',
		padding: '15px',
		marginBottom: 25,
	} as CSSObject,
	highLightedLeft: {
		marginRight: 50,
	} as CSSObject,
	highlightedText: {
		fontWeight: 400,
		paddingLeft: 20,
		paddingRight: 20,
		fontSize: '2.43em',
		textAlign: 'right',
		lineHeight: '1.25em',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			textAlign: 'center',
		},
	} as CSSObject,
	highlightedTextLeft: {
		textAlign: 'right',
	} as CSSObject,
	
	infoGatherTitle: {
		fontWeight: 400,
		paddingLeft: 50,
		fontSize: '2.43em',
		lineHeight: '1.25em',
	} as CSSObject,

	schoolsButtonContainer: {
		display: 'flex',
		padding: '2px 20px',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	schoolsChevronRight: {} as CSSObject,
	schoolsButtonLabel: {
		margin: 7.5,
		fontWeight: 600,
		letterSpacing: 4,
		fontSize: '0.9em',
		color: theme.palette.primary.contrastText,
	} as CSSObject,
	ageCategorySection: {
		display: 'flex',
		paddingLeft: '11%',
		paddingRight: '11%',
		flexDirection: 'column',
	} as CSSObject,
	ageCategoriesContainer: {
		width: '100%',
		display: 'flex',
		pading: '0 20px',
		marginBottom: 50,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			flexDirection: 'column',
		},
	} as CSSObject,
	ageCategoriesTitle: {
		marginBottom: 25,
		paddingBottom: 10,
		color: theme.palette.primary.dark,
		borderBottom: `solid 5px ${theme.palette.primary.main}`,
	} as CSSObject,
	lureBoxContent: {
		fontWeight: 500,
		lineHeight: '25px',
	} as CSSObject,
	categoryIcon: {
		fontSize: 16,
		marginLeft: 5,
		marginBottom: 2,
		color: theme.palette.primary.contrastText,
	} as CSSObject,

	infoGatherSectionDescription: {
		fontWeight: 400,
		marginBottom: 60,
		textAlign: 'left',
		fontSize: '1.25em',
		padding: '25px 0 10px 0',
	} as CSSObject,

	timelines: {
		padding: 10,
		width: '100%',
		display: 'flex',
		marginTop: 143,
		alignItems: 'center',
		justifyContent: 'center',
	} as CSSObject,
	timelinesLayout: {
		width: '80%',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '95%',
		},
	} as CSSObject,
	timelineTitle: {
		width: '75%',
		marginRight: 35,
		color: theme.palette.primary.dark,

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			textAlign: 'center',
		},
	} as CSSObject,

	crimeMapsContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '15vh',
		flexDirection: 'column',
		justifyContent: 'center',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			marginTop: '15vh',
		},
	} as CSSObject,
	crimeMapLayout: {
		width: '80%',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export default Styles;