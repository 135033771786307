/**
 * 
 * 	~ User Factories
 * 
 */

import UserInteractor from '@domain/UseCases/user';
import createRemoteStore from '@ducts/Providers/RemoteStore/Services/Firebase/Firestore';

import UserStoreAdapter, { iUserStoreAdapter } from '@ducts/Adapters/ApplicationState/user';

import { CollectionNames } from '@domain/Types/Collection';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { iUserInteractor } from '@domain/Interfaces/Interactor/iUserInteractor';
import { UserStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/user';
import UserRemoteStoreAdapter from '@ducts/Adapters/RemoteStore/user';


export function createUserInteractor(): iUserInteractor {
	// Build remote store for users, then build its provider
	const RemoteStore = createRemoteStore(CollectionNames.users);
	const remoteUserStoreProvider: iRemoteStore = new RemoteStore();
	// Give the created store to the adapter
	const remoteUserStoreAdapter = new UserRemoteStoreAdapter(remoteUserStoreProvider);
	// Build store adapter for user application state
	const userStore: iUserStoreAdapter = new UserStoreAdapter(UserStoreProvider);
	// Build user interactor with providers
	return new UserInteractor(
		remoteUserStoreAdapter,
		userStore
	);
}