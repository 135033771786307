/*
	~ Register View
*/

import Form from '@atoms/Form/_form';
import Input from '@atoms/Form/_input';
import SubmitButton from '@atoms/_button';
import BackButton from '@atoms/_backButton';
import FilledTitle from '@atoms/_filledTitle';

import Styles, { stylesType } from './styles';

import { MouseEvent, useState } from 'react';
import { Paths } from '@settings/routes';
import { useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { Inputs } from '@assets/styles/muiTypes/input';
import { createUser } from '../../core/Ducts/Controllers/authentication';
import { selectUser } from '@ducts/Providers/ApplicationState/StateSelectors/user';

// ????? VVVVVVV ??????? EWWW!
import { validEmailRegex, validPasswordRegex } from '@data/Shared/Validation/regex';

import { 
	Step,
	Paper,
	Button,
	Stepper,
	StepLabel, 
	StepContent, 

} from '@mui/material';

import {
	Buttons,
	ButtonSizes,
	ButtonColors,
	ButtonVariants,

} from '@assets/styles/muiTypes/button';


interface iRegisterView extends getClassesType<stylesType> {}

enum RegistrationFormKeys {
	schoolDistrict = 'schoolDistrict',
	schoolCommunity = 'schoolCommunity',
	email = 'email',
	password = 'password',
	confirmPassword = 'confirmPassword',
}

interface iRegistrationForm {
	[RegistrationFormKeys.schoolDistrict]: string,
	[RegistrationFormKeys.schoolCommunity]: string,
	[RegistrationFormKeys.email]: string,
	[RegistrationFormKeys.password]: string,
	[RegistrationFormKeys.confirmPassword]: string,
}

const steps = [
	'Enter a username',
	'Create a password',
];


const RegisterView = (props: iRegisterView) => {
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const classes = withStyles.getClasses<stylesType>(props);
	const { isFetching } = useSelector(selectUser);


	const getStepContent = (step: number) => {
		switch(step) {
			case 0:
				return renderUsernameStepContent();
			case 1:
				return renderPasswordStepContent();

			default:
				return 'Unknown step';
		}
	};


	const handleFormSubmission = (data: iRegistrationForm) => {
		const { 
			email, 
			password, 
		
		} = data;

		createUser({
			email,
			password,
		});
	};


	const handleBackHomeButtonOnClick = () => {
		navigate(Paths.login);
	};


	const handleAdvanceButtonOnClick = () => {
		setActiveStep(activeStep + 1);
	};
	
	
	const handlePreviousStepButtonClick = () => {
		setActiveStep(activeStep - 1);
	};

	const handleBackButtonOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		event.stopPropagation();

		navigate(Paths.marketingHome);
	};


	const renderUsernameStepContent = () => {
		return (
			<div>
				<Input
					label={'Email'}
					fieldName={RegistrationFormKeys.email}
					helperText={'The email you will use to log in'}
					validation={{
						minLength: 8,
						required: '*Required',
						pattern: {
							value: validEmailRegex,
							message: 'Must be a valid email address',
						},
					}} />
			</div>
		);
	};


	const renderPasswordStepContent = () => {
		return (
			<div>
				<Input
					label={'Password'}
					type={Inputs.password}
					fieldName={RegistrationFormKeys.password}
					validation={{
						required: '*Required',
						pattern: {
							value: validPasswordRegex,
							message: 'Must have 8 characters and 1 number',
						},
					}} />
					
				<Input
					type={Inputs.password}
					label={'Confirm password'}
					fieldName={RegistrationFormKeys.confirmPassword}
					validation={{
						required: '*Required',
						pattern: {
							value: validPasswordRegex,
							message: 'Must have 8 characters and 1 number',
						},
					}} />
			</div>
		);
	};


	/**
	 * Apr 27th, 2024 - Commented out because its information is not needed 
	 * yet and this will likely have to become a whole new question and context 
	 * of profile information
	 */
	// const renderUserDetailsStepContent = () => {
	// 	return (
	// 		<div className={classes.profileButtonContainer}>
	// 			<Input
	// 				label={'School District'}
	// 				fieldName={RegistrationFormKeys.schoolDistrict}
	// 				inputClassOverride={classes.profileButtonInput}
	// 				helperText={
	// 					'Enter the school district that is most important to you (optional)'
	// 				} />
					
	// 			<Input
	// 				label={'School Learning Community (SLC)'}
	// 				helperText={'Enter the SLC closest to you'}
	// 				fieldName={RegistrationFormKeys.schoolCommunity}
	// 				inputClassOverride={classes.profileButtonInput} />
	// 		</div>
	// 	);
	// };


	const renderStepperFooterButtons = () => {
		return (
			<div className={classes.createProfileFormFooter}>
				<Button
					size={ButtonSizes.small}
					variant={ButtonVariants.text}
					color={ButtonColors.secondary}
					onClick={handleBackHomeButtonOnClick}>

					Login
				</Button>

				{
					(activeStep >= (steps.length - 1)) &&
						<SubmitButton
							type={Buttons.submit}
							isLoading={isFetching}
							size={ButtonSizes.large}
							color={ButtonColors.primary}
							variant={ButtonVariants.contained}>
						
							Register
						</SubmitButton>
				}
			</div>
		);
	};


	return (
		<div className={classes.container}>
			<BackButton 
				href={'#'}
				label={'Back Home'}
				onClick={handleBackButtonOnClick} />

			<div className={classes.centeringContainer}>
				<Paper 
					square 
					elevation={5}
					classes={{
						root: classes.registerBox 
					}}>

					<FilledTitle>
						Register
					</FilledTitle>

					<Form<iRegistrationForm> 
						onSubmit={handleFormSubmission}
						// defaultValues={{
						// 	[RegistrationFormKeys.password]: 'Iamalongpassword143',
						// 	[RegistrationFormKeys.confirmPassword]: 'Iamalongpassword143',
						// 	[RegistrationFormKeys.email]: `testing_${getRandomRange(0, 123454657687654)}@test.ca`,
						// }}
						formClassOverride={classes.createProfileForm}>

						<div className={classes.stepContainer}>
							<Stepper 
								activeStep={activeStep} 
								orientation={'vertical'}>

								{
									steps.map((label, index) => {
										return (
											<Step key={label}>
												<StepLabel classes={{
													iconContainer: classes.stepLabel 
												}}>
													{ label }
												</StepLabel>

												<StepContent>
													{ getStepContent(index) }

													<div>
														<div>
															<Button
																disabled={activeStep === 0}
																onClick={handlePreviousStepButtonClick}>
																
																Back
															</Button>

															{
																(activeStep < (steps.length - 1)) && 
																	<Button
																		size={'small'}
																		color={'primary'}
																		variant={'contained'}
																		onClick={handleAdvanceButtonOnClick}>
																		
																		{  'Next' }
																	</Button>
															}
														</div>
													</div>
												</StepContent>
											</Step>
										);
									})
								}
							</Stepper>
						</div>

						{ renderStepperFooterButtons() }
					</Form>
				</Paper>
			</div>
		</div>
	);
};


export default withStyles(RegisterView, Styles);

