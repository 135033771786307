/**
 * 
 *  ~ Firebase Realtime DB Provider
 * 
 */

import databaseBase from 'firebase/database';
import * as realtimeDB from 'firebase/database';
import iRemoteStoreProvider from '../../Interfaces/iRemoteStoreProvider';

import { CollectionNames } from '../../../../../Domain/Types/Collection';
import { addBaseActions } from '../../UseCaseMixins/realtimeDBAbilities';


// 
class RealtimeDBProvider implements iRemoteStoreProvider<typeof databaseBase> {
	public remoteStoreProvider = realtimeDB;
}


// Add Actions To Auth Provider
export const createRemoteStoreProvider = (key: CollectionNames) => addBaseActions(key, RealtimeDBProvider);