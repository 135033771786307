/*
	~ Login View
*/

import Form from '@atoms/Form/_form';
import Input from '@atoms/Form/_input';
import SubmitButton from '@atoms/_button';
import BackButton from '@atoms/_backButton';
import FilledTitle from '@atoms/_filledTitle';

import Styles, { stylesType } from './styles';

import { MouseEvent } from 'react';
import { Paths } from '@settings/routes';
import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { Inputs } from '@assets/styles/muiTypes/input';
import { login } from '../../core/Ducts/Controllers/authentication';
// import { notifyApplication } from '../../core/Ducts/Controllers/notifications';
// import { ViewNotificationTypes, mapMessageTypes } from '@interfaces/notifications';

import {
	Paper,
	Button,
	Divider,
	Typography,

} from '@mui/material';

import {
	ButtonSizes,
	ButtonColors,
	ButtonVariants,
	Buttons,

} from '@assets/styles/muiTypes/button';

import { validEmailRegex, validPasswordRegex } from '@data/Shared/Validation/regex';


enum LoginFormKeys {
	username = 'username',
	password = 'password',
}

interface iLoginForm {
	[LoginFormKeys.username]: string,
	[LoginFormKeys.password]: string,
}


interface iLoginViewProps extends getClassesType<stylesType> {}


const LoginView = (props: iLoginViewProps) => {
	const navigate = useNavigate();
	const classes = withStyles.getClasses<stylesType>(props);

	const handleRegistrationOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		navigate(Paths.register);
	};

	const handlePasswordResetOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		navigate(Paths.forgotPassword);
	};

	const handleBackButtonOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		event.stopPropagation();

		navigate(Paths.marketingHome);
	};

	const handleLoginSubmit = async (fields: iLoginForm) => {
		console.log(`data: `, fields);

		const {
			username, 
			password,
		} = fields;

		if (username && password) {
			await login(username, password);

		} else {
			// notifyApplication({
			// 	message: 'Could not log in!',
			// 	messageType: ViewNotificationTypes.error,
			// });
		}
	};


	return (
		<div className={classes.container}>
			<BackButton 
				href={'#'}
				label={'Back Home'}
				onClick={handleBackButtonOnClick} />

			<div className={classes.centeringContainer}>
				<Paper 
					square={true}
					elevation={2}
					classes={{
						root: classes.loginBox 
					}}>

					<FilledTitle>
						Login
					</FilledTitle>

					<div className={classes.loginFieldContainer}>
						<Form<iLoginForm>
							onSubmit={handleLoginSubmit}
							// defaultValues={{
							// 	[LoginFormKeys.username]: `testing@test.ca`,
							// 	[LoginFormKeys.password]: 'Iamalongpassword143',
							// }}
							key={'loginForm'}>

							<Input 
								label={'Username'}
								fieldName={'username'}
								validation={{
									required: '*Required',
									pattern: {
										value: validEmailRegex,
										message: 'Must be a valid email address',
									},
								}} />
							
							<Input 
								label={'Password'}
								type={Inputs.password}
								fieldName={'password'}
								validation={{
									required: '*Required',
									pattern: {
										value: validPasswordRegex,
										message: 'Must have 8 characters and 1 number',
									},
								}}
								 />

							<div className={classes.buttonFooter}>
								<Button
									size={ButtonSizes.small}
									variant={ButtonVariants.text}
									color={ButtonColors.secondary}
									onClick={handlePasswordResetOnClick}
									className={classes.forgotPasswordButton}>

									Forgot your password?
								</Button>

								<SubmitButton
									isLoading={false}
									type={Buttons.submit}
									size={ButtonSizes.large}
									color={ButtonColors.primary}
									variant={ButtonVariants.contained}>

									Login
								</SubmitButton>
							</div>
						</Form>
					</div>

					<Divider 
						classes={{
							root: classes.footerDivider 
						}} />

					<Typography
						align={'center'}
						variant={'caption'}
						classes={{
							root: classes.registrationText 
						}}>

						Create a new account?
						<Button
							size={ButtonSizes.small}
							color={ButtonColors.primary}
							variant={ButtonVariants.text}
							onClick={handleRegistrationOnClick}
							classes={{
								root: classes.registerButton 
							}}>

							Create your account
						</Button>.
					</Typography>
				</Paper>
			</div>
		</div>
	);
};

export default withStyles(LoginView, Styles);