/**
 * 
 * 	~ Pipeline Adapters for Remote Store
 * 
 */

import { iPipeline } from '@data/Entities/Piipeline/pipeline';
import { iRemoteStore } from '@domain/Interfaces/iRemoteStore';
import { PipelineConfigurationModelling } from '@domain/Modelling/pipeline';
import { iPipelineRemoteResponse } from '@domain/Interfaces/remoteDTOs/pipeline';


export interface iPipelineRemoteStoreAdapter {
	subscribeTo(id: string, callback: (model: iPipeline | null) => object): Promise<() => void>
}


export default class PipelineRemoteStoreAdapter implements iPipelineRemoteStoreAdapter {

	constructor(
		private _RemoteStoreProvider: iRemoteStore,
	) {}
	
	async subscribeTo(id: string, callback: (model: iPipeline | null) => object): Promise<() => void> {
		return this._RemoteStoreProvider.subscribeTo<iPipelineRemoteResponse>(
			id, 
			(pipelineDTOResponse: iPipelineRemoteResponse | null) => {
				if (pipelineDTOResponse) {
					return callback(
						PipelineConfigurationModelling.toModel({
							lastRun: pipelineDTOResponse['last-run']
						})
					);
				
				} else {
					return {};
				}
			}
		);
	}

}