/**
 * 
 *  ~ Notification Controller Functions
 * 
 */

import NotificationInteractor from '@domain/UseCases/notification';

import { log } from '@logging/Console';
import { iNotificationDuct } from '@ducts/Interfaces/notifications';
import NotificationStoreAdapter, { iNotificationStoreAdapter } from '@ducts/Adapters/ApplicationState/notification';
import { NotificationStoreProvider } from '@ducts/Providers/ApplicationState/StoreProviders/notification';
import { NotificationModelling } from '@domain/Modelling/notification';


/**
 * 
 * @param notificationDTO 
 */
export async function notifyApplication(notificationDTO: iNotificationDuct) {
	try {
		const notificationProvider: iNotificationStoreAdapter = new NotificationStoreAdapter(NotificationStoreProvider);
		const notificationInteractor = new NotificationInteractor(notificationProvider);

		notificationInteractor.notifyApplication(
			NotificationModelling.toModel({
				...notificationDTO,
			})
		);
        
	} catch (error) {
		log('error', 'Something went wrong when notifying the application, error: ', error);
	}
}


/**
 * 
 */
export async function viewRecentNotification(): Promise<void> {
	try {
		const notificationProvider: iNotificationStoreAdapter = new NotificationStoreAdapter(NotificationStoreProvider);
		const notificationInteractor = new NotificationInteractor(notificationProvider);

		notificationInteractor.removeLastNotificationFromMessages();

	} catch (error) {
		log('error', 'Something went wrong when reading the most recent notification', error);
	}
}