/**
 * 
 *  ~ School Timeline Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { DRAWER_WIDTH } from '@atoms/_searchFilterDrawer';


const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		overflowX: 'hidden',
		flexDirection: 'column',

	} as CSSObject,
	content: {
		width: '100%',
		minHeight: '85vh',
		position: 'relative',
        
	} as CSSObject,
	filterBar: {
		width: '100%',
		minHeight: 60,
		position: 'relative',
		flexDirection: 'row',
		transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

	} as CSSObject,
	filterBarCompact: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,

	} as CSSObject,
	eventContainer: {
		width: '100%',
		transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

	} as CSSObject,
	eventContainerCompact: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,

	} as CSSObject,
	openDrawerButton: {
		right: 25,
		top: '15%',
		position: 'absolute',
	} as CSSObject,
	menuIcon: {
		color: '#ffffff',
	} as CSSObject,
	schoolSelectorListItem: {
		padding: 30,
		marginLeft: 10,
		marginRight: 10,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;