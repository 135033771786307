/**
 * 
 *  ~ Selector Controllers - School
 * 
 */

import { UUID } from '@ducts/Types/generic';
import { iSchoolStoreCollectionDTO, iSchoolStoreDTO } from '@domain/Interfaces/AppState/school/dto';
import { SchoolStoreDuctCollectionModelling, SchoolStoreDuctModelling } from '@ducts/Modelling/school';

import { 
	selectAllSchools,
	selectSchoolByUUID, 
	selectSchoolsByUUIDs, 
} from '@ducts/Providers/ApplicationState/StateSelectors/school';


/**
 * Get all state schools and model as school ducts
 */
export const getAllSchoolsDuct = selectAllSchools((schoolsCollection: iSchoolStoreCollectionDTO) =>
	SchoolStoreDuctCollectionModelling.fromCollection(schoolsCollection)
);

/**
 * Get the state of a school by it's UUID
 * @param uuid 
 * @returns 
 */
export const getSchoolDuct = (uuid: UUID) => selectSchoolByUUID(
	uuid, 
	(schoolDTO: iSchoolStoreDTO) => {
		if (schoolDTO) {
			return SchoolStoreDuctModelling.toDuct(schoolDTO)
		}
	}
)

/**
 * Get all schools in state by given uuids array
 * @param uuids 
 * @returns 
 */
export const getSchoolDucts = (uuids: UUID[]) => selectSchoolsByUUIDs(
	uuids, 
	schoolsCollection => SchoolStoreDuctCollectionModelling.fromCollection(schoolsCollection)
);